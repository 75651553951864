import { injectable } from 'inversify';

import { CredentialData, CredentialPersistence, RQL_ENCRYPTION_VERSIONS } from '@runql/util';
import type { DataCredential } from '../entities';
import { desktopFeatureAvailable } from './DesktopQueryService';

export const SESSION_STORAGE_AUTHN_PERSON_ID_KEY = 'authNPersonId';

export interface LocalCredentialService {
   clear(target?: CredentialPersistence): Promise<void>;
   delete(dataConnectionId: number): Promise<void>;
   generateHeaders(dataConnectionIds: number | number[]): Promise<Record<string, string> | {}>;
   get(dataConnectionId: number): Promise<CredentialData | undefined>;
   getMap(
      dataConnectionIds: number | number[],
      options?: { defaultRqlEncryptionVersion?: number }
   ): Promise<Record<number, CredentialData>>;
   has(dataConnectionId: number): Promise<boolean>;
   set(dataConnectionId: number, credential: DataCredential): Promise<boolean>;
}

// Note: 'sshPassword' is used as the passphrase for the SSH key file when 'sshAuthMethod' is equal
// to SSHAuthenticationMethod.KEY_FILE.
function formatCredentialForStorage(dataCredential: DataCredential) {
   return (
      [
         'accountName',
         'accountPassword',
         'sshAuthMethod',
         'sshUsername',
         'sshPassword',
         'sshKeyFile',
         'rqlEncryptionVersion',
         'credentialPersistence',
      ] as Array<keyof DataCredential>
   ).reduce((acc, key) => {
      return {
         ...acc,
         ...(dataCredential[key] ? { [key]: dataCredential[key] } : {}),
      };
   }, {} as CredentialData);
}

@injectable()
abstract class BaseLocalCredentialService implements LocalCredentialService {
   abstract delete(dataConnectionId: number): Promise<void>;
   abstract get(dataConnectionId: number): Promise<CredentialData | undefined>;
   abstract has(dataConnectionId: number): Promise<boolean>;
   abstract set(dataConnectionId: number, credential: DataCredential): Promise<boolean>;
   abstract clear(target?: CredentialPersistence): Promise<void>;

   async getMap(
      dataConnectionIds: number | number[],
      { defaultRqlEncryptionVersion }: { defaultRqlEncryptionVersion?: number } = {}
   ) {
      if (!Array.isArray(dataConnectionIds)) {
         dataConnectionIds = [dataConnectionIds];
      }

      const entries = (
         await Promise.all(
            dataConnectionIds.map(async (dataConnectionId) => {
               const credentialData = await this.get(dataConnectionId);

               if (!credentialData) {
                  return [];
               }

               // On the web client, credentialData should have an rqlEncryptionVersion value that
               // overrides the default value, except for credentials that were stored before
               // versioning was introduced.
               return [
                  [
                     dataConnectionId,
                     { rqlEncryptionVersion: defaultRqlEncryptionVersion, ...credentialData },
                  ],
               ];
            })
         )
      ).flat();

      return Object.fromEntries(entries) as Record<number, CredentialData>;
   }

   async generateHeaders(dataConnectionIds: number | number[]) {
      const obj = await this.getMap(dataConnectionIds);

      if (Object.keys(obj).length === 0) {
         return {};
      }

      return { 'runql-credentials': encodeURIComponent(JSON.stringify(obj)) };
   }

   protected generateLocalStorageKey(dataConnectionId?: number) {
      const personId = sessionStorage.getItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);
      let key = `runQL-credentials-${personId}`;
      if (dataConnectionId) {
         key += `-${dataConnectionId}`;
      }
      return key;
   }
}

@injectable()
export class WebLocalCredentialService extends BaseLocalCredentialService {
   private deleteSync(dataConnectionId: number) {
      const storageKey = this.generateLocalStorageKey(dataConnectionId);
      localStorage.removeItem(storageKey);
      sessionStorage.removeItem(storageKey);
   }

   delete(dataConnectionId: number) {
      const storageKey = this.generateLocalStorageKey(dataConnectionId);
      localStorage.removeItem(storageKey);
      sessionStorage.removeItem(storageKey);
      return Promise.resolve();
   }

   private getSync(dataConnectionId: number) {
      const storageKey = this.generateLocalStorageKey(dataConnectionId);
      let value = localStorage.getItem(storageKey);

      if (value === null) {
         value = sessionStorage.getItem(storageKey);
      }

      if (value === null) {
         return undefined;
      }

      try {
         return JSON.parse(value) as CredentialData;
      } catch (err: unknown) {
         return undefined;
      }
   }

   get(dataConnectionId: number) {
      return Promise.resolve(this.getSync(dataConnectionId));
   }

   // Override options so defaultRqlEncryptionVersion is always 'v1'
   getMap(
      dataConnectionIds: number | number[],
      notUsed?: { defaultRqlEncryptionVersion?: number }
   ) {
      return super.getMap(dataConnectionIds, {
         defaultRqlEncryptionVersion: RQL_ENCRYPTION_VERSIONS.v1,
      });
   }

   has(dataConnectionId: number) {
      const storageKey = this.generateLocalStorageKey(dataConnectionId);
      return Promise.resolve(
         localStorage.getItem(storageKey) !== null || sessionStorage.getItem(storageKey) !== null
      );
   }

   private setSync(dataConnectionId: number, credential: DataCredential) {
      try {
         const storageKey = this.generateLocalStorageKey(dataConnectionId);
         const value = JSON.stringify({
            ...formatCredentialForStorage(credential),
         });

         this.deleteSync(dataConnectionId);

         if (credential.credentialPersistence === CredentialPersistence.SESSION_STORAGE) {
            sessionStorage.setItem(storageKey, value);
         } else {
            localStorage.setItem(storageKey, value);
         }
      } catch (err: unknown) {
         return false;
      }

      return true;
   }

   set(dataConnectionId: number, credential: DataCredential) {
      return Promise.resolve(this.setSync(dataConnectionId, credential));
   }

   clear(target?: CredentialPersistence): Promise<void> {
      if (!target || target === CredentialPersistence.LOCAL_STORAGE) {
         Object.keys(localStorage)
            .filter((key) => key.startsWith(this.generateLocalStorageKey()))
            .forEach((key) => localStorage.removeItem(key));
      }
      if (!target || target === CredentialPersistence.SESSION_STORAGE) {
         Object.keys(sessionStorage)
            .filter((key) => key.startsWith(this.generateLocalStorageKey()))
            .forEach((key) => sessionStorage.removeItem(key));
      }
      return Promise.resolve();
   }
}

@injectable()
export class DesktopLocalCredentialService extends BaseLocalCredentialService {
   private sessionStorageAvailable = desktopFeatureAvailable('sessionCredentials');
   toKeyParams(dataConnectionId: number) {
      const personId = sessionStorage.getItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);

      if (personId === null) {
         throw new Error('Bad implementation');
      }

      return {
         dataConnectionId,
         personId: parseInt(personId, 10),
      };
   }

   async delete(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      await Promise.all([
         globalThis.runql.credentialStore.delete(this.toKeyParams(dataConnectionId)),
         sessionStorage.removeItem(this.generateLocalStorageKey(dataConnectionId)),
      ]);

      return;
   }

   async get(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      // check local storage first
      const data = await globalThis.runql.credentialStore.get(this.toKeyParams(dataConnectionId));
      if (data) return data;

      // check session storage
      const sessionValue = sessionStorage.getItem(this.generateLocalStorageKey(dataConnectionId));
      if (sessionValue === null) return undefined;

      try {
         const sessionData = JSON.parse(sessionValue) as CredentialData;
         return sessionData;
      } catch (err: unknown) {
         return undefined;
      }
   }

   // Override options so defaultRqlEncryptionVersion is always 'none'.
   getMap(
      dataConnectionIds: number | number[],
      notUsed?: { defaultRqlEncryptionVersion?: number }
   ) {
      return super.getMap(dataConnectionIds, {
         defaultRqlEncryptionVersion: RQL_ENCRYPTION_VERSIONS.none,
      });
   }

   async has(dataConnectionId: number) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }

      const has = await globalThis.runql.credentialStore.has(this.toKeyParams(dataConnectionId));
      if (has) return true;

      const sessionValue = sessionStorage.getItem(this.generateLocalStorageKey(dataConnectionId));
      return sessionValue !== null;
   }

   async set(dataConnectionId: number, credential: DataCredential) {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }
      const data = formatCredentialForStorage(credential);

      await this.delete(dataConnectionId);

      try {
         if (credential.credentialPersistence === CredentialPersistence.SESSION_STORAGE) {
            sessionStorage.setItem(
               this.generateLocalStorageKey(dataConnectionId),
               JSON.stringify(data)
            );
            return Promise.resolve(true);
         }
         const keyParams = this.toKeyParams(dataConnectionId);
         return globalThis.runql.credentialStore.set(keyParams, data);
      } catch (err: unknown) {
         return false;
      }
   }

   async clear(target?: CredentialPersistence): Promise<void> {
      if (!globalThis.runql) {
         throw new Error('Bad implementation');
      }
      const personId = sessionStorage.getItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY);
      if (personId === null) {
         throw new Error('Bad implementation');
      }
      const keyParams = this.toKeyParams(0);

      if (!target || target === CredentialPersistence.LOCAL_STORAGE) {
         await globalThis.runql.credentialStore.clear(keyParams);
      }
      if (!target || target === CredentialPersistence.SESSION_STORAGE) {
         Object.keys(sessionStorage)
            .filter((key) => key.startsWith(this.generateLocalStorageKey()))
            .forEach((key) => sessionStorage.removeItem(key));
      }

      return Promise.resolve();
   }
}
