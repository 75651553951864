import { zodResolver } from '@hookform/resolvers/zod';
import {
   DBMS_ANALYZE_SUPPORTED,
   DBMS_EXPLAIN_SUPPORTED,
   RunaMode,
   walkthroughStep,
} from '@runql/util';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Dropdown, Form, InputGroup, Stack } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IconType } from 'react-icons';
import {
   BiChevronDown,
   BiChevronRight,
   BiCopyAlt,
   BiDetail,
   BiEdit,
   BiGitCompare,
   BiInfoCircle,
   BiMessageEdit,
} from 'react-icons/bi';
import { MdOutlineRocketLaunch } from 'react-icons/md';
import Loader from 'react-spinners/PulseLoader';
import { z } from 'zod';
import { IconSendChat } from '../utilities/icons';

import { Markdown } from '../components';
import { Button as SuggestionButton } from '../components/Button';
import { DataConnection, StepType, useOpenQuery, WorkspaceStatus } from '../entities';
import {
   useExplorer,
   useExploreTab,
   useExtension,
   useListWorkspaceConnectionsQuery,
   useWalkthroughStep,
   useWorkspace,
} from '../hooks';
import { useAskRunaContext } from '../hooks/askHooks';
import { SQL_TEMPLATE } from '../pages';
import { AskRunaResponse, RunaStep } from '../services';
import { formatQuery, getShortDateTimeString, IconUse } from '../utilities';
import CodeViewer from './UI/CodeViewer';
import DiffViewer from './UI/DiffViewer';
import LoadingError from './UI/LoadingError';

const askRunaSchema = z.object({
   prompt: z.string().min(1),
   excludeQuery: z.boolean().optional(),
   mode: z.nativeEnum(RunaMode),
});

export type AskRunaFormData = z.infer<typeof askRunaSchema>;

const RunaResponse = ({
   analyzeSupported,
   askRunaHandler,
   editorCode,
   explainSupported,
   explorerName,
   index,
   onClick,
   response,
   latestResponse,
}: {
   analyzeSupported?: boolean;
   askRunaHandler?: (mode: RunaMode, steps: RunaStep[]) => void;
   editorCode?: string;
   explainSupported?: boolean;
   explorerName?: string;
   index: number;
   latestResponse?: boolean;
   onClick?: () => void;
   response: AskRunaResponse;
}) => {
   const [showDiff, setShowDiff] = useState(false);

   const responseQueries = useMemo(() => {
      return response.steps.map((step) => step.currentQuery || '');
   }, [response.steps]);

   async function handleDescribe(): Promise<void> {
      askRunaHandler?.(
         RunaMode.CompareExecPlans,
         response.steps.map((step, index) => ({
            dataConnectionId: step.dataConnectionId,
            defaultSchema: step.defaultSchema,
            execPlan: step.execPlan,
            order: step.order,
            suggestedQuery: responseQueries[index],
            stepType: step.stepType,
         }))
      );
   }

   function handleAnalyse(): void {
      askRunaHandler?.(
         RunaMode.AnalyzeExecPlans,
         response.steps.map((step, index) => ({
            dataConnectionId: step.dataConnectionId,
            defaultSchema: step.defaultSchema,
            query: editorCode,
            suggestedQuery: responseQueries[index],
            stepType: step.stepType,
         }))
      );
   }
   return (
      <Stack
         className={classNames('border-0 query-card card p-2', {
            'query-card-hover':
               response.mode === RunaMode.Describe ? response.answer : responseQueries.join('\n\n'),
         })}
         gap={2}
         onClick={onClick}
      >
         {(responseQueries.length > 0 || response.mode === RunaMode.Describe) && (
            <div className="query-card-top-right">
               <Button className="query-card-action border-0 fs-10p" size="sm" variant="white">
                  {response.mode === RunaMode.Describe ? 'Update Analyst Description' : 'Use'}
                  <IconUse size={10} />
               </Button>
            </div>
         )}

         <Stack gap={1}>
            <Stack className="flex-wrap fs-14p fw-800" direction="horizontal" gap={1}>
               {response.prompt}
               <Badge bg="info" pill>
                  <div>{response.label}</div>
               </Badge>
            </Stack>
            <Stack className="align-items-start" direction="horizontal" gap={1}>
               <div className="fs-9p text-muted">{getShortDateTimeString(response.timestamp)}</div>
               <div className="fs-9p text-muted fw-500 potential-badge">{explorerName}</div>
            </Stack>
         </Stack>
         {responseQueries.length ? (
            <>
               {(response.mode === RunaMode.CompareExecPlans ||
                  response.mode === RunaMode.AnalyzeExecPlans) && (
                  <Stack direction="horizontal" gap={2}>
                     <pre className="w-50">{response.steps[0].execPlan}</pre>
                     <pre className="w-50">{response.steps[0].suggestedExecPlan}</pre>
                  </Stack>
               )}
               <div className="queryFontSmall cm-editor">
                  <div className="card border-0 queryCardCode" style={{ position: 'relative' }}>
                     {showDiff ? (
                        <DiffViewer
                           newCode={responseQueries.join('\n') || ''}
                           oldCode={editorCode || ''}
                        />
                     ) : (
                        <CodeViewer
                           query={
                              responseQueries.length
                                 ? responseQueries.join('\n')
                                 : `Runa didn't generate a query. Try refining your query and/or question.`
                           }
                        />
                     )}
                  </div>
               </div>
               <Markdown>{response.answer}</Markdown>
               <Stack className="align-self-end" direction="horizontal" gap={1}>
                  {response.mode === RunaMode.Optimize && explainSupported && (
                     <button
                        className="btn btn-sm btn-secondary"
                        disabled={!latestResponse}
                        onClick={(event) => {
                           event.stopPropagation();
                           setShowDiff(true);
                           handleDescribe();
                        }}
                     >
                        Compare Execution Plans
                     </button>
                  )}
                  {response.mode === RunaMode.CompareExecPlans && analyzeSupported && (
                     <button
                        className="btn btn-secondary btn-sm"
                        disabled={!latestResponse}
                        onClick={(event) => {
                           event.stopPropagation();
                           handleAnalyse();
                        }}
                     >
                        <BiChevronRight size={16} /> Time Analysis
                     </button>
                  )}
                  <div onClick={(event) => event.stopPropagation()}>
                     <Dropdown key={`compare-${index}`}>
                        <Dropdown.Toggle className="btn-sm" variant={'secondary'}>
                           <Stack direction="horizontal" gap={1}>
                              <BiGitCompare size={13} />
                              <div>Compare</div>
                              <BiChevronDown size={16} />
                           </Stack>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                           <Dropdown.Item
                              active={showDiff}
                              onClick={() => {
                                 setShowDiff(true);
                              }}
                           >
                              Version in Editor
                           </Dropdown.Item>
                           <Dropdown.Item
                              active={!showDiff}
                              onClick={() => {
                                 setShowDiff(false);
                              }}
                           >
                              None
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown>
                  </div>
               </Stack>
            </>
         ) : (
            <Markdown>{response.answer}</Markdown>
         )}
      </Stack>
   );
};

const AskRuna = ({
   onRunaSetExplanation,
   setShowUpgradeModal,
}: {
   onRunaSetExplanation?: (explanation: string) => void;
   setShowUpgradeModal?: () => void;
}) => {
   const {
      register,
      setFocus,
      setValue,
      handleSubmit,
      reset,
      formState: { isValid },
   } = useForm<AskRunaFormData>({
      resolver: zodResolver(askRunaSchema),
      defaultValues: {
         prompt: '',
         mode: RunaMode.Runa,
      },
   });
   const { askRuna, isLoading, clearConversation, conversation } = useAskRunaContext();
   const exploreTab = useExploreTab();
   const extension = useExtension();
   const explorer = useExplorer();
   const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});
   const scrollRef = useRef<HTMLDivElement | null>(null);
   const lastCardRef = useRef<HTMLDivElement | null>(null);
   const [stepSix, setStepSix] = useWalkthroughStep(walkthroughStep.GENERATE_INLINE_COMMENTS);
   const workspace = useWorkspace();

   const workspaceConnectionList = useListWorkspaceConnectionsQuery({
      workspaceId: exploreTab?.workspaceId,
      includeConnectionDetails: true, // only including to match query key from WorkspaceConnectionSelector
   });

   const { steps, connections } = useMemo(() => {
      const steps: any[] = [];
      const connections: Record<number, DataConnection> = {};

      exploreTab?.queryVersion?.steps?.forEach((step) => {
         steps.push({
            dataConnectionId:
               step.dataConnectionId === undefined
                  ? workspaceConnectionList.isLoading
                     ? undefined
                     : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? undefined
                  : step.dataConnectionId,
            defaultSchema: step.schemaName ?? undefined,
            order: step.order,
            query: step.queryText !== SQL_TEMPLATE ? step.queryText : undefined,
            schemas: step.schemaName ? [step.schemaName] : [],
            stepType: step.type,
         });

         if (step.dataConnection && step.dataConnection.id) {
            connections[step.dataConnection.id] = step.dataConnection;
         }
      });

      return { steps, connections };
   }, [
      exploreTab?.queryVersion?.steps,
      workspaceConnectionList?.data,
      workspaceConnectionList.isLoading,
   ]);

   const { explainSupported, analyzeSupported } = useMemo(() => {
      const explainSupported = exploreTab?.queryVersion?.steps.every(
         (step) =>
            step.dataConnection?.dbms && DBMS_EXPLAIN_SUPPORTED.includes(step.dataConnection.dbms)
      );
      const analyzeSupported = exploreTab?.queryVersion?.steps.every(
         (step) =>
            step.dataConnection?.dbms && DBMS_ANALYZE_SUPPORTED.includes(step.dataConnection.dbms)
      );

      return { explainSupported, analyzeSupported };
   }, [exploreTab?.queryVersion?.steps]);

   let explorerName = 'UNKNOWN';
   if (explorer) explorerName = explorer.firstName + ' ' + explorer.lastName;

   // Handlers
   const onSubmit = useCallback(
      async (data: AskRunaFormData) => {
         const prompt = data.prompt;
         reset();
         await askRuna({
            prompt: data.mode === RunaMode.Optimize ? undefined : prompt,
            excludeQuery: data.excludeQuery,
            mode: data.mode,
            steps: steps,
            workspaceId: workspace.id,
            dataConnections: connections,
         });
      },
      [reset, askRuna, steps, workspace.id, connections]
   );

   useEffect(() => {
      if (scrollRef.current) {
         scrollRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [isLoading]);

   useEffect(() => {
      if (lastCardRef.current) {
         lastCardRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [conversation]);

   const handleUse = (response: AskRunaResponse) => {
      if (!response?.steps?.length) {
         if (response.mode === RunaMode.Describe && response.answer !== undefined) {
            onRunaSetExplanation?.(response.answer);
            return;
         } else {
            return;
         }
      }
      openQuery({
         newTab: false,
         queryVersion: {
            steps: response.steps.map((step, index) => ({
               dataConnectionId:
                  step.dataConnectionId ??
                  (workspaceConnectionList.isLoading
                     ? undefined
                     : workspaceConnectionList?.data?.[0]?.dataConnectionId ?? undefined),
               schemaName: step.defaultSchema ?? null,
               queryText: formatQuery(step.currentQuery || ''),
               order: step.order || index,
               type: StepType.DATA_CONNECTION,
            })),
         },
         source: 'askRuna',
         workspaceId: exploreTab?.workspaceId,
      });
   };

   const handleAskRuna = (mode: RunaMode, steps: RunaStep[]) => {
      askRuna({
         mode,
         steps,
         clearConversation: false,
         workspaceId: workspace.id,
         dataConnections: connections,
      });
   };

   // Render
   if (exploreTab?.queryVersion?.steps[0]?.type === StepType.PYTHON) {
      return (
         <div>
            <LoadingError message="Ask Runa is not supported for Python" />
         </div>
      );
   }

   const handleRunaClick = (response: AskRunaResponse) => {
      if (response.isUpgradePrompt && setShowUpgradeModal) {
         setShowUpgradeModal();
      } else {
         handleUse(response);
      }
   };

   const handleClear = () => {
      clearConversation();
   };

   const UsageSuggestion = ({
      CustomIcon,
      excludeQuery,
      mode,
      prompt,
      text,
      userComplete,
   }: {
      CustomIcon: IconType;
      excludeQuery?: boolean;
      mode?: RunaMode;
      prompt: string;
      text: string;
      userComplete?: boolean;
   }) => {
      const handleClick = () => {
         setValue('prompt', prompt);
         if (userComplete) {
            setFocus('prompt');
         } else {
            onSubmit({ prompt, excludeQuery, mode: mode ?? RunaMode.Runa });
         }
      };
      return (
         <SuggestionButton
            className="runa-suggestion-buttons btn-xs-link text-primary"
            onClick={handleClick}
         >
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
               <CustomIcon size="16px" />
               <span style={{ fontSize: '12px', fontWeight: '400' }}>{text}</span>
            </div>
         </SuggestionButton>
      );
   };

   if (conversation.length === 0) {
      if (isLoading) {
         return <Loader color="#6366f1" size={6} />;
      }
      return (
         <div
            style={{
               display: 'flex',
               flexDirection: 'column',
               width: '100%',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <h3 className="opacity-75 fw-600">What can I help with?</h3>
            <Form
               onSubmit={handleSubmit(onSubmit)}
               style={{ maxWidth: '768px', width: '100%', alignSelf: 'center' }}
            >
               <InputGroup>
                  <Form.Control
                     {...register('prompt')}
                     autoComplete="off"
                     className="small-form-control-input"
                     placeholder="Message Runa"
                  />
                  <button
                     className="btn btn-xs btn-primary plausible-event-name--askRuna"
                     disabled={isLoading || !isValid}
                     type="submit"
                  >
                     <IconSendChat size={'18px'} />
                  </button>
               </InputGroup>
            </Form>
            <div style={{ display: 'flex', marginTop: '10px', gap: '0.5rem' }}>
               <UsageSuggestion
                  CustomIcon={MdOutlineRocketLaunch}
                  mode={RunaMode.Optimize}
                  prompt="Optimize my query"
                  text="Optimize Query"
               />
               <UsageSuggestion
                  CustomIcon={BiInfoCircle}
                  excludeQuery={true}
                  mode={RunaMode.Describe}
                  prompt="Describe this query"
                  text="Describe"
               />
               <UsageSuggestion
                  CustomIcon={BiDetail}
                  mode={RunaMode.InlineComments}
                  prompt="Generate inline comments"
                  text="Create inline comments"
               />
               <UsageSuggestion
                  CustomIcon={BiEdit}
                  prompt="Write a query that "
                  text="Write a query that..."
                  userComplete={true}
               />
               <UsageSuggestion
                  CustomIcon={BiCopyAlt}
                  prompt="Modify this query to "
                  text="Modify this query to..."
                  userComplete={true}
               />
            </div>
         </div>
      );
   }

   return (
      <Stack className="ask-runa-container w-100" gap={2}>
         <div className="runa-responses">
            <Stack gap={3}>
               {conversation.map((response, index) => (
                  <div
                     className={
                        workspace.status === WorkspaceStatus.PERSONAL_DEMO &&
                        stepSix &&
                        response.prompt === 'Generate inline comments'
                           ? 'rainbowAnimation'
                           : ''
                     }
                     key={index}
                     onClick={() => {
                        if (workspace.status === WorkspaceStatus.PERSONAL_DEMO && stepSix) {
                           setStepSix();
                        }
                     }}
                     ref={index === conversation.length - 1 ? lastCardRef : null}
                  >
                     {!isLoading && index === conversation.length - 1 && <div ref={scrollRef} />}
                     <RunaResponse
                        analyzeSupported={analyzeSupported}
                        askRunaHandler={handleAskRuna}
                        editorCode={exploreTab?.queryVersion?.steps[0].queryText}
                        explainSupported={explainSupported}
                        explorerName={explorerName}
                        index={index}
                        key={index}
                        latestResponse={index === conversation.length - 1}
                        onClick={() => handleRunaClick(response)}
                        response={response}
                     />
                  </div>
               ))}
               {isLoading && (
                  <div>
                     <Loader color="#6366f1" size={6} />
                     {isLoading && <div ref={scrollRef} />}
                  </div>
               )}
            </Stack>
         </div>
         <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <SuggestionButton
               className="runa-new-chat-button btn-xs-link text-primary"
               onClick={handleClear}
               title="New Chat"
            >
               <BiMessageEdit size="18px" />
            </SuggestionButton>
            <Form
               onSubmit={handleSubmit(onSubmit)}
               style={{ maxWidth: '95%', width: '100%', alignSelf: 'center' }}
            >
               <InputGroup>
                  <Form.Control
                     {...register('prompt')}
                     autoComplete="off"
                     className="small-form-control-input"
                     placeholder="Message Runa"
                  />
                  <button
                     className="btn btn-xs btn-primary plausible-event-name--askRuna"
                     disabled={isLoading || !isValid}
                     type="submit"
                  >
                     <IconSendChat size={'18px'} />
                  </button>
               </InputGroup>
            </Form>
         </div>
      </Stack>
   );
};

export default AskRuna;
