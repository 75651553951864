import { ForwardedRef, forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';

import '../assets/scss/components/input.scss';

export const InlineInput = forwardRef(
   (
      {
         autoFocus,
         disabled,
         onBlur,
         onChange,
         value,
         multiLine,
         readOnly,
         placeholder,
         className,
         onEnter,
      }: {
         autoFocus?: boolean;
         className?: string;
         disabled?: boolean;
         multiLine?: boolean;
         onBlur?: (value: string) => void;
         onChange: (value: string) => void;
         onEnter?: (value: string) => void;
         placeholder?: string;
         readOnly?: boolean;
         value: string;
      },
      targetRef: ForwardedRef<HTMLDivElement>
   ) => {
      const [localValue, setLocalValue] = useState<string>(value);
      const ref = useRef<HTMLTextAreaElement>(null);

      useEffect(() => {
         setLocalValue(value);
      }, [value]);

      useLayoutEffect(() => {
         if (ref.current) {
            ref.current.style.height = '0px';
            const scrollHeight = Math.max(ref.current.scrollHeight, multiLine ? 26 : 22);
            ref.current.style.height = scrollHeight + 'px';
         }
      }, [ref.current?.value, multiLine]);

      return (
         <div ref={targetRef}>
            <Form.Control
               as={multiLine ? 'textarea' : 'input'}
               autoFocus={autoFocus}
               className={classNames(className, { 'empty-textarea': !value })}
               disabled={disabled}
               onBlur={(e) => {
                  setLocalValue(e.target.value);
                  onBlur?.(e.target.value);
               }}
               onChange={(e) => {
                  setLocalValue(e.target.value);
                  onChange(e.target.value);
               }}
               onKeyDown={(e) => {
                  if (e.key === 'Enter' && (!multiLine || !e.shiftKey)) {
                     if (onEnter) {
                        e.preventDefault();
                        onEnter(localValue);
                     } else {
                        e.currentTarget.blur();
                     }
                  }
               }}
               placeholder={placeholder}
               readOnly={readOnly}
               ref={ref}
               type="text"
               value={localValue}
            />
         </div>
      );
   }
);
export default InlineInput;
