import {
   ExploreTab,
   Org,
   Person,
   QueryVersion,
   WorkspaceCollaborator,
   WorkspaceSchemaConnection,
} from '.';
import { Base } from './Base';

//Must match API
export enum WorkspaceStatus {
   OPEN = 1,
   PROTECTED = 2,
   ORG_DEMO = 3,
   PERSONAL_DEMO = 4,
   DELETED = 0,
}

export interface Workspace extends Base {
   adminPerson?: Person;
   adminPersonId?: number;
   dataConnections?: WorkspaceSchemaConnection[];
   /**This is an outbound param only, API will not fill this */
   dataSourceIds?: number[];
   description?: string;
   exploreTabs?: ExploreTab[];
   id?: number;
   membersCanInvite?: boolean;
   name?: string;
   org?: Org;
   orgId?: number;
   queries?: QueryVersion[];
   savedQueryCount?: number;
   status?: WorkspaceStatus;
   workspaceCollaborators?: WorkspaceCollaborator[];
}
