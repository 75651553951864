import { Image } from 'react-bootstrap';
import classNames from 'classnames';
import { MdQuestionAnswer } from 'react-icons/md';

import { SERVICE_PLANS } from '@runql/util';

import { NavRail, NavRailButton, GlobalMenu, AiPulse } from '.';
import { isDesktop } from '../services';
import logo from '../assets/img/runQL-logo-prompt.svg';
import {
   IconMenuCertRequests,
   IconMenuDataSources,
   IconMenuDocs,
   IconMenuHome,
   IconMenuQueryChanges,
   IconMenuWorkspaces,
} from '../utilities/icons';
import {
   useOrg,
   useWalkthroughStep,
   usePerson,
   useListDataConnectionsQuery,
   useListQueryQuery,
   useDataChatThreadsCount,
} from '../hooks';
import { walkthroughStep } from '../entities';
import { PersonRole } from '../enums';

export const GlobalNavRail = (): JSX.Element => {
   const org = useOrg();
   const person = usePerson();
   const hasAnswers =
      person.role === PersonRole.ORG_BUSINESS_USER ||
      (typeof org?.plan === 'number' && SERVICE_PLANS[org.plan].businessAnswers);

   const queryUpdateCount = useListQueryQuery({
      listOptions: {
         version: 'ai-suggestions',
         countOnly: true,
      },
   });
   const queryReviewCount = useListQueryQuery({
      listOptions: {
         version: 'review',
         countOnly: true,
      },
   });
   const unviewedSchemaChanges = useListDataConnectionsQuery({
      countOnly: true,
      withNewMetadata: 'filter',
   });
   const dataChatCount = useDataChatThreadsCount();
   const queryUpdates = queryUpdateCount?.data?.totalItems;
   const queryReviews = queryReviewCount?.data?.totalItems;
   const [stepTwelve, setStepTwelve] = useWalkthroughStep(walkthroughStep.DATA_SOURCES);

   return (
      <NavRail
         bottom={
            <>
               <AiPulse
                  on={stepTwelve}
                  onClick={() => {
                     if (stepTwelve) {
                        setStepTwelve();
                     }
                  }}
                  sparkleAfter
               >
                  <NavRailButton
                     count={unviewedSchemaChanges.data?.totalItems}
                     icon={IconMenuDataSources}
                     title="Data Sources"
                     to="/sources"
                  />
               </AiPulse>
               <NavRailButton icon={IconMenuDocs} title="Documentation" to="/documentation" />
               <GlobalMenu />
            </>
         }
      >
         {!isDesktop() && (
            <a
               className={classNames('m-1 p-2')}
               href="https://runql.com"
               rel="noreferrer"
               target="_blank"
            >
               <Image alt="runQL Logo" src={logo} style={{ height: 'auto', width: '24px' }} />
            </a>
         )}
         {person.role !== PersonRole.ORG_BUSINESS_USER && (
            <>
               <NavRailButton exact icon={IconMenuHome} title="Home" to="/" />
               <NavRailButton icon={IconMenuWorkspaces} title="Workspaces" to="/workspaces" />
               <NavRailButton
                  count={queryUpdates}
                  icon={IconMenuQueryChanges}
                  title="Schema Change - Recommended Query Changes"
                  to="/query-updates"
               />
               <NavRailButton
                  count={queryReviews}
                  icon={IconMenuCertRequests}
                  title="Certification Requests"
                  to="/query-review"
               />
            </>
         )}
         {hasAnswers && (
            <NavRailButton
               count={dataChatCount}
               icon={MdQuestionAnswer}
               title="Answers"
               to="/answers"
            />
         )}
      </NavRail>
   );
};

export default GlobalNavRail;
