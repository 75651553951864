import { DataConnection, QueryParameter } from '.';
import { Base } from './Base';

export interface QueryInlineComment {
   comment: string;
   line: number;
}

export enum StepType {
   DATA_CONNECTION = 0,
   FEDERATED = 1,
   PYTHON = 2,
}

export interface QueryStep extends Base {
   comments?: QueryInlineComment[];
   dataConnection?: DataConnection | null;
   dataConnectionId?: number | null;
   federated?: boolean;
   order: number;
   queryText?: string;
   schemaName?: string | null;
   type: StepType;
}

export const findParams = (steps: QueryStep[]) => {
   const findParametersRegex = new RegExp('(?<={{)(.+?)(?=}})', 'g');
   const paramNames = new Set<string>();
   const newParameters: QueryParameter[] = [];

   for (const step of steps) {
      let foundParameter: string | undefined;
      while (step.queryText && (foundParameter = findParametersRegex.exec(step.queryText)?.[0])) {
         if (paramNames.has(foundParameter)) continue;
         paramNames.add(foundParameter);
         newParameters.push({
            name: foundParameter,
            description: '',
            defaultValue: '',
            exclude: false,
         });
      }
   }
   return newParameters;
};
