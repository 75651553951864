import { memo, useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, Spinner, TabContainer, TabContent, TabPane } from 'react-bootstrap';
import { QueryReturn } from '../interfaces';
import { getShorterDateTimeString, IconPinned, IconUnpinned } from '../utilities';
import { QueryResults } from './QueryResults';
import { useCurrentQuery } from '../hooks';

const TabHeader = ({
   active,
   queryResults,
   pinned,
   updatePin,
   eventKey,
   timestamp,
}: {
   active?: boolean;
   eventKey: string;
   pinned?: boolean;
   queryResults: QueryReturn[][];
   timestamp: string;
   updatePin: (result: QueryReturn[][], pinned: boolean) => void;
}): JSX.Element => {
   return (
      <NavItem className={`explore-tab ${active ? 'active' : ''}`}>
         <NavLink
            className="position-relative override-active-pointer"
            eventKey={eventKey}
            role="tab"
         >
            {getShorterDateTimeString(timestamp)}
            <div className="ps-1 text-muted">
               {pinned === true ? (
                  <IconPinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, false);
                     }}
                     size={14}
                     title="Remove this pinned tab"
                  />
               ) : (
                  <IconUnpinned
                     className="active-background-on-hover"
                     onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        updatePin(queryResults, true);
                     }}
                     size={14}
                     title="Pin this tab (result set) for comparison"
                  />
               )}
            </div>
         </NavLink>
      </NavItem>
   );
};

const MultipleResultsContainer = ({
   results,
   onRefreshQueryData,
}: {
   onRefreshQueryData?: () => Promise<void>;
   results: QueryReturn[][];
}) => {
   const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
   const { editResultsDataConnections, editResultsSchemaNames } = useCurrentQuery();

   useEffect(() => {
      setActiveKey(`${results.length - 1}-${(results.at(-1)?.length ?? 0) - 1}`);
   }, [results]);

   const numSteps = results.length;

   return (
      <TabContainer
         activeKey={activeKey}
         onSelect={(eventKey) => {
            setActiveKey(eventKey ?? undefined);
         }}
         transition={false}
      >
         <Nav className="fs-10p text-nowrap steps-row" role="tablist">
            {results.flatMap((resultsForStep, stepIdx) => {
               const numStatements = resultsForStep.length;

               return resultsForStep.map((_, statementIdx) => {
                  const key = `${stepIdx}-${statementIdx}`;
                  return (
                     <NavItem
                        className={`explore-tab ${key === activeKey ? 'active' : ''}`}
                        key={key}
                     >
                        <NavLink eventKey={key} role="tab">
                           {numSteps > 1
                              ? `Step ${stepIdx + 1}${
                                   numStatements > 1 ? `-${statementIdx + 1}` : ''
                                }`
                              : `Statement ${statementIdx + 1}`}
                        </NavLink>
                     </NavItem>
                  );
               });
            })}
         </Nav>
         <TabContent className="flex-grow-1 overflow-hidden">
            {results.flatMap((resultsForStep, stepIdx) =>
               resultsForStep.map((result, statementIdx) => {
                  const key = `${stepIdx}-${statementIdx}`;
                  return (
                     <TabPane className="h-100" eventKey={key} key={key}>
                        <QueryResults
                           dataConnection={editResultsDataConnections[stepIdx]}
                           onRefreshQueryData={onRefreshQueryData}
                           queryReturn={result}
                           schemaName={editResultsSchemaNames[stepIdx]}
                        />
                     </TabPane>
                  );
               })
            )}
         </TabContent>
      </TabContainer>
   );
};

const getTimestamp = (queryResults?: QueryReturn[][]): string | undefined => {
   return queryResults?.at(-1)?.at(-1)?.timestamp.toString();
};

const QueryResultsTabs = memo(
   ({
      onRefreshQueryData,
      queryResults,
   }: {
      onRefreshQueryData?: () => Promise<void>;
      queryResults?: QueryReturn[][];
   }): JSX.Element => {
      const [pinnedResults, setPinnedResults] = useState<QueryReturn[][][]>([]);
      const [activeResultSetTab, setActiveResultSetTab] = useState<string | undefined>(undefined);
      const { editResultsDataConnections, editResultsSchemaNames } = useCurrentQuery();

      const currentTimestamp = getTimestamp(queryResults) ?? '';
      const isCurrentPinned = currentTimestamp === getTimestamp(pinnedResults.at(-1));
      const allResults = [
         ...pinnedResults,
         ...(!isCurrentPinned && queryResults ? [queryResults] : []),
      ];

      useEffect(() => {
         if (!currentTimestamp) return;
         setActiveResultSetTab(currentTimestamp);
      }, [currentTimestamp, queryResults]);

      const updatePin = (result: QueryReturn[][], pin: boolean): void => {
         if (pin) {
            setPinnedResults((pinnedResults) => [...pinnedResults, result]);
         } else {
            const resultTimestamp = getTimestamp(result);
            setPinnedResults((pinnedResults) =>
               pinnedResults.filter(
                  (pinnedResult) => getTimestamp(pinnedResult) !== resultTimestamp
               )
            );
            setActiveResultSetTab(currentTimestamp);
         }
      };

      if (queryResults === undefined) {
         return (
            <div className="p-2">
               <i>Run this query to see data results.</i>
            </div>
         );
      }

      if (queryResults.length === 0) {
         return (
            <div className="p-2">
               <Spinner />
            </div>
         );
      }
      return (
         <div className="d-flex flex-column h-100 pt-2">
            <TabContainer
               activeKey={activeResultSetTab}
               onSelect={(eventKey) => {
                  setActiveResultSetTab(eventKey ?? undefined);
               }}
               transition={false}
            >
               <Nav className="fs-10p resultset-explore-tabs text-nowrap" role="tablist">
                  {allResults.map((resultsForRun, i) => {
                     const timestamp = getTimestamp(resultsForRun);
                     return (
                        <TabHeader
                           active={activeResultSetTab === timestamp}
                           eventKey={timestamp!}
                           key={timestamp}
                           pinned={i < pinnedResults.length}
                           queryResults={resultsForRun}
                           timestamp={timestamp ?? ''}
                           updatePin={updatePin}
                        />
                     );
                  })}
               </Nav>
               <TabContent className="flex-grow-1 overflow-hidden">
                  {allResults.map((resultsForRun) => {
                     const timestamp = getTimestamp(resultsForRun);
                     return (
                        <TabPane className="h-100" eventKey={timestamp} key={timestamp}>
                           <>
                              {resultsForRun.length === 1 && resultsForRun[0].length === 1 ? (
                                 <QueryResults
                                    dataConnection={editResultsDataConnections[0]}
                                    onRefreshQueryData={onRefreshQueryData}
                                    queryReturn={resultsForRun[0][0]}
                                    schemaName={editResultsSchemaNames[0]}
                                 />
                              ) : (
                                 <MultipleResultsContainer
                                    onRefreshQueryData={onRefreshQueryData}
                                    results={resultsForRun}
                                 />
                              )}
                           </>
                        </TabPane>
                     );
                  })}
               </TabContent>
            </TabContainer>
         </div>
      );
   }
);
export default QueryResultsTabs;
