import { inject, injectable } from 'inversify';
import { PaginatedResult, Invite, NewInvite } from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';

const PATH = '/v1/invite';

export interface ListOptionsInvite {
   skip?: number;
   take?: number;
   workspaceId: number;
}

export interface GetOptionsInvite {}

export interface AcceptInvitePayload {
   firstName: string;
   lastName: string;
   link: string;
   password: string;
}

export interface CheckInviteLinkResult {
   email?: string;
   firstName?: string;
   lastName?: string;
   message?: string;
   ok: boolean;
}

@injectable()
export class InviteService implements ApiServiceInterface<Invite, NewInvite> {
   private apiService: ApiService;

   constructor(@inject(TYPES.apiService) apiService: ApiService) {
      this.apiService = apiService;
   }

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${PATH}/${id}`);
   }

   async get(id: string | number, params?: Record<string, string>): Promise<Invite | undefined> {
      const result = await this.apiService.get<Invite>(`${PATH}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result;
   }

   async list(params: Record<string, string>): Promise<PaginatedResult<Invite>> {
      const result = await this.apiService.get<PaginatedResult<Invite>>(PATH, params);
      if (!result) {
         return { items: [], totalItems: 0 };
      }

      return result;
   }

   listOptions(options?: ListOptionsInvite): Promise<PaginatedResult<Invite>> {
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async patch(
      id: string | number,
      body: Invite,
      params?: Record<string, string> | undefined
   ): Promise<Invite | undefined> {
      const result = await this.apiService.patch<Invite>(`${PATH}/${id}`, body, params);
      if (!result) {
         return undefined;
      }
      return result;
   }

   async post(
      body: NewInvite,
      params?: Record<string, string> | undefined
   ): Promise<Invite | undefined> {
      try {
         const result = await this.apiService.post<Invite>(PATH, body, params);
         if (!result) {
            return undefined;
         }

         return result;
      } catch (err) {
         return undefined;
      }
   }

   put(body: Invite, params?: Record<string, string> | undefined): Promise<any> {
      throw new Error('Method not implemented.');
   }

   async checkLink(link: string | undefined): Promise<CheckInviteLinkResult> {
      if (!link) {
         return { ok: false, message: 'Invalid link' };
      }
      const result = await this.apiService.get<CheckInviteLinkResult>(`${PATH}/checkLink/${link}`);
      if (!result) {
         return { ok: false, message: 'Invalid Link' };
      }
      return result;
   }

   async accept(body: AcceptInvitePayload) {
      const result = await this.apiService.post<{ message: string; title: string }>(
         `${PATH}/accept`,
         body
      );
      if (!result) {
         return undefined;
      }
      return result;
   }
}
