import { OverlayTrigger } from 'react-bootstrap';
import { MdInfoOutline } from 'react-icons/md';

import type { OverlayTriggerProps } from 'react-bootstrap';
import type { IconType } from 'react-icons';

export const ToggleTip = ({
   children,
   placement = 'auto',
   size = 16,
   icon: Icon = MdInfoOutline,
}: {
   children: OverlayTriggerProps['overlay'];
   icon?: IconType;
   placement?: OverlayTriggerProps['placement'];
   size?: number;
}) => (
   <OverlayTrigger
      delay={{ show: 250, hide: 350 }}
      overlay={children}
      placement={placement}
      trigger={['click', 'hover']}
   >
      <button
         className="toggle-tip border-0 bg-transparent text-reset focus-outline d-flex justify-content-center align-items-center"
         type="button"
      >
         <span className="visually-hidden">Open toggle tip</span>
         <Icon aria-hidden="true" size={size} />
      </button>
   </OverlayTrigger>
);

export default ToggleTip;
