import { zodResolver } from '@hookform/resolvers/zod';
import { CredentialPersistence } from '@runql/util';
import { useEffect } from 'react';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import { ConnectionAccessType } from '../../../../entities';
import { DBMS } from '../../../../enums';
import { useGetDataConnectionQuery } from '../../../../hooks';
import { connectionName, description, password, username } from '../validators';
import {
   AccessTypeField,
   ConnectionCredentialsFields,
   ConnectionParamSelectField,
   CredentialPersistenceField,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   accountName: username,
   password: password,
});

const snowflakeSchema = z
   .object({
      dbms: z.literal(DBMS.Snowflake),
      connectionAccessType: z.nativeEnum(ConnectionAccessType),
      connectionName: connectionName,
      description: description,
      snowflakeAccount: z.string().min(1, 'Required'),
      warehouse: z.string(),
      database: z
         .string()
         .min(1, 'Required')
         .regex(/^[^.]*$/, 'Database cannot specify a schema'),
   })
   .merge(credentialsSchema);

type SnowflakeDetailFormData = z.infer<typeof snowflakeSchema>;

function SnowflakeDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const formMethods = useForm<SnowflakeDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : snowflakeSchema),
      defaultValues: {
         rememberCredential: true,
      },
   });
   const { register, handleSubmit, formState, reset, setValue, watch } = formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];
         const formData: SnowflakeDetailFormData = {
            dbms: DBMS.Snowflake,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            snowflakeAccount: selectedConnectionQuery.data.snowflakeAccount ?? '',
            warehouse: selectedConnectionQuery.data.snowflakeWarehouse ?? '',
            database: selectedConnectionQuery.data.catalogName ?? '',
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: SnowflakeDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   if (isSaving) return <LoadingSpinner />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input type="hidden" {...register('dbms')} value={DBMS.Snowflake} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="snowflakeAccount"
                        dbms={DBMS.Snowflake}
                        isRequired
                        label={
                           <div>
                              Snowflake Account <span className="text-danger">*</span>{' '}
                              <a
                                 className="fs-11p"
                                 href="https://docs.snowflake.com/en/user-guide/admin-account-identifier#format-1-preferred-account-name-in-your-organization"
                                 rel="noreferrer"
                                 target={'_blank'}
                              >
                                 (find your account identifier)
                              </a>
                           </div>
                        }
                        name="snowflakeAccount"
                        onExistingSelect={(value) => {
                           setValue('warehouse', value.snowflakeWarehouse ?? '');
                           setValue('database', value.catalogName ?? '');
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Warehouse <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('warehouse')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.warehouse && !!errors.warehouse}
                           isValid={touchedFields.warehouse && !errors.warehouse}
                           placeholder="Warehouse"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.warehouse?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Database <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('database')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.database && !!errors.database}
                           isValid={touchedFields.database && !errors.database}
                           placeholder="Database"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.database?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange: () => {
                              setValue('accountName', '');
                              setValue('password', '');
                           },
                        })}
                     />
                  </>
               )}
               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>

               <ConnectionCredentialsFields
                  autoFocusName={onlyCreds}
                  readonly={editType === 'read-only'}
               />
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default SnowflakeDetailForm;
