import { SchemaItemType } from '@runql/util';
import { ModuleRegistry } from 'ag-grid-community';
import { AllEnterpriseModule, LicenseManager } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useInjection } from 'inversify-react';
import {
   forwardRef,
   memo,
   useCallback,
   useContext,
   useEffect,
   useImperativeHandle,
   useMemo,
   useRef,
   useState,
} from 'react';
import { OverlayTrigger, Stack, Tooltip } from 'react-bootstrap';
import { BiCodeBlock } from 'react-icons/bi';
import { MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { parse as parseDate, isValid as isValidDate, format as formatDate } from 'date-fns';

import { DBMS } from '../../enums';
import { useOptionalExploreTab, useGetDataConnectionSchema } from '../../hooks';
import { QueryReturn } from '../../interfaces';
import { StatsService } from '../../services';
import { TYPES } from '../../types';
import { formatQuery, normalizeType } from '../../utilities';
import { ThemeContext } from '../ThemeContext';

import type {
   CellEditingStoppedEvent,
   CellSelectionDeleteEndEvent,
   FilterModel,
   GridOptions,
   PostSortRowsParams,
   RowClassParams,
   StateUpdatedEvent,
   ValueFormatterFunc,
   ValueParserParams,
   GetMainMenuItemsParams,
   DefaultMenuItem,
   IMenuActionParams,
} from 'ag-grid-community';
import type { CustomCellRendererProps, CustomStatusPanelProps } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type ForeignKeyEntry = {
   fkColumnNames: string[];
   fkTableId?: number;
   fkTableName: string;
};

const NULL_PLACEHOLDER = '(NULL)';
const dateTimeValueFormatter: ValueFormatterFunc<any, string | null> = (params) => {
   const value = params.value;

   // TODO: Uncomment when the other column types support displaying null values
   // properly.
   // if (value === null) {
   //    return NULL_PLACEHOLDER;
   // }

   return value ?? '';
};

function customColumnDefs(type: string) {
   type = normalizeType(type);
   switch (type) {
      case 'BIGINT':
      case 'DOUBLE':
      case 'FLOAT':
      case 'INT':
      case 'LONG':
      case 'LONGLONG':
      case 'SHORT':
      case 'TINY':
      case 'YEAR':
         return { cellDataType: 'number', cellStyle: { whiteSpace: 'normal', textAlign: 'right' } };
      case 'JSON':
         return {
            cellDataType: 'text',
            cellStyle: { whiteSpace: 'pre', textAlign: 'left' },
            wrapText: true,
            autoHeight: true,
         };
      case 'TEXT':
      case 'VARCHAR':
      case 'VAR_STRING':
         return { cellDataType: 'text' };
      case 'DATE':
         return {
            cellDataType: 'dateString',
            valueFormatter: dateTimeValueFormatter,
         };
      case 'TIME':
         return {
            cellDataType: 'text',
            valueFormatter: dateTimeValueFormatter,
            valueParser: (params: ValueParserParams<any, string | null>) => {
               // TODO: When we fully support a NULL placeholder, should a blank value be treated as
               // NULL?
               if (params.newValue === NULL_PLACEHOLDER || params.newValue.trim() === '') {
                  return null;
               }

               // TODO: Ideally we would use the fractional seconds precision from the column type,
               // but for now we are defaulting to 6.

               const dateFormat = 'HH:mm:ss.SSSSSS';

               // Combine placeholder with newValue to ensure the date is parsed correctly.
               const placeholder = '00:00:00.000000';
               const newDate = parseDate(
                  params.newValue + placeholder.slice(params.newValue.length),
                  dateFormat,
                  new Date()
               );

               if (!isValidDate(newDate)) {
                  return params.oldValue;
               }

               return formatDate(newDate, dateFormat);
            },
         };
      case 'DATETIME':
      case 'TIMESTAMP':
         return {
            cellDataType: 'text',
            valueFormatter: dateTimeValueFormatter,
            valueParser: (params: ValueParserParams<any, string | null>) => {
               // TODO: When we fully support a NULL placeholder, should a blank value be treated as
               // NULL?
               if (params.newValue === NULL_PLACEHOLDER || params.newValue.trim() === '') {
                  return null;
               }

               // User must provide at least a date value.
               if (params.newValue.length < 'yyyy-MM-dd'.length) {
                  return params.oldValue;
               }

               // TODO: Ideally we would use the fractional seconds precision from the column type,
               // but for now we are defaulting to 6.

               const dateFormat = 'yyyy-MM-dd HH:mm:ss.SSSSSS';

               // Combine placeholder with newValue to ensure the date is parsed correctly.
               const placeholder = '0000-00-00 00:00:00.000000';
               const newDate = parseDate(
                  params.newValue + placeholder.slice(params.newValue.length),
                  dateFormat,
                  new Date()
               );

               if (!isValidDate(newDate)) {
                  return params.oldValue;
               }

               return formatDate(newDate, dateFormat);
            },
         };
      case '':
      // fall through
      default:
         return { cellDataType: true };
   }
}

function dataValue(type: string, value: any, multilineJson?: boolean) {
   type = normalizeType(type);
   try {
      switch (type) {
         case 'BIGINT':
         case 'DOUBLE':
         case 'FLOAT':
         case 'INT':
         case 'LONG':
         case 'LONGLONG':
         case 'SHORT':
         case 'TINY':
         case 'YEAR':
            return value === null ? null : Number(value);
         case 'TEXT':
         case 'VARCHAR':
         case 'VAR_STRING':
            return value?.toString() ?? '';
         case 'JSON':
         case 'UDT':
            if (multilineJson) {
               return JSON.stringify(value, null, 2);
            }
            return JSON.stringify(value, null);
         case 'BIT':
            return value && typeof value === 'object' && 'data' in value ? value.data : value;
         case 'DATE':
         case 'TIME':
         case 'DATETIME':
         case 'TIMESTAMP':
            return value instanceof Date ? 'Please update runQL' : value;
         case '':
         // fall through
         default:
            return value;
      }
   } catch (e) {
      console.error(e);
      return value;
   }
}

function getRowDataFromQueryReturn(queryReturn: QueryReturn): Object[] {
   const colTypes =
      queryReturn?.fields?.reduce((acc, field) => {
         acc[field.field] = field.colType;
         return acc;
      }, {} as Record<string, string>) ?? {};
   if (Array.isArray(queryReturn?.rows)) {
      return (
         queryReturn?.rows?.map((row) =>
            Object.fromEntries(
               Object.entries(row).map(([key, value]) => [
                  key,
                  dataValue(colTypes[key], value, queryReturn.dbms === DBMS.Neo4j),
               ])
            )
         ) ?? []
      );
   } else {
      return [queryReturn?.rows as string];
   }
}

const sideBarDef = {
   toolPanels: [
      {
         id: 'columns',
         labelDefault: 'Columns',
         labelKey: 'columns',
         iconKey: 'columns',
         toolPanel: 'agColumnsToolPanel',
         minWidth: 100,
         maxWidth: 200,
         width: 200,
      },
      {
         id: 'filters',
         labelDefault: 'Filters',
         labelKey: 'filters',
         iconKey: 'filter',
         toolPanel: 'agFiltersToolPanel',
         minWidth: 100,
         maxWidth: 200,
         width: 200,
      },
   ],
   defaultToolPanel: '',
};

const defaultColDef = {
   enableRowGroup: true,
   enablePivot: true,
   enableValue: true,
   sortable: true,
   resizable: true,
   filter: true,
   minWidth: 36,
   suppressHeaderFilterButton: true,
   mainMenuItems: (params: GetMainMenuItemsParams) => {
      if (params.column === null) {
         return params.defaultItems;
      }

      // Add "Wrap Text" option to the column menu

      const wrappedColIds = params.context.wrappedColIds as Set<string>;

      return [
         ...params.defaultItems,
         'separator' as DefaultMenuItem,
         {
            checked: wrappedColIds.has(params.column.getColId()),
            name: 'Wrap Text',
            action: ({ api, column }: IMenuActionParams) => {
               if (column === null) {
                  return;
               }

               const columnDefs = api.getColumnDefs();

               if (!columnDefs) {
                  return;
               }

               const colId = column.getColId();

               const wasWrapped = wrappedColIds.delete(colId);

               if (!wasWrapped) {
                  wrappedColIds.add(colId);
               }

               api.setGridOption(
                  'columnDefs',
                  columnDefs.map((colDef) => {
                     if (!('colId' in colDef) || colDef.colId !== colId) {
                        return colDef;
                     }

                     return {
                        ...colDef,
                        wrapText: !wasWrapped,
                        autoHeight: !wasWrapped,
                        wrapHeaderText: !wasWrapped,
                        autoHeaderHeight: !wasWrapped,
                     };
                  })
               );
            },
         },
      ];
   },
};

const gridOptionsTemplate: GridOptions = {
   // grid options
   getContextMenuItems: () => {
      return ['cut', 'copy', 'copyWithHeaders', 'copyWithGroupHeaders', 'paste'];
   },
};

const rowClassRules = {
   'row-new': (params: RowClassParams) =>
      params.node.data.rqlAction ? params.node.data.rqlAction.type === 'insert' : false,
   'row-edited': (params: RowClassParams) =>
      params.node.data.rqlAction ? params.node.data.rqlAction.type === 'update' : false,
   'row-deleted': (params: RowClassParams) =>
      params.node.data.rqlAction ? params.node.data.rqlAction.type === 'delete' : false,
};

ModuleRegistry.registerModules([AllEnterpriseModule]);
LicenseManager.setLicenseKey(
   'Using_this_{AG_Grid}_Enterprise_key_{AG-064077}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{runQL}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{runQL}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{runQL}_need_to_be_licensed___{runQL}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{29_September_2025}____[v3]_[01]_MTc1OTEwMDQwMDAwMA==1f357805da5eab8c23091191a58a02ab'
);

const QueryStatsBar = (
   props: CustomStatusPanelProps & {
      affectedRows: number;
      runTime: number;
   }
) => {
   const runTime = props.runTime;
   const affectedRows = props.affectedRows;
   return (
      <div className="ag-status-name-value">
         <Stack direction="horizontal" gap={3}>
            {affectedRows > 0 && (
               <span>
                  <span className="component">Affected Rows&nbsp;</span>
                  <span className="ag-status-name-value-value">{affectedRows}</span>
               </span>
            )}

            <span>
               <span className="component">Run time:&nbsp;</span>
               <span className="ag-status-name-value-value">{runTime.toFixed(2)} ms</span>
            </span>
         </Stack>
      </div>
   );
};

export interface RqlAction {
   columns?: string[];
   key?: Record<string, any>;
   type: 'insert' | 'update' | 'delete';
}

export interface ResultTableRef {
   addNewRow: () => void;
   clearEdits: (clearData?: boolean) => void;
   downloadCSV: () => void;
   getUpdatedRows: () => { filterModel?: FilterModel; updates: any[] };
   markRowsAsDeleted: () => boolean;
}

export const ResultTable = memo(
   forwardRef<
      ResultTableRef,
      {
         allowEditing?: boolean;
         editableColumns?: string[];
         filterModel?: FilterModel;
         keyColumns?: string[];
         onCellEdit?: (event: { valueChanged: boolean }) => void;
         onRefreshChange?: (refreshing: boolean) => void;
         queryReturn: QueryReturn;
         simplified?: boolean;
      }
   >(
      (
         {
            allowEditing,
            editableColumns,
            filterModel,
            keyColumns,
            onCellEdit,
            onRefreshChange,
            queryReturn,
            simplified,
         },
         ref
      ) => {
         const exploreTab = useOptionalExploreTab();
         const navigate = useNavigate();
         const connectionSchema = useGetDataConnectionSchema(
            exploreTab?.tableSchema?.dataConnectionId
         );
         // Editable row data must be stored in state else ag-grids behavior becomes unpredictable during re-renders
         const [rowData, setRowData] = useState<Object[]>([]);
         const [foreignKeyData, setForeignKeyData] = useState<Record<string, ForeignKeyEntry>>({});
         const statsService = useInjection<StatsService>(TYPES.statsService);
         const resultStateKey =
            queryReturn?.fields &&
            `result-state-${queryReturn.fields.map((f) => f.field).join(':')}`;

         const saveColumnState = useCallback(
            ({ state, api }: StateUpdatedEvent) => {
               if (state.filter !== undefined) {
                  // If filter has been set (E.g. from following a FK table), resize
                  api.autoSizeAllColumns();
               }
               const stateStr = JSON.stringify(state);
               if (resultStateKey && stateStr !== localStorage.getItem(resultStateKey)) {
                  localStorage.setItem(resultStateKey, stateStr);
               }
            },
            [resultStateKey]
         );
         const getTableId = useCallback(
            (tableName: string) => {
               const fkTableObject = connectionSchema.data?.find(
                  (table) =>
                     table.catalogName === exploreTab?.tableSchema?.catalogName &&
                     table.tableName === tableName &&
                     table.type === SchemaItemType.TABLE
               );
               if (fkTableObject) {
                  return fkTableObject.id;
               } else {
                  return undefined;
               }
            },
            [connectionSchema.data, exploreTab?.tableSchema?.catalogName]
         );

         const openDataInNewTab = useCallback(
            (foreignKeyEntry: ForeignKeyEntry, value: string | null | undefined) => {
               if (exploreTab?.workspaceId && foreignKeyEntry.fkTableId) {
                  const appliedFilters: Record<string, string | null | undefined> = {};
                  for (const name of foreignKeyEntry.fkColumnNames) {
                     appliedFilters[name] = value;
                  }
                  const path = `/workspaces/${exploreTab?.workspaceId}/table/${foreignKeyEntry.fkTableId}`;
                  navigate(path, {
                     state: {
                        filters: {
                           [exploreTab?.workspaceId]: {
                              [foreignKeyEntry.fkTableId]: appliedFilters,
                           },
                        },
                     },
                  });
               }
            },
            [exploreTab?.workspaceId, navigate]
         );

         const CellWithForeignKeyLink = (props: CustomCellRendererProps) => {
            const foreignKeyEntry = foreignKeyData[props?.colDef?.headerName || ''];
            if (!foreignKeyEntry) {
               return <div>{props.valueFormatted || props?.value?.toString()}</div>;
            } else {
               return (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                     }}
                  >
                     <div>{props.valueFormatted || props?.value?.toString()}</div>
                     <button
                        onClick={() =>
                           openDataInNewTab(
                              foreignKeyEntry,
                              props.valueFormatted || props?.value?.toString()
                           )
                        }
                        style={{ border: 'none', background: 'none' }}
                     >
                        <MdOpenInNew className="opacity-50" size={12} />
                     </button>
                  </div>
               );
            }
         };

         const gridOptions = useMemo(() => {
            const options = { ...gridOptionsTemplate };

            if (allowEditing && rowData?.length) {
               options.getRowId = (params) => {
                  const key = keyColumns?.map((col) => params.data[col]).join(':');
                  if (key === undefined) {
                     console.error('Key columns are not defined');
                     return 'UNKNOWN';
                  }
                  return key;
               };
            }
            return options;
         }, [allowEditing, keyColumns, rowData?.length]);

         let columnState = useMemo(() => {
            if (!resultStateKey) return undefined;
            const stateData = localStorage.getItem(resultStateKey);
            if (!stateData) return undefined;
            return JSON.parse(stateData);
         }, [resultStateKey]);

         const enableEditing = allowEditing && keyColumns && keyColumns.length > 0;

         // Create column definitions
         const columnDefs = queryReturn?.fields?.map(({ colType, ...rest }) => ({
            ...rest,
            ...customColumnDefs(colType),
            editable:
               enableEditing &&
               (editableColumns === undefined
                  ? true
                  : editableColumns.includes(rest.headerName || '')),
            cellRenderer: CellWithForeignKeyLink,
         }));

         const gridRef = useRef<AgGridReact>(null);

         useEffect(() => {
            const refresh = filterModel && gridRef.current?.api;
            if (refresh) {
               onRefreshChange?.(true);
            }
            setRowData(getRowDataFromQueryReturn(queryReturn));
            setForeignKeyData((prev) => {
               const newFkData = { ...prev };
               const relationData = connectionSchema.data?.filter(
                  (entry) =>
                     entry.type === SchemaItemType.RELATION &&
                     entry.schemaName === exploreTab?.tableSchema?.schemaName &&
                     entry.tableName === exploreTab?.tableSchema?.tableName
               );
               for (const entry of relationData || []) {
                  for (const columnName of entry.relationColumnNames || []) {
                     newFkData[columnName] = {
                        fkTableName: entry.relationReferencedTableName,
                        fkColumnNames: entry.relationReferencedColumnNames || [],
                        fkTableId: getTableId(entry.relationReferencedTableName),
                     };
                  }
               }
               return newFkData;
            });
            gridRef.current?.api?.autoSizeAllColumns();
            if (refresh) {
               setTimeout(() => {
                  gridRef.current?.api.setFilterModel(filterModel);
                  onRefreshChange?.(false);
               }, 15);
            }
         }, [
            filterModel,
            onRefreshChange,
            queryReturn,
            connectionSchema.data,
            exploreTab?.tableSchema?.schemaName,
            exploreTab?.tableSchema?.tableName,
            getTableId,
            gridRef?.current?.api,
         ]);

         const handleDownloadCSV = () => {
            if (gridRef.current) {
               gridRef.current.api?.exportDataAsCsv();
            }
            statsService.addDownload({
               query: queryReturn?.query,
            });
         };

         function addNewRow() {
            gridRef.current?.api?.stopEditing();
            // Add a new row below the selected row or at the end of the table
            const selectedCell = gridRef.current?.api.getFocusedCell();

            if (selectedCell) {
               gridRef.current?.api?.applyTransaction({
                  add: [{ rqlAction: { type: 'insert' } }],
                  addIndex: selectedCell.rowIndex !== null ? selectedCell.rowIndex + 1 : 0,
               });
            } else {
               gridRef.current?.api?.applyTransaction({
                  add: [{ rqlAction: { type: 'insert' } }],
                  addIndex: 0,
               });
            }
         }

         const clearEdits = useCallback(
            (clearData: boolean = true) => {
               gridRef.current?.api?.stopEditing();
               gridRef.current?.api.deselectAll();
               if (clearData) setRowData(getRowDataFromQueryReturn(queryReturn));
            },
            [queryReturn]
         );

         const handleCellEdit = useCallback(
            (event: CellEditingStoppedEvent<any, any>) => {
               if (event.valueChanged) {
                  // If the value has changed, set rqlAction to 'update'
                  const node = event.node;
                  const data = node.data;
                  const rqlAction = data.rqlAction as RqlAction;
                  if (!rqlAction) {
                     data.rqlAction = {
                        type: 'update',
                        columns: [event.colDef.field],
                        key: {
                           ...keyColumns?.reduce((acc, col) => {
                              if (event.colDef.field === col) {
                                 acc[col] = event.oldValue;
                              } else {
                                 acc[col] = data[col];
                              }
                              return acc;
                           }, {} as Record<string, any>),
                        },
                     };
                     node.setData(data);
                  } else if (rqlAction.type !== 'insert') {
                     if (!event.colDef.field) return;

                     if (!rqlAction.columns?.includes(event.colDef.field)) {
                        rqlAction.columns?.push(event.colDef.field);
                     }
                     data.rqlAction = rqlAction;
                     node.setData(data);
                  }
               }
               onCellEdit?.({ valueChanged: true });
            },
            [keyColumns, onCellEdit]
         );

         const handlePostSort = useCallback((params: PostSortRowsParams<any, any>) => {
            let rowNodes = params.nodes;
            let nextIndex = 0;

            //check if grid is sorted
            const sortCol = params.api.getColumns()?.find((col) => col.getSort());
            if (sortCol) {
               for (let i = 0; i < rowNodes.length; i++) {
                  const rowNode = rowNodes[i];
                  const data = rowNode.data;
                  const rqlAction = data.rqlAction as RqlAction;
                  if (rqlAction) {
                     if (rqlAction.type === 'insert') {
                        rowNodes.splice(i, 1);
                        rowNodes.splice(nextIndex, 0, rowNode);
                        nextIndex++;
                     }
                  }
               }
            }
         }, []);

         function getUpdatedRows() {
            gridRef.current?.api?.stopEditing(true);
            const updates: any[] = [];
            gridRef.current?.api?.forEachNode((node) => {
               if (node.data.rqlAction) {
                  updates.push({ ...node.data });
               }
            });
            const model = gridRef.current?.api?.getFilterModel();
            return { updates, filterModel: model };
         }

         /** Returns true if rows were marked as deleted */
         function markRowsAsDeleted(): boolean {
            // Mark the selected row as deleted
            gridRef.current?.api?.stopEditing();
            const selectedRanges = gridRef.current?.api.getCellRanges();
            if (selectedRanges && selectedRanges.length > 0) {
               const selectedRows = selectedRanges.reduce((acc, range) => {
                  if (!range.startRow || !range.endRow) return acc;
                  for (let i = range.startRow.rowIndex; i <= range.endRow.rowIndex; i++) {
                     const rowNode = gridRef.current?.api.getDisplayedRowAtIndex(i);
                     if (rowNode?.data) {
                        const rqlAction: RqlAction = rowNode.data.rqlAction || {
                           type: 'delete',
                           key: {
                              ...keyColumns?.reduce((acc, col) => {
                                 acc[col] = rowNode.data[col];
                                 return acc;
                              }, {} as Record<string, any>),
                           },
                        };
                        rqlAction.type = 'delete';
                        rowNode.data.rqlAction = rqlAction;
                        const updateRow = { ...rowNode.data };
                        rowNode.setData({ ...rowNode.data });
                        acc.push(updateRow);
                     }
                  }
                  return acc;
               }, [] as any[]);
               gridRef.current?.api?.applyTransaction({
                  update: selectedRows,
               });
               return true;
            }
            return false;
         }

         function handleCellSelectionDelete(event: CellSelectionDeleteEndEvent<any, any>): void {
            gridRef.current?.api.stopEditing();
            const selectedRanges = gridRef.current?.api.getCellRanges();

            if (selectedRanges && selectedRanges.length > 0) {
               const rowUpdatesMap = new Map<number, any>();

               selectedRanges.forEach((range) => {
                  if (!range.startRow || !range.endRow) return;

                  for (let i = range.startRow.rowIndex; i <= range.endRow.rowIndex; i++) {
                     const rowNode = gridRef.current?.api.getDisplayedRowAtIndex(i);
                     if (rowNode?.data) {
                        const existingUpdate = rowUpdatesMap.get(i) || { ...rowNode.data };

                        // Merge the rqlAction column changes
                        const updatedColumns = range.columns.map((col) => col.getColDef().field);
                        existingUpdate.rqlAction = existingUpdate.rqlAction || {
                           type: 'update',
                           columns: [],
                           key: {
                              ...keyColumns?.reduce((acc, col) => {
                                 acc[col] = rowNode.data[col];
                                 return acc;
                              }, {} as Record<string, any>),
                           },
                        };
                        if (existingUpdate.rqlAction.type === 'update') {
                           existingUpdate.rqlAction.columns = [
                              ...new Set([...existingUpdate.rqlAction.columns, ...updatedColumns]),
                           ];
                        }

                        // Store the update
                        rowUpdatesMap.set(i, existingUpdate);
                     }
                  }
               });

               const updatedRows = Array.from(rowUpdatesMap.values());

               gridRef.current?.api?.applyTransaction({
                  update: updatedRows,
               });
            }
            onCellEdit?.({ valueChanged: true });
         }

         useImperativeHandle(ref, () => ({
            downloadCSV: handleDownloadCSV,
            addNewRow,
            clearEdits,
            getUpdatedRows,
            markRowsAsDeleted,
         }));

         const QueryUsed = useCallback(() => {
            return (
               <OverlayTrigger
                  delay={{ show: 500, hide: 0 }}
                  overlay={
                     <Tooltip className="bg-transparent" style={{ maxWidth: '50vw' }}>
                        <div>
                           <code className="text-secondary">{formatQuery(queryReturn.query)}</code>
                        </div>
                     </Tooltip>
                  }
                  placement="top"
               >
                  <div
                     className="ag-react-container ag-status-name-value ag-status-panel"
                     style={{ gap: '3px', display: 'flex', alignItems: 'center' }}
                  >
                     <BiCodeBlock size="10px" style={{ flexShrink: 0 }} />
                     <code className="query-previewer text-truncate" style={{ maxWidth: '25vw' }}>
                        {queryReturn.query}
                     </code>
                  </div>
               </OverlayTrigger>
            );
         }, [queryReturn.query]);

         // Get the current theme mode
         const themeContext = useContext(ThemeContext);
         if (!themeContext) {
            // Handle the case when ThemeContext is undefined
            return <div>Loading...</div>; // Or display a fallback UI or show a loading indicator
         }
         const { mode } = themeContext;

         return (
            <div
               className={`ag-theme-alpine${
                  mode === 'dark' ? '-dark' : ''
               } d-flex flex-column h-100 w-100`}
            >
               <AgGridReact
                  autoSizeStrategy={{ type: 'fitCellContents' }}
                  cellSelection={true}
                  columnDefs={columnDefs}
                  columnHoverHighlight={true}
                  context={{ wrappedColIds: new Set<string>() }}
                  defaultColDef={defaultColDef}
                  gridOptions={gridOptions}
                  headerHeight={32}
                  initialState={columnState}
                  onCellEditingStopped={enableEditing ? handleCellEdit : undefined}
                  onCellSelectionDeleteEnd={enableEditing ? handleCellSelectionDelete : undefined}
                  onStateUpdated={saveColumnState}
                  pagination={!simplified}
                  postSortRows={enableEditing ? handlePostSort : undefined}
                  ref={gridRef}
                  rowClassRules={rowClassRules}
                  rowData={rowData}
                  rowHeight={32}
                  rowSelection={{
                     mode: 'multiRow',
                     checkboxes: false,
                     headerCheckbox: false,
                     enableClickSelection: true,
                  }}
                  sideBar={simplified ? undefined : sideBarDef}
                  statusBar={
                     simplified
                        ? undefined
                        : {
                             statusPanels: [
                                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                                { statusPanel: QueryUsed, align: 'left' },
                                { statusPanel: 'agFilteredRowCountComponent' },
                                { statusPanel: 'agSelectedRowCountComponent' },
                                { statusPanel: 'agAggregationComponent' },
                                {
                                   statusPanel: QueryStatsBar,
                                   statusPanelParams: {
                                      affectedRows: queryReturn.affectedRows,
                                      runTime: queryReturn.runtime,
                                   },
                                },
                             ],
                          }
                  }
                  stopEditingWhenCellsLoseFocus={true}
                  suppressFieldDotNotation={true}
                  suppressMenuHide={false}
                  theme="legacy"
               ></AgGridReact>
            </div>
         );
      }
   )
);

export default ResultTable;
