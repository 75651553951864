import { useState, useRef, useEffect } from 'react';
import { Form, Nav, Dropdown, Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { IconType } from 'react-icons';
import { BiChevronDown } from 'react-icons/bi';
import Loader from 'react-spinners/PulseLoader';

import {
   IconNewChat,
   IconSendChat,
   IconMenuWorkspaces,
   IconCheckBox,
   IconCheckBoxChecked,
   IconInternal,
} from '../utilities/icons';
import { Button, InlineInput } from '../components';
import {
   PersonUtilities,
   DataChatThread,
   DataChatMessage,
   DataChatMessageQuery,
   DataChatThreadState,
   useOpenQuery,
   DataChatMessageRole,
   DataChatMessageSuggestion,
   Person,
   QueryVersion,
} from '../entities';
import { getShortDateTimeString } from '../utilities/formatters';
import { usePerson, useAddDataChatMessage, usePatchDataChatThread } from '../hooks';
import { PersonRole } from '../enums';

type SendCallback = (message: Partial<DataChatMessage>, thread?: Partial<DataChatThread>) => void;

export type ChatSuggestion = {
   icon?: IconType;
   label: string;
   onClick: () => void;
};

const SuggestionButton = ({ icon, label, onClick }: ChatSuggestion) => {
   const CustomIcon = icon;
   return (
      <Button colorScheme="secondary" onClick={onClick} size="md" style={{ borderRadius: '8px' }}>
         <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
            {CustomIcon && <CustomIcon size="20px" />}
            <span style={{ fontSize: '14px', fontWeight: '400' }}>{label}</span>
         </div>
      </Button>
   );
};

const ChatStart = ({
   greeting,
   onSend,
   suggestions,
   footer,
}: {
   footer?: React.ReactNode;
   greeting?: string;
   onSend: SendCallback;
   suggestions?: ChatSuggestion[];
}): JSX.Element => {
   return (
      <Stack
         className="w-100 h-100 p-3 justify-content-center align-items-center position-relative"
         gap={3}
      >
         <h1 className="m-0" style={{ opacity: '75%', fontSize: '30px', fontWeight: '600' }}>
            {greeting ?? 'How can I help?'}
         </h1>
         <ChatInput big onSend={onSend} thread={null} />
         {!!suggestions?.length && (
            <Stack className="justify-content-center" direction="horizontal" gap={2}>
               {suggestions.map((suggestion, index) => (
                  <SuggestionButton key={index} {...suggestion} />
               ))}
            </Stack>
         )}
         <div className="position-absolute w-100 py-2 px-3" style={{ bottom: '0' }}>
            {footer}
         </div>
      </Stack>
   );
};

const ChatBubble = ({
   message,
   person,
   side,
   renderQueries,
}: {
   message: Partial<DataChatMessage>;
   person: Person;
   renderQueries: (queries: DataChatMessageQuery[]) => React.ReactNode;
   side: 'ours' | 'theirs';
}): JSX.Element => {
   const currentUser = usePerson();
   return (
      <>
         <div
            className={classNames(
               'card chat-bubble py-2 px-3',
               side === 'ours' ? 'bg-secondary' : 'bg-primary',
               currentUser.id === person.id ? 'chat-bubble-right' : 'chat-bubble-left',
               { 'chat-bubble-internal': message.isInternal },
               { 'chat-bubble-wide': !!message.messageQueries?.length }
            )}
         >
            <Stack gap={2}>
               <div>
                  {message.isInternal && (
                     <>
                        <IconInternal />{' '}
                     </>
                  )}
                  {message.content}
               </div>

               <Stack
                  className="query-card-footer fs-9p align-items-end w-100 text-muted"
                  direction="horizontal"
                  gap={1}
               >
                  <div>{getShortDateTimeString(message.created)}</div>
                  <div className="fw-500 potential-badge">
                     – {PersonUtilities.getFullName(person)}
                  </div>
               </Stack>

               {currentUser.role !== PersonRole.ORG_BUSINESS_USER &&
                  !!message.messageQueries?.length &&
                  renderQueries(message.messageQueries)}
            </Stack>
         </div>
         {currentUser.role === PersonRole.ORG_BUSINESS_USER &&
            !!message.messageQueries?.length &&
            renderQueries(message.messageQueries)}
      </>
   );
};

const ChatInput = ({
   big,
   currentQueryVersion,
   disabled,
   onSend,
   suggestions,
   thread,
}: {
   big?: boolean;
   currentQueryVersion?: QueryVersion;
   disabled?: boolean;
   onSend: SendCallback;
   suggestions?: DataChatMessageSuggestion[];
   thread: DataChatThread | null;
}): JSX.Element => {
   const [includeQuery, setIncludeQuery] = useState(false);
   const [message, setMessage] = useState<string>('');
   const inputRef = useRef<HTMLInputElement>(null);
   const person = usePerson();
   const openQuery = useOpenQuery();
   const patchDataChatThread = usePatchDataChatThread();

   // Clear the input when switching threads
   useEffect(() => {
      setMessage('');
   }, [thread?.id]);

   const prompt =
      person.role === PersonRole.ORG_BUSINESS_USER
         ? !thread || thread?.state === DataChatThreadState.NEW
            ? 'Ask Runa a question'
            : thread?.assignee
            ? `Respond to ${thread.assignee.firstName}`
            : 'Add a message'
         : thread?.creator
         ? `Respond to ${thread.creator.firstName}`
         : 'Add a message';

   const newQuery = () =>
      openQuery({
         workspaceId: thread?.workspaceId,
         dataChatThreadId: thread?.id,
         newTab: true,
         source: 'answers',
         queryVersion: {
            steps: [],
         },
      });

   const sendMessage = () => {
      onSend(
         {
            content: message,
            ...(currentQueryVersion?.version && includeQuery
               ? { queryVersionId: currentQueryVersion.id }
               : {}),
         },
         {
            state:
               person.role === PersonRole.ORG_BUSINESS_USER ? DataChatThreadState.OPEN : undefined,
         }
      );
      setMessage('');
      setIncludeQuery(false);
   };

   return (
      <Form
         className="w-100 align-items-center"
         onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
         }}
         style={{ maxWidth: big ? '768px' : undefined }}
      >
         <Stack gap={2}>
            {!suggestions &&
               (!thread ||
                  !(
                     person.role === PersonRole.ORG_BUSINESS_USER &&
                     [DataChatThreadState.CANCELLED, DataChatThreadState.ACCEPTED].includes(
                        thread?.state
                     )
                  )) && (
                  <InlineInput
                     autoFocus
                     className={classNames(
                        'chat-input',
                        big ? 'form-control fs-14p' : 'small-form-control-input'
                     )}
                     disabled={disabled}
                     multiLine
                     onChange={(value) => setMessage(value)}
                     onEnter={sendMessage}
                     placeholder={prompt}
                     ref={inputRef}
                     value={message}
                  />
               )}
            <Stack className="justify-content-end" direction="horizontal" gap={2}>
               {currentQueryVersion && !currentQueryVersion.version && (
                  <div>
                     <i>Save to include data</i>
                  </div>
               )}
               {suggestions && (
                  <>
                     {suggestions.map((response, i) => (
                        <Button
                           key={i}
                           onClick={() => onSend(response, { state: response.nextState })}
                        >
                           {response.content}
                        </Button>
                     ))}
                  </>
               )}
               {thread && person.role !== PersonRole.ORG_BUSINESS_USER && (
                  <Dropdown>
                     <Dropdown.Toggle size="sm" variant="secondary">
                        {thread?.state === DataChatThreadState.PENDING && (
                           <>Waiting for Requestor</>
                        )}
                        {thread?.state === DataChatThreadState.OPEN && <>Waiting for Analyst</>}
                        {thread?.state === DataChatThreadState.SOLVED && <>Solved</>}
                        {thread?.state === DataChatThreadState.CANCELLED && <>Cancelled</>}
                        {thread?.state === DataChatThreadState.ACCEPTED && <>Accepted</>}
                        <BiChevronDown size={16} />
                     </Dropdown.Toggle>
                     <Dropdown.Menu className="fs-10p">
                        <Dropdown.Item
                           active={thread?.state === DataChatThreadState.PENDING}
                           onClick={() => {
                              patchDataChatThread.mutate({
                                 id: thread.id!,
                                 data: { state: DataChatThreadState.PENDING },
                              });
                           }}
                        >
                           Waiting for Requestor
                        </Dropdown.Item>
                        <Dropdown.Item
                           active={thread?.state === DataChatThreadState.OPEN}
                           onClick={() => {
                              patchDataChatThread.mutate({
                                 id: thread.id!,
                                 data: { state: DataChatThreadState.OPEN },
                              });
                           }}
                        >
                           Waiting for Analyst
                        </Dropdown.Item>
                        <Dropdown.Item
                           active={thread?.state === DataChatThreadState.SOLVED}
                           onClick={() => {
                              patchDataChatThread.mutate({
                                 id: thread.id!,
                                 data: { state: DataChatThreadState.SOLVED },
                              });
                           }}
                        >
                           Solved
                        </Dropdown.Item>
                        <Dropdown.Item
                           active={thread?.state === DataChatThreadState.CANCELLED}
                           onClick={() => {
                              patchDataChatThread.mutate({
                                 id: thread.id!,
                                 data: { state: DataChatThreadState.CANCELLED },
                              });
                           }}
                        >
                           Cancelled
                        </Dropdown.Item>
                        {thread?.assignee && (
                           <>
                              <Dropdown.Divider />
                              <Dropdown.Item
                                 active={thread?.state === DataChatThreadState.CANCELLED}
                                 onClick={() => {
                                    patchDataChatThread.mutate({
                                       id: thread.id!,
                                       data: { assigneeId: null },
                                    });
                                 }}
                              >
                                 Unassign
                              </Dropdown.Item>
                           </>
                        )}
                     </Dropdown.Menu>
                  </Dropdown>
               )}
               {!currentQueryVersion &&
                  person.role !== PersonRole.ORG_BUSINESS_USER &&
                  thread?.creatorWorkspaces && (
                     <>
                        <Dropdown>
                           <Dropdown.Toggle size="sm" variant="secondary">
                              <IconMenuWorkspaces className="me-1" size="12px" />
                              {
                                 thread.creatorWorkspaces.find((w) => w.id === thread.workspaceId)
                                    ?.name
                              }
                              <BiChevronDown size={16} />
                           </Dropdown.Toggle>
                           <Dropdown.Menu className="fs-10p">
                              {thread.creatorWorkspaces.map((w) => (
                                 <Dropdown.Item
                                    active={thread.workspaceId === w.id}
                                    key={w.id}
                                    onClick={() => {
                                       patchDataChatThread.mutate({
                                          id: thread.id!,
                                          data: { workspaceId: w.id },
                                       });
                                    }}
                                 >
                                    {w.name}
                                 </Dropdown.Item>
                              ))}
                           </Dropdown.Menu>
                        </Dropdown>
                        <Button colorScheme="secondary" onClick={newQuery}>
                           New Query
                        </Button>
                     </>
                  )}
               {currentQueryVersion?.version && (
                  <Button
                     colorScheme="secondary"
                     onClick={() => setIncludeQuery(!includeQuery)}
                     style={{
                        flexBasis: 'content',
                     }}
                  >
                     {includeQuery ? (
                        <IconCheckBoxChecked size="20px" />
                     ) : (
                        <IconCheckBox size="20px" />
                     )}
                     &nbsp;Include Data
                  </Button>
               )}
               <Button type="submit">
                  <IconSendChat size="16px" />
               </Button>
            </Stack>
         </Stack>
      </Form>
   );
};

export const ChatHistory = ({
   threads,
   currentId,
   onChange,
   title,
   noNewThread,
}: {
   currentId: number | null;
   noNewThread?: boolean;
   onChange: (thread: DataChatThread | null) => void;
   threads: DataChatThread[];
   title: string;
}): JSX.Element => {
   const person = usePerson();
   return (
      <Nav className="h-100 overflow-y-auto flex-column" style={{ flexWrap: 'nowrap' }}>
         <Stack
            className="m-2 mt-1 me-0 justify-content-between align-items-center"
            direction="horizontal"
            gap={2}
         >
            <div className="fw-bold fs-16">{title}</div>
            {!noNewThread && (
               <Button
                  colorScheme="secondary"
                  onClick={() => onChange(null)}
                  size="sm"
                  style={{ height: '100%' }}
               >
                  <IconNewChat size="16px" />
               </Button>
            )}
         </Stack>
         {threads.map((thread) => (
            <Nav.Link
               active={thread.id === currentId}
               className={classNames({
                  unread:
                     person.role === PersonRole.ORG_BUSINESS_USER &&
                     thread.latestMessageAt > thread.creatorLastViewedAt,
               })}
               key={thread.id}
               onClick={(e) => {
                  e.preventDefault();
                  onChange(thread);
               }}
            >
               <Stack gap={1}>
                  <div className="truncate-lines truncate-lines-2">{thread.title}</div>
                  {person.id !== thread.creator?.id && (
                     <div className="text-nowrap">
                        {thread.state !== DataChatThreadState.OPEN && <>&gt; </>}
                        {PersonUtilities.getFullName(thread.creator)}
                        {thread.state !== DataChatThreadState.OPEN && <> &lt;</>}
                     </div>
                  )}
                  {thread.assignee &&
                     [DataChatThreadState.OPEN, DataChatThreadState.PENDING].includes(
                        thread.state
                     ) && (
                        <div className="text-nowrap">
                           {thread.state === DataChatThreadState.OPEN && <>&gt; </>}
                           {PersonUtilities.getFullName(thread.assignee)}
                           {thread.state === DataChatThreadState.OPEN && <> &lt;</>}
                        </div>
                     )}
                  {thread.state === DataChatThreadState.ACCEPTED && <div>Accepted</div>}
                  {thread.state === DataChatThreadState.CANCELLED && <div>Cancelled</div>}
                  {thread.state === DataChatThreadState.SOLVED && <div>Solved</div>}
                  <div className="text-nowrap">
                     {getShortDateTimeString(thread.latestMessageAt)}
                  </div>
               </Stack>
            </Nav.Link>
         ))}
      </Nav>
   );
};

export const Chat = ({
   greeting,
   renderQueries,
   suggestions,
   thread,
   newChatFooter,
   noNewThread,
   onNewThread,
   currentQueryVersion,
}: {
   currentQueryVersion?: QueryVersion;
   greeting?: string;
   newChatFooter?: React.ReactNode;
   noNewThread?: boolean;
   onNewThread?: (thread: DataChatThread) => void;
   renderQueries: (queries: DataChatMessageQuery[]) => React.ReactNode;
   suggestions?: ChatSuggestion[];
   thread: DataChatThread | null;
}): JSX.Element => {
   const [messages, setMessages] = useState<Partial<DataChatMessage>[]>(thread?.messages ?? []);
   useEffect(() => {
      setMessages(thread?.messages ?? []);
   }, [thread?.messages]);
   const runaResponding =
      (!thread?.state || thread?.state === DataChatThreadState.NEW) &&
      messages.length > 0 &&
      messages[messages.length - 1].role === DataChatMessageRole.CREATOR;
   const scrollRef = useRef<HTMLDivElement | null>(null);
   const person = usePerson();
   const patchDataChatThread = usePatchDataChatThread();

   const addDataChatMessage = useAddDataChatMessage();
   const onSend: SendCallback = (message, newThread) => {
      let newState = newThread?.state;
      if (person.role !== PersonRole.ORG_BUSINESS_USER) {
         newState ??= DataChatThreadState.PENDING;
      }
      setMessages([
         ...messages,
         {
            ...message,
            role:
               person.role === PersonRole.ORG_BUSINESS_USER
                  ? DataChatMessageRole.CREATOR
                  : DataChatMessageRole.ANALYST,
         },
      ]);
      addDataChatMessage.mutate(
         {
            id: newThread?.id ?? message.threadId ?? thread?.id,
            message: {
               content: message.content,
               queryVersionId: message.queryVersionId,
            },
            state: newState,
         },
         {
            onSuccess: (newThread, oldThread) => {
               if (!oldThread.id) {
                  onNewThread?.(newThread);
               }
            },
         }
      );
   };

   // Scroll to the bottom when Runa responds
   useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
   }, [runaResponding, messages.length]);

   // Update view teimstamp
   const patchDataChatThreadMutate = patchDataChatThread.mutate;
   useEffect(() => {
      if (person.role !== PersonRole.ORG_BUSINESS_USER) return;
      if (!thread?.latestMessageAt) return;
      if (thread.latestMessageAt > thread.creatorLastViewedAt) {
         patchDataChatThreadMutate({
            id: thread.id!,
            data: { viewedByCreator: true },
         });
      }
   }, [
      person.role,
      thread?.id,
      thread?.latestMessageAt,
      thread?.creatorLastViewedAt,
      patchDataChatThreadMutate,
   ]);

   if (messages.length === 0) {
      if (noNewThread) {
         return (
            <div className="h-100 d-flex justify-content-center align-items-center">
               Select a thread.
            </div>
         );
      } else {
         return (
            <ChatStart
               footer={newChatFooter}
               greeting={greeting}
               onSend={onSend}
               suggestions={suggestions}
            />
         );
      }
   }
   const lastMessage = messages[messages.length - 1];
   const responses = lastMessage.metadata?.success
      ? lastMessage.metadata?.data.suggestedResponses
      : undefined;
   return (
      <div className="h-100 d-flex flex-column justify-content-end">
         <Stack className="p-3 pb-0 overflow-auto flex-grow-0" gap={3}>
            {messages.map((message, index) => (
               <Stack className="flex-grow-0" gap={2} key={index}>
                  <Stack
                     gap={2}
                     ref={!runaResponding && index === messages.length - 1 ? scrollRef : undefined}
                  >
                     {message.content && (
                        <ChatBubble
                           message={message}
                           person={
                              message.role === DataChatMessageRole.CREATOR
                                 ? thread?.creator ?? person
                                 : message.role === DataChatMessageRole.SYSTEM
                                 ? {
                                      firstName: 'Runa',
                                   }
                                 : message.person ?? person
                           }
                           renderQueries={renderQueries}
                           side={
                              (person.role === PersonRole.ORG_BUSINESS_USER) ===
                              (message.role === DataChatMessageRole.CREATOR)
                                 ? 'ours'
                                 : 'theirs'
                           }
                        />
                     )}
                  </Stack>
               </Stack>
            ))}
            {runaResponding && <Loader color="#6366f1" size={6} />}
            {runaResponding && <div ref={scrollRef} />}
         </Stack>
         <Stack className="flex-grow-0 pt-3 pb-2 px-3" gap={2}>
            <ChatInput
               currentQueryVersion={currentQueryVersion}
               onSend={onSend}
               suggestions={responses}
               thread={thread}
            />
         </Stack>
      </div>
   );
};

export default Chat;
