import React, { createContext, useState, useContext, useCallback } from 'react';

import { DataConnection, QueryVersion } from '../entities';

type QueryProviderProps = {
   // If not set, queryVersion is read-only
   onChange?: (
      changes: Partial<
         Pick<
            QueryVersion,
            'steps' | 'title' | 'description' | 'question' | 'parameters' | 'chartConfig'
         >
      >,
      options: { debounce?: boolean }
   ) => void;
   queryVersion?: QueryVersion;
};

type QueryContextType = QueryProviderProps & {
   editResultsDataConnections: DataConnection[];
   editResultsSchemaNames: string[];
   onParamChange: (key: string, value: string) => void;
   paramOverrides: Record<string, string>;
   setEditResultsDataConnections: (value: DataConnection[]) => void;
   setEditResultsSchemaNames: (value: string[]) => void;
};

const noopDC: React.Dispatch<React.SetStateAction<DataConnection[]>> = () => {};
const noopSN: React.Dispatch<React.SetStateAction<string[]>> = () => {};

const QueryContext = createContext<QueryContextType>({
   editResultsDataConnections: [],
   editResultsSchemaNames: [],
   onParamChange: (_, __) => {},
   paramOverrides: {},
   setEditResultsDataConnections: noopDC,
   setEditResultsSchemaNames: noopSN,
});

export function QueryProvider({
   onChange,
   queryVersion,
   children,
}: React.PropsWithChildren<QueryProviderProps>) {
   const [paramOverrides, setParamOverrides] = useState<Record<number, Record<string, string>>>({});
   const [editResultsDataConnections, setEditResultsDataConnections] = useState<
      Record<number, DataConnection[]>
   >({});
   const [editResultsSchemaNames, setEditResultsSchemaNames] = useState<Record<number, string[]>>(
      {}
   );
   const onParamChange = useCallback(
      (key: string, value: string) => {
         if (typeof queryVersion?.id !== 'number') {
            return;
         }

         if (onChange) {
            // If changing the query is supported, we don't need param overrides, since changing the
            // param values updates queryVersion.parameters.
            return;
         }

         setParamOverrides((prev) => ({
            ...prev,
            [queryVersion.id!]: {
               ...prev[queryVersion.id!],
               [key]: value,
            },
         }));
      },
      [onChange, queryVersion?.id]
   );

   const setEditResultsDataConnectionsForCurrentQueryVersion = useCallback(
      (value: DataConnection[]) => {
         if (typeof queryVersion?.id !== 'number') {
            return;
         }

         setEditResultsDataConnections((prev) => ({
            ...prev,
            [queryVersion.id!]: value,
         }));
      },
      [queryVersion?.id]
   );

   const setEditResultsSchemaNamesForCurrentQueryVersion = useCallback(
      (value: string[]) => {
         if (typeof queryVersion?.id !== 'number') {
            return;
         }

         setEditResultsSchemaNames((prev) => ({
            ...prev,
            [queryVersion.id!]: value,
         }));
      },
      [queryVersion?.id]
   );

   return (
      <QueryContext.Provider
         value={{
            queryVersion,
            paramOverrides:
               typeof queryVersion?.id === 'number' ? paramOverrides[queryVersion.id] ?? {} : {},
            onChange,
            onParamChange,
            editResultsDataConnections:
               typeof queryVersion?.id === 'number'
                  ? editResultsDataConnections[queryVersion.id] ?? []
                  : [],
            editResultsSchemaNames:
               typeof queryVersion?.id === 'number'
                  ? editResultsSchemaNames[queryVersion.id] ?? []
                  : [],
            setEditResultsDataConnections: setEditResultsDataConnectionsForCurrentQueryVersion,
            setEditResultsSchemaNames: setEditResultsSchemaNamesForCurrentQueryVersion,
         }}
      >
         {children}
      </QueryContext.Provider>
   );
}

export function useCurrentQuery() {
   return useContext(QueryContext);
}
