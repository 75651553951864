import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { StatsService, ExploreTabService, QUERY_SOURCE } from '../services';
import { TYPES } from '../types';
import { DBMS, ExploreTabType, QueryKey } from '../enums';
import { handleError } from '../utilities';
import { useExtension, useCurrentStep, fetchExploreTabQueryKey } from '../hooks';
import { QueryVersionLog } from './';

import { Person, QueryLog, QueryParameter, QueryVersion, SchemaCache, Workspace } from './';
import { Base } from './Base';

export interface ExploreTabSummary extends Base {
   dataConnectionAccessType?: number;
   dataConnectionDBMS?: DBMS;
   generatedLabel?: string | null;
   orgId?: number;
   personId?: number;
   queryId?: number;
   queryModified?: Date;
   queryParentId?: number;
   queryPreviews?: string[];
   queryTitle?: string;
   queryVersionId?: number;
   savedQueryVersion?: string;
   tableName?: string;
   tableSchemaId?: number;
   type?: ExploreTabType;
   workspaceConnectionId?: number;
   workspaceId?: number;
}

export interface ExploreTab extends Base {
   dataChatThreadId?: number | null;
   generatedLabel?: string | null;
   parameters?: QueryParameter[];
   person?: Person;
   personId?: number;
   queryLogs?: QueryLog[];
   queryVersion?: QueryVersion;
   queryVersionId?: number;
   tableSchema?: SchemaCache;
   tableSchemaId?: number;
   type?: ExploreTabType;
   workspace?: Workspace;
   workspaceId?: number;
}

export type ExploreTabPost = Pick<
   ExploreTab,
   'queryVersion' | 'queryVersionId' | 'tableSchemaId' | 'type'
> & {
   queryId?: number;
   workspaceId: number;
};

export type ExploreTabPatch = Pick<
   ExploreTab,
   'queryVersion' | 'queryVersionId' | 'parameters' | 'dataChatThreadId'
>;

// Usage: const openQuery = useOpenQuery({queryVersion, exploreTabId})
//
// queryVersion can either be an existing version with an id, or a new version
// without an id.
//
// If exploreTabId is not provided, a new tab will be created.
export const useOpenQuery = ({ handler }: { handler?: (url: string) => void } = {}) => {
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   const statsService = useInjection<StatsService>(TYPES.statsService);
   const queryClient = useQueryClient();
   const navigate = useNavigate();
   const urlParams = useParams();
   const [searchParams] = useSearchParams();
   const tabId = searchParams.has('t') ? Number(searchParams.get('t')) : undefined;
   const extension = useExtension();

   const tabMutation = useMutation({
      mutationFn: async ({
         queryVersion,
         workspaceId,
         exploreTabId,
         dataChatThreadId,
      }: {
         autoRun?: boolean;
         dataChatThreadId?: number;
         exploreTabId?: number;
         queryVersion: QueryVersion;
         workspaceId: number;
      }) => {
         const body = {
            workspaceId,
            ...(queryVersion.id === undefined
               ? { queryVersion }
               : { queryVersionId: queryVersion.id }),
            type: ExploreTabType.Query,
            dataChatThreadId,
         };
         if (exploreTabId) {
            return await exploreTabService.patch(exploreTabId, body);
         } else {
            return await exploreTabService.post(body);
         }
      },
      onSuccess: async (data, variables) => {
         if (data?.queryVersion) {
            const params = new URLSearchParams({
               tabOpened: '1',
               ...(data.queryVersion.id ? { v: data.queryVersion.id.toString() } : {}),
               ...(data.id ? { t: data.id.toString() } : {}),
               ...(variables.autoRun ? { autoRun: '1' } : {}),
            }).toString();
            const path = `/workspaces/${data.workspaceId}/query/${data.queryVersion.queryId}?${params}`;
            if (handler) {
               handler(`${window.location.origin}${path}`);
            } else {
               navigate(path);
            }

            // Must invalidate the query data AFTER updating the URL, otherwise the useEffect in
            // ExploreTabNav will add a new tab if the URL state doesn't match the ExploreTabSummary
            // list (tabOpened=1 prevents that action from happening).

            const invalidateQueryPromises = [
               queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', variables.workspaceId]),
            ];

            if (variables.exploreTabId !== undefined) {
               invalidateQueryPromises.push(
                  queryClient.invalidateQueries(
                     fetchExploreTabQueryKey({
                        type: 'invalidate',
                        data: { exploreTabId: variables.exploreTabId },
                     })
                  )
               );
            }

            await Promise.all(invalidateQueryPromises);
         }
      },
      onError: handleError,
   });

   const [currentStep] = useCurrentStep();

   return useCallback(
      async ({
         queryVersion,
         exploreTabId,
         workspaceId,
         dataChatThreadId,
         fork,
         newDraft,
         newTab,
         autoRun,
         source = null,
      }: {
         autoRun?: boolean;
         dataChatThreadId?: number;
         exploreTabId?: number;
         fork?: boolean;
         newDraft?: boolean;
         newTab?: boolean;
         queryVersion: QueryVersion;
         source?: QUERY_SOURCE | null;
         workspaceId?: number;
      }): Promise<QueryVersion> => {
         fork ??= false;
         workspaceId ??= queryVersion.query?.workspaceId;
         if (urlParams.workspaceId) {
            workspaceId ??= parseInt(urlParams.workspaceId);
         }
         if (!workspaceId) {
            throw new Error('useOpenQuery missing workspaceId');
         }
         exploreTabId ??= tabId;
         if (newTab) {
            exploreTabId = undefined;
         }

         const isLog = (queryVersion as QueryVersionLog).logId !== undefined;
         if ((isLog || !queryVersion.id) && exploreTabId && currentStep && !fork) {
            currentStep.replaceAll(queryVersion.steps);
            return currentStep.queryVersion;
         } else if (isLog || fork) {
            const {
               id: _1,
               query: _2,
               queryId: _3,
               version: _4,
               minorVersion: _5,
               ...newVersion
            } = { ...queryVersion, parent: queryVersion, parentId: queryVersion.id };
            queryVersion = newVersion;
         } else if (newDraft) {
            const {
               id: _1,
               version: _2,
               ...newVersion
            } = {
               ...queryVersion,
               parentId: queryVersion.id,
            };
            queryVersion = newVersion;
         }
         if (source) {
            statsService.addUseQueryClick({
               destination: extension ? 'extension' : 'app',
               query: queryVersion.steps?.map((s) => s.queryText)?.join(';\n') ?? '',
               workspaceId,
               queryVersionId: queryVersion.id,
               source,
            });
         }
         const newVersion = (
            await tabMutation.mutateAsync({
               queryVersion,
               workspaceId,
               exploreTabId,
               dataChatThreadId,
               autoRun,
            })
         )?.queryVersion;
         if (!newVersion) {
            throw new Error('Failed to open query');
         }
         return newVersion;
      },
      [extension, tabMutation, statsService, urlParams.workspaceId, tabId, currentStep]
   );
};

export const useOpenTableTab = ({ handler }: { handler?: (url: string) => void } = {}) => {
   const exploreTabService = useInjection<ExploreTabService>(TYPES.exploreTabService);
   const queryClient = useQueryClient();
   const navigate = useNavigate();

   const openTabMutation = useMutation({
      mutationFn: async ({
         tableSchemaId,
         workspaceId,
      }: {
         tableSchemaId: number;
         workspaceId: number;
      }) => {
         return exploreTabService.post({ workspaceId, tableSchemaId, type: ExploreTabType.Table });
      },
      onSuccess: async (data) => {
         if (data?.workspaceId) {
            await queryClient.invalidateQueries([QueryKey.ExploreTab, 'list', data.workspaceId]);
            const path = `/workspaces/${data.workspaceId}/table/${data.tableSchemaId}`;
            if (handler) {
               handler(`${window.location.origin}${path}`);
            } else {
               navigate(path);
            }
         }
      },
      onError: handleError,
   });

   return useCallback(
      ({ tableSchemaId, workspaceId }: { tableSchemaId: number; workspaceId: number }) => {
         return openTabMutation.mutateAsync({ tableSchemaId, workspaceId });
      },
      [openTabMutation]
   );
};
