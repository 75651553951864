import { inject, injectable } from 'inversify';
import _chunk from 'lodash/chunk';

import { getErrorMessage } from '@runql/util';

import {
   ConnectionAccessType,
   DataConnection,
   DataConnectionSchema,
   DataCredential,
   PaginatedResult,
   SchemaCache,
} from '../entities';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';
import { DesktopQueryService, isDesktop } from './DesktopQueryService';

import type { ReadSchemaTarget, SchemaItem } from '@runql/util';
import type { LocalCredentialService } from '../services/LocalCredentialService';

const path = '/v1/dataConnection';

export interface DataConnectionListOptions {
   countOnly?: boolean;
   withNewMetadata?: 'filter' | 'flag';
}

export interface DataConnectionGetOptions {
   flagNewMetadata?: boolean;
   includeCredentials?: boolean;
   includeSchemaCache?: boolean;
}

export interface DataConnectionSchemaGetOptions {
   viewAll?: boolean;
}

export interface UpdateSchemaVisibilityPayload {
   schemas: {
      catalog?: string;
      schema: string;
      visible: boolean;
   }[];
}

export type EditorSchema = {
   // Full-qualified table names to column names
   schema: Record<string, string[]>;
};

export type PostResult = {
   connection: DataConnection;
   schemaGenError?: string;
};

@injectable()
export class DataConnectionService implements ApiServiceInterface<DataConnection> {
   constructor(
      @inject(TYPES.apiService) private apiService: ApiService,
      @inject(TYPES.localCredentialService)
      private localCredentialService: LocalCredentialService,
      @inject(TYPES.desktopQueryService)
      private desktopQueryService: DesktopQueryService
   ) {}

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   async get(
      id: string | number,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const result = await this.apiService.get<DataConnection>(`${path}/${id}`, params);
      if (!result) {
         return undefined;
      }

      return result as DataConnection;
   }

   getOptions(
      id: number | undefined,
      options: DataConnectionGetOptions | undefined
   ): Promise<DataConnection | undefined> | undefined {
      if (id === undefined) {
         return undefined;
      }
      const params: Record<string, string> = {};
      if (options !== undefined) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      return this.get(id, params);
   }

   async list(params?: Record<string, string>): Promise<PaginatedResult<DataConnection>> {
      const result = await this.apiService.get<PaginatedResult<DataConnection>>(path, params);
      if (!result) {
         return { items: [], totalItems: 0 };
      }

      return result;
   }

   listOptions(filter?: DataConnectionListOptions): Promise<PaginatedResult<DataConnection>> {
      const params: Record<string, string> = {};
      if (filter) {
         for (const k in filter) {
            const v = filter[k as keyof typeof filter];
            if (v) {
               params[k] = v.toString();
            }
         }
      }
      return this.list(params);
   }

   async listSchemas(params?: Record<string, string>): Promise<DataConnectionSchema[]> {
      const result = await this.apiService.get<DataConnectionSchema[]>(`${path}/schemas`, params);
      if (!result) {
         return [];
      }

      return result;
   }

   async patch(
      id: string | number,
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const dataCredentialFromInput = body.dataCredentials?.[0];

      if (body.connectionAccessType === ConnectionAccessType.INDIVIDUAL && isDesktop()) {
         // Do not send credentials in the request when updating an individual connection from
         // the Desktop app.
         body.dataCredentials = undefined;
      }

      const formData = this.apiService.toFormData({
         ...body,
         // Clear this field if it's not longer set
         sslCaCertId: body.sslCaCertId ?? null,
      });

      const result = await this.apiService.patch<DataConnection>(`${path}/${id}`, formData, params);

      if (!result?.id) {
         return undefined;
      }

      if (result.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
         // When updating an existing connection, `dataCredentialFromInput` /
         // `result.dataCredentials[0]` may have missing or `undefined` values for
         // 'accountPassword' and/or 'sshPassword' if their values were not changed. So we need
         // to fetch the existing values and merge them with the data that will be stored.

         const existingCredentialData = await this.localCredentialService.get(result.id);

         const { accountPassword, sshPassword } = existingCredentialData ?? {};

         const useSsh = result.useSSH ?? false;

         const mergeCredentialData = (newData: DataCredential) => ({
            accountPassword,
            sshPassword: useSsh ? sshPassword : undefined,
            // We need to omit undefined values from `dataCredentialFromInput` for the merging to
            // work as desired.
            ...Object.fromEntries(
               Object.entries(newData).filter(([_, value]) => value !== undefined)
            ),
            dataConnectionId: result.id!,
         });

         if (isDesktop()) {
            if (dataCredentialFromInput) {
               await this.localCredentialService.set(
                  result.id,
                  mergeCredentialData(dataCredentialFromInput)
               );
            }
         } else {
            if (result.dataCredentials?.[0]) {
               await this.localCredentialService.set(
                  result.id,
                  mergeCredentialData({
                     ...result.dataCredentials[0],
                     credentialPersistence: dataCredentialFromInput?.credentialPersistence,
                  })
               );
            }
         }
      }

      return result;
   }

   async post(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<DataConnection | undefined> {
      const dataCredentialsFromInput = body.dataCredentials?.[0];

      if (!dataCredentialsFromInput) {
         return undefined;
      }

      if (body.connectionAccessType === ConnectionAccessType.INDIVIDUAL && isDesktop()) {
         // Do not send credentials in the request when creating a new individual connection from
         // the Desktop app.
         body.dataCredentials = undefined;
      }

      const formData = this.apiService.toFormData(body);

      const result = await this.apiService.post<PostResult>(`${path}`, formData, params);

      if (!result?.connection?.id) {
         return undefined;
      }

      let schemaGenError = result.schemaGenError;

      if (result.connection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
         const dataCredentialsToStore = isDesktop()
            ? dataCredentialsFromInput
            : result.connection.dataCredentials?.[0];

         if (dataCredentialsToStore) {
            await this.localCredentialService.set(result.connection.id, dataCredentialsToStore);
         }

         if (isDesktop()) {
            try {
               await this.updateSchemaRootsDesktop(result.connection);
            } catch (err) {
               // Don't fail the entire operation if we fail to update the schema
               schemaGenError = getErrorMessage(err);
               console.error('Error updating schema', err);
            }
         }
      }

      return { ...result.connection, schemaGenError };
   }

   async put(
      _body: DataConnection,
      _params?: Record<string, string> | undefined
   ): Promise<DataConnection | undefined> {
      throw new Error('Method not implemented.');
   }

   /**
    * @deprecated replaced with post
    */
   async postFromEntity(entity: DataConnection) {
      return this.post(entity);
   }

   async testConnection(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<{ message?: string; success: Boolean }> {
      const formData = this.apiService.toFormData(body);
      const result = await this.apiService.post<{ message?: string; success: Boolean }>(
         `${path}/testConnection`,
         formData,
         params
      );
      if (!result) {
         return { success: false, message: 'No response from server' };
      }

      return result;
   }

   async retestConnection(
      body: DataConnection,
      params?: Record<string, string>
   ): Promise<{ message?: string; success: Boolean }> {
      const formData = this.apiService.toFormData({
         ...body,
         // Clear this field if it's not longer set
         sslCaCertId: body.sslCaCertId ?? null,
      });
      const result = await this.apiService.post<{ message?: string; success: Boolean }>(
         `${path}/${body.id}/testConnection`,
         formData,
         params,
         await this.localCredentialService.generateHeaders([body.id!])
      );
      if (!result) {
         return { success: false, message: 'No response from server' };
      }
      return result;
   }

   async getEditorSchema(id: number | string): Promise<EditorSchema | undefined> {
      const result = await this.apiService.get<EditorSchema>(`${path}/${id}/editorSchema`);
      if (!result) {
         return undefined;
      }
      return result;
   }

   private async updateSchemaPost(
      id: number,
      payload: {
         schemaItems?: SchemaItem[];
         target?: { schemaName: string; tableName: string; type: 'table' };
      },
      { includeCredentialHeaders = true }: { includeCredentialHeaders?: boolean } = {}
   ) {
      const result = await this.apiService.post<{ success: true }>(
         `${path}/${id}/updateSchema`,
         payload,
         undefined,
         includeCredentialHeaders
            ? await this.localCredentialService.generateHeaders([id])
            : undefined
      );

      return result?.success ?? false;
   }

   async updateSchema(
      dataConnection: DataConnection,
      target?: { schemaName: string; tableName: string; type: 'table' }
   ): Promise<boolean> {
      if (isDesktop() && dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
         return this.updateSchemaDesktop(dataConnection, target);
      }

      const id = dataConnection.id;

      if (!id) {
         return false;
      }

      return this.updateSchemaPost(id, { target });
   }

   private convertToReadSchemaTarget(
      dataConnection: DataConnection,
      target: { schemaName: string; tableName: string; type: 'table' } | undefined
   ) {
      let readSchemaTarget: ReadSchemaTarget | undefined = undefined;
      if (target !== undefined) {
         readSchemaTarget = {
            ...target,
            catalogName: dataConnection.catalogName ?? undefined,
         };
      } else if (typeof dataConnection.catalogName === 'string') {
         readSchemaTarget = {
            type: 'catalog',
            catalogName: dataConnection.catalogName,
         };
      }

      return readSchemaTarget;
   }

   private async sendSchemaUpdate(
      dataConnection: DataConnection,
      schemaItems: SchemaItem[],
      target?: { schemaName: string; tableName: string; type: 'table' }
   ) {
      if (schemaItems.length === 0) {
         return true;
      }

      if (!dataConnection.id) {
         return false;
      }

      const CHUNK_SIZE = 2000;
      const chunks = _chunk(schemaItems, CHUNK_SIZE);

      if (chunks.length === 1) {
         return this.updateSchemaPost(
            dataConnection.id,
            { schemaItems: chunks[0], target },
            { includeCredentialHeaders: false }
         );
      }

      const [firstChunk, ...rest] = chunks;

      // This mutates rest; rest may be empty now
      const lastChunk = rest.pop();

      const createTaskResult = await this.apiService.post<{ id: number }>(
         '/v1/updateSchemaCacheTasks',
         {
            dataConnectionId: dataConnection.id,
            target: this.convertToReadSchemaTarget(dataConnection, target),
            data: firstChunk,
         }
      );

      if (!createTaskResult) {
         return false;
      }

      const { id: updateSchemaCacheTaskId } = createTaskResult;

      if (rest.length > 0) {
         const NUM_CONCURRENT_REQUESTS = 4;

         // Sequentially send NUM_CONCURRENT_REQUESTS at a time.
         await _chunk(rest, NUM_CONCURRENT_REQUESTS).reduce(async (accPromise, chunks) => {
            // This await is required to ensure each chunk of requests is sent sequentially.
            await accPromise;

            return Promise.all(
               chunks.map((chunk) => {
                  return this.apiService.post(
                     `/v1/updateSchemaCacheTasks/${updateSchemaCacheTaskId}`,
                     { data: chunk }
                  );
               })
            ).then(() => {
               // void
            });
         }, Promise.resolve());
      }

      const completeTaskResult = await this.apiService.post<{ state: string }>(
         `/v1/updateSchemaCacheTasks/${updateSchemaCacheTaskId}`,
         { data: lastChunk, isLastChunk: true }
      );

      return completeTaskResult;
   }

   async updateSchemaDesktop(
      dataConnection: DataConnection,
      target?: { schemaName: string; tableName: string; type: 'table' }
   ) {
      // This method is only supported on the Desktop client.
      if (!isDesktop()) {
         throw new Error('Bad implementation');
      }

      if (!dataConnection.id) {
         return false;
      }

      const schemaItems = await this.desktopQueryService.readSchema(
         dataConnection,
         this.convertToReadSchemaTarget(dataConnection, target)
      );

      if (!schemaItems) {
         return false;
      }

      const completeTaskResult = await this.sendSchemaUpdate(dataConnection, schemaItems, target);

      return completeTaskResult !== null;
   }

   async updateSchemaRootsDesktop(dataConnection: DataConnection) {
      // This method is only supported on the Desktop client.
      if (!isDesktop()) {
         throw new Error('Bad implementation');
      }

      if (!dataConnection.id) {
         return false;
      }

      const schemaItems = await this.desktopQueryService.readSchemaRoots(dataConnection);

      if (!schemaItems) {
         return false;
      }

      const completeTaskResult = await this.sendSchemaUpdate(dataConnection, schemaItems);

      return completeTaskResult !== null;
   }

   async getSchemaCache(
      dataConnectionId?: number,
      options?: DataConnectionSchemaGetOptions
   ): Promise<SchemaCache[]> {
      if (dataConnectionId === undefined) {
         return [];
      }

      const params: Record<string, string> = {};
      if (options) {
         for (const k in options) {
            const v = options[k as keyof typeof options];
            if (v) {
               params[k] = v.toString();
            }
         }
      }

      const result = await this.apiService.get<SchemaCache[]>(
         `${path}/${dataConnectionId}/schema`,
         params
      );
      if (!result) {
         return [];
      }

      return result;
   }

   async updateSchemaVisibility(
      dataConnectionId: number,
      body: UpdateSchemaVisibilityPayload
   ): Promise<void> {
      await this.apiService.patch(`${path}/${dataConnectionId}/updateSchemaVisibility`, body);

      return;
   }
}
