import { inject, injectable } from 'inversify';

import { DataCredential } from '../entities/DataCredentials';
import { TYPES } from '../types';
import { ApiService } from './ApiService';
import { ApiServiceInterface } from './ApiServiceInterface';
import { isDesktop } from './DesktopQueryService';

import { ConnectionAccessType } from '../entities';
import type { DataConnectionService } from '../services/DataConnectionService';
import type { LocalCredentialService } from '../services/LocalCredentialService';

const path = '/v1/dataCredential';

export interface listOptionsDataCredential {
   includeConnection?: boolean;
   personId?: number;
}

@injectable()
export class DataCredentialService implements ApiServiceInterface<DataCredential> {
   constructor(
      @inject(TYPES.apiService) private apiService: ApiService,
      @inject(TYPES.localCredentialService)
      private localCredentialService: LocalCredentialService,
      @inject(TYPES.dataConnectionService)
      private dataConnectionService: DataConnectionService
   ) {}

   async delete(id: string | number): Promise<null> {
      return await this.apiService.delete(`${path}/${id}`);
   }

   get(id: string | number): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }

   async list(params: Record<string, string>): Promise<DataCredential[]> {
      throw new Error('Method not implemented.');
   }

   listOptions(filter?: listOptionsDataCredential): Promise<DataCredential[]> {
      const params: Record<string, string> = {};

      if (filter?.personId) {
         if (filter.personId > 0) {
            params['personId'] = filter.personId.toString();
         } else {
            throw new Error('personId must be positive if defined');
         }
      }

      if (filter?.includeConnection !== undefined) {
         params['includeConnection'] = filter.includeConnection.toString();
      }

      return this.list(params);
   }

   patch(
      id: string | number,
      body: DataCredential,
      params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }

   async post(
      body: DataCredential,
      params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      const dataConnection = await this.dataConnectionService.get(body.dataConnectionId);

      if (!dataConnection) {
         return undefined;
      }

      // Do not make an API request when updating an individual connection from the Desktop app.
      if (isDesktop() && dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
         await this.localCredentialService.set(body.dataConnectionId, body);
         return body;
      }

      const result = await this.apiService.post<DataCredential>(`${path}`, body, params);

      if (!result) {
         return undefined;
      }

      if (dataConnection.connectionAccessType === ConnectionAccessType.INDIVIDUAL) {
         await this.localCredentialService.set(result.dataConnectionId, result);
      }

      return result;
   }

   async put(
      _body: DataCredential,
      _params?: Record<string, string> | undefined
   ): Promise<DataCredential | undefined> {
      throw new Error('Method not implemented.');
   }
}
