import React, { createContext, Dispatch, useContext, useState } from 'react';
import { queryPanelTabs } from '../components';

type QueryPanelContextType = {
   collapsedSize: number;
   expanded: boolean;
   queryTab: keyof typeof queryPanelTabs;
   setCollapsedSize: Dispatch<React.SetStateAction<number>>;
   setExpanded: Dispatch<React.SetStateAction<boolean>>;
   setQueryTab: Dispatch<React.SetStateAction<keyof typeof queryPanelTabs>>;
};

const QueryPanelContext = createContext<QueryPanelContextType>({
   expanded: false,
   setExpanded: () => {
      return false;
   },
   collapsedSize: 30,
   setCollapsedSize: () => {
      return 30;
   },
   queryTab: 'suggestions',
   setQueryTab: () => {
      return 'suggestions';
   },
});

export type PanelTabs = keyof typeof queryPanelTabs;

export function QueryPanelProvider({
   defaultTab,
   children,
}: React.PropsWithChildren<{
   defaultTab: PanelTabs;
}>) {
   const [expanded, setExpanded] = useState<boolean>(false);
   const [collapsedSize, setCollapsedSize] = useState(30);
   const [queryTab, setQueryTab] = useState<keyof typeof queryPanelTabs>(defaultTab);

   return (
      <QueryPanelContext.Provider
         value={{
            expanded,
            setExpanded,
            collapsedSize,
            setCollapsedSize,
            queryTab,
            setQueryTab,
         }}
      >
         {children}
      </QueryPanelContext.Provider>
   );
}

export function useQueryPanelContext() {
   return useContext(QueryPanelContext);
}
