import { useMemo, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';

import { SERVICE_PLANS } from '@runql/util';
import { useInjection } from 'inversify-react';
import { CollaboratorRole, CollaboratorRoleOrder, Org, Person } from '../../entities';
import { PersonRole, QueryKey, QueryKeyType } from '../../enums';
import {
   getWorkspaceCollaboratorQueryKey,
   useBulkUpdateWorkspaceCollabMutator,
   useGetAuthorizedExplorerQuery,
   useGetExplorerCollabForWorkspace,
   useListEligibleCollaborators,
   useListWorkspaceCollabQuery,
} from '../../hooks';
import { ListOptionsWorkspaceCollab, OrgService, WorkspaceCollabBulkPayload } from '../../services';
import { TYPES } from '../../types';
import { handleError, IconX, toTitleCase } from '../../utilities';

interface SelectOptionCollaborator {
   label: string;
   value: number | string | undefined;
}

const WorkspaceCollaboratorCard = ({
   collaboratorRemoved,
   disabled,
   id,
   name,
   role,
   roleChanged,
}: {
   collaboratorRemoved?: (id: number) => void;
   disabled: boolean;
   id: number;
   name: string;
   role: CollaboratorRole;
   roleChanged?: (id: number, newRole: CollaboratorRole) => void;
}) => {
   return (
      <Card className="border-1 px-4 py-2 shadow-sm fs-12p">
         <Form className="">
            <Row className="align-items-center">
               <Col lg={6} xs={12}>
                  {name}
               </Col>
               <Col lg={4} xs={12}>
                  {disabled ? (
                     <span>{toTitleCase(role)}</span>
                  ) : (
                     <Form.Select
                        aria-label="Role Select"
                        disabled={disabled}
                        onChange={(event) =>
                           roleChanged && roleChanged(id, event.target.value as CollaboratorRole)
                        }
                        value={role}
                     >
                        {Object.values(CollaboratorRole)
                           .sort((a, b) => CollaboratorRoleOrder[a] - CollaboratorRoleOrder[b])
                           .map((role) => (
                              <option key={role} value={role}>
                                 {toTitleCase(role)}
                              </option>
                           ))}
                     </Form.Select>
                  )}
               </Col>
               <Col lg={2} xs={12}>
                  {disabled ? (
                     <></>
                  ) : (
                     <button
                        className="btn btn-xs-link text-danger"
                        onClick={(e) => {
                           e.preventDefault();
                           collaboratorRemoved && collaboratorRemoved(id);
                        }}
                        title="Remove Collaborator"
                     >
                        <IconX size={24} />
                     </button>
                  )}
               </Col>
            </Row>
         </Form>
      </Card>
   );
};

export const ManageWorkspaceCollaborators = ({
   explorer,
   onClose,
   setShowUpgradeModal,
   show,
   workspaceId,
}: {
   explorer: Person | undefined;
   onClose: () => void;
   setShowUpgradeModal(): void;
   show: boolean;
   workspaceId: number;
}): JSX.Element => {
   const queryClient = useQueryClient();

   // State Variables
   const [newCollaborator, setNewCollaborator] = useState<SingleValue<SelectOptionCollaborator>>();
   const [newCollaboratorRole, setNewCollaboratorRole] = useState<CollaboratorRole>(
      CollaboratorRole.READER
   );
   const [currentCollaborators, setCurrentCollaborators] = useState<
      {
         action: 'add' | 'remove' | 'update' | 'invite' | undefined;
         id: number;
         name: string;
         role: CollaboratorRole;
      }[]
   >([]);

   const orgService = useInjection<OrgService>(TYPES.orgService);
   const orgs = useQuery<Org[]>([QueryKey.Org, 'list'], () =>
      orgService.list().catch((err) => {
         handleError(err);
         return [];
      })
   );
   const org = orgs.data?.[0];
   const plan = org?.plan;
   const planUsers = SERVICE_PLANS[plan ?? 0]?.users;
   const orgPeopleCount =
      org?.people?.filter((person) =>
         [PersonRole.ORG_ADMIN].includes(person.role ?? PersonRole.ORG_EXPLORER)
      ).length ?? 0;
   const isUserLimitReached = planUsers ? orgPeopleCount >= planUsers : false;

   // Queries
   const listCollabOptions: ListOptionsWorkspaceCollab = {
      personDetails: true,
      workspaceId,
   };
   const workspaceCollabQuery = useListWorkspaceCollabQuery({
      options: listCollabOptions,
      callbacks: {
         onSuccess(data) {
            if (data) {
               setCurrentCollaborators(
                  data.map((collaborator) => ({
                     id: collaborator.person?.id ?? -1,
                     name: collaborator.person?.firstName
                        ? `${collaborator.person?.firstName} ${collaborator.person.lastName}`
                        : collaborator.person?.email ?? 'UNKNOWN',
                     role: collaborator.role ?? CollaboratorRole.READER,
                     action: undefined,
                  }))
               );
            }
         },
      },
   });
   const eligibleCollabQuery = useListEligibleCollaborators({ workspaceId });
   const allCollabs = useMemo(() => {
      return (eligibleCollabQuery.data ?? []).concat(
         workspaceCollabQuery.data?.filter((c) => c.person)?.map((c) => c.person as Person) ?? []
      );
   }, [eligibleCollabQuery.data, workspaceCollabQuery.data]);

   const options: SelectOptionCollaborator[] = useMemo(
      () => [
         ...allCollabs
            .filter(
               (collab) =>
                  currentCollaborators.find(
                     (curr) => curr.id === collab.id && curr.action !== 'remove'
                  ) === undefined
            )
            .map?.((collaborator) => {
               return {
                  value: collaborator.id ?? -1,
                  label: collaborator?.firstName
                     ? `${collaborator.firstName} ${collaborator.lastName} - ${collaborator.email}`
                     : `${collaborator?.email}`,
               };
            }),
         // ...(newCollaborator ? [newCollaborator] : []),
      ],
      [allCollabs, currentCollaborators]
   );

   const refreshLists = async () => {
      await Promise.all([
         queryClient.invalidateQueries(
            getWorkspaceCollaboratorQueryKey({
               type: QueryKeyType.LIST,
               options: listCollabOptions,
            })
         ),
         queryClient.invalidateQueries([QueryKey.EligibleCollaborators, workspaceId]),
      ]);
   };

   const explorerQuery = useGetAuthorizedExplorerQuery();
   const explorerCollab = useGetExplorerCollabForWorkspace({
      workspaceId: workspaceId ?? -1,
   });

   const availableRoles = useMemo(() => {
      if (isUserLimitReached) return [CollaboratorRole.READER];

      const explorerLevel =
         CollaboratorRoleOrder[
            explorerQuery.data?.person.role === PersonRole.ORG_ADMIN
               ? CollaboratorRole.ADMIN // Org admins are always workspace admins
               : explorerCollab.data?.role ?? CollaboratorRole.READER
         ];
      return Object.values(CollaboratorRole)
         .filter((role) => CollaboratorRoleOrder[role] >= explorerLevel)
         .sort((a, b) => CollaboratorRoleOrder[a] - CollaboratorRoleOrder[b]);
   }, [explorerCollab.data, explorerQuery.data, isUserLimitReached]);

   // Mutators
   const bulkUpdateWorkspaceCollabMutator = useBulkUpdateWorkspaceCollabMutator();

   // Page functions
   const save = async () => {
      const payload: WorkspaceCollabBulkPayload = { workspaceId, collaborators: [] };
      for (const collab of currentCollaborators) {
         if (collab.action !== undefined) {
            if (collab.action !== 'invite') {
               payload.collaborators.push({
                  action: collab.action,
                  id: collab.id,
                  role: collab.role,
               });
            } else {
               payload.collaborators.push({
                  action: collab.action,
                  email: collab.name,
                  role: collab.role,
               });
            }
         }
      }
      if (
         newCollaborator &&
         typeof newCollaborator.value === 'number' &&
         isFinite(newCollaborator.value)
      ) {
         payload.collaborators.push({
            id: newCollaborator.value,
            role: newCollaboratorRole,
            action: 'add',
         });
      } else if (newCollaborator && typeof newCollaborator.value === 'string') {
         payload.collaborators.push({
            email: newCollaborator.value,
            role: newCollaboratorRole,
            action: 'invite',
         });
      }
      // reset drop down
      setNewCollaborator(null);
      if (payload.collaborators.length > 0) {
         const response = (await bulkUpdateWorkspaceCollabMutator.mutateAsync(payload)) as
            | Record<string, string>
            | undefined;
         if (response?.error) {
            toast.error(
               <span>
                  There was an error adding this user. Please contact support at{' '}
                  <a href="mailto:support@runql.com">support@runql.com</a>.
               </span>
            );
            refreshLists();
         } else {
            for (const collab of payload.collaborators) {
               if (collab.action === 'invite' && collab.email) {
                  toast.success(`An invite has been sent to ${collab.email}`);
               }
            }
            refreshLists();
         }
      }
      onClose();
   };

   function addCollaborator() {
      if (
         newCollaborator &&
         typeof newCollaborator.value === 'number' &&
         isFinite(newCollaborator.value)
      ) {
         // Add already existing collaborator
         setCurrentCollaborators([
            ...currentCollaborators,
            {
               id: newCollaborator.value,
               name: newCollaborator.label,
               role: newCollaboratorRole,
               action: 'add',
            },
         ]);
      } else if (newCollaborator && newCollaborator.value) {
         // Invite new Collaborator
         setCurrentCollaborators([
            ...currentCollaborators,
            {
               id: new Date().getMilliseconds(), // Setting the ID to something for mapping. Wont be used during save
               name: newCollaborator.label,
               role: newCollaboratorRole,
               action: 'invite',
            },
         ]);
      }
      // reset drop down
      setNewCollaborator(null);
   }

   function handleRoleChange(id: number, newRole: CollaboratorRole): void {
      const currentCollabs = currentCollaborators.map((collab) => {
         if (collab.id === id) {
            collab.role = newRole;
            // only update the action if one hasn't been set
            if (collab.action === undefined) {
               collab.action = 'update';
            }
         }
         return collab;
      });
      setCurrentCollaborators(currentCollabs);
   }

   function handleCollaboratorRemoved(id: number): void {
      const toRemove = currentCollaborators.find((collab) => collab.id === id);
      if (toRemove && (toRemove.action === 'add' || toRemove.action === 'invite')) {
         // if the collaborator to remove was added but not yet saved to the database
         setCurrentCollaborators(currentCollaborators.filter((collab) => collab.id !== id));
      } else if (toRemove && toRemove.action === undefined) {
         // if the collaborator to remove has already been saved to the database
         const currentCollabs = currentCollaborators.map((collab) => {
            if (collab.id === id) {
               collab.action = 'remove';
            }
            return collab;
         });
         setCurrentCollaborators(currentCollabs);
      }
   }

   function handleCancel(): void {
      onClose();
      if (workspaceCollabQuery.data) {
         setCurrentCollaborators(
            workspaceCollabQuery.data.map((collaborator) => ({
               id: collaborator.person?.id ?? -1,
               name: collaborator.person?.firstName
                  ? `${collaborator.person?.firstName} ${collaborator.person.lastName}`
                  : collaborator.person?.email ?? 'UNKNOWN',
               role: collaborator.role ?? CollaboratorRole.READER,
               action: undefined,
            }))
         );
      }
   }

   //Render
   return (
      <Modal centered={true} onHide={handleCancel} show={show} size={'lg'}>
         <Modal.Header className="border-0 mb-0 pb-0" closeButton={true}>
            Workspace Collaborators
         </Modal.Header>
         <Modal.Body>
            <Stack gap={2}>
               {currentCollaborators
                  .filter((val) => val.action !== 'remove' && val.action !== 'invite')
                  .map((val) => {
                     return (
                        <WorkspaceCollaboratorCard
                           collaboratorRemoved={handleCollaboratorRemoved}
                           disabled={
                              !(explorerCollab.data?.role === CollaboratorRole.ADMIN) ||
                              explorerQuery.data?.person.id === val.id
                           }
                           id={val.id}
                           key={val.id}
                           name={val.name}
                           role={val.role}
                           roleChanged={handleRoleChange}
                        />
                     );
                  })}
               {currentCollaborators
                  .filter((val) => val.action === 'invite')
                  .map((val) => {
                     return (
                        <WorkspaceCollaboratorCard
                           collaboratorRemoved={handleCollaboratorRemoved}
                           disabled={
                              !(explorerCollab.data?.role === CollaboratorRole.ADMIN) ||
                              explorerQuery.data?.person.id === val.id
                           }
                           id={val.id}
                           key={val.id}
                           name={val.name}
                           role={val.role}
                           roleChanged={handleRoleChange}
                        />
                     );
                  })}
               <Row>
                  <Col xs={6}>
                     <CreatableSelect
                        className="runql-select"
                        classNamePrefix="runql-select"
                        defaultInputValue={newCollaborator?.label}
                        formatCreateLabel={(val) => <span>Invite {val}</span>}
                        noOptionsMessage={() => 'No matches'}
                        onChange={setNewCollaborator}
                        onCreateOption={(value) => {
                           setNewCollaborator({ label: value, value });
                        }}
                        onMenuOpen={() => setNewCollaborator(null)}
                        options={options}
                        placeholder="Select a user or enter an email"
                        value={newCollaborator}
                     />
                  </Col>
                  <Col xs={4}>
                     <Form.Select
                        className="runql-select"
                        onChange={(event) =>
                           setNewCollaboratorRole(event.currentTarget.value as CollaboratorRole)
                        }
                        value={newCollaboratorRole}
                     >
                        {Object.values(availableRoles).map((role) => (
                           <option key={role} value={role}>
                              {toTitleCase(role)}
                           </option>
                        ))}
                     </Form.Select>
                  </Col>
                  <Col xs={2}>
                     {newCollaborator && (
                        <Button className="w-100" onClick={addCollaborator}>
                           Add
                        </Button>
                     )}
                  </Col>
               </Row>
            </Stack>
            {org?.people && isUserLimitReached && (
               <div
                  className="pt-2"
                  style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}
               >
                  Awesome! Your team is growing! Ready to add more members?{' '}
                  <Button
                     onClick={() => {
                        onClose();
                        setShowUpgradeModal();
                     }}
                     style={{
                        marginBottom: 2,
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        padding: 0,
                        margin: 0,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        fontSize: '12px',
                     }}
                  >
                     Click here
                  </Button>{' '}
                  to upgrade.
               </div>
            )}
         </Modal.Body>
         <Modal.Footer className="border-0 mt-0 pt-0">
            <Button className="" onClick={save}>
               Save
            </Button>
         </Modal.Footer>
      </Modal>
   );
};
