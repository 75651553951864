import { useInjection } from 'inversify-react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Org, Auth, AuthPost } from '../entities';
import { QueryKey } from '../enums';
import { get, handleError, post } from '../utilities';
import { OrgService, OrgStats } from '../services';
import { TYPES } from '../types';
import { SESSION_STORAGE_AUTHN_PERSON_ID_KEY } from '../services/LocalCredentialService';

export const useGetAuthorizedExplorerQuery = ({
   callbacks,
   storePersonId = false,
}: {
   callbacks?: {
      errorCallback?: (error: unknown) => void;
      successCallback?: (data: Auth) => void;
   };
   storePersonId?: boolean;
} = {}) => {
   return useQuery<Auth>(
      [QueryKey.AUTH],
      async () => {
         const result = await get<Auth>('/auth/1');

         const personId = result.person.id;

         if (personId && storePersonId) {
            sessionStorage.setItem(SESSION_STORAGE_AUTHN_PERSON_ID_KEY, personId.toString());
         }

         return result;
      },
      {
         keepPreviousData: true,
         refetchOnWindowFocus: false,
         refetchOnMount: false,
         refetchOnReconnect: false,
         staleTime: Infinity,
         retry: false,
         onSuccess: callbacks?.successCallback,
         onError: callbacks?.errorCallback ?? handleError,
      }
   );
};

export const useAttemptLoginMutator = () => {
   const queryClient = useQueryClient();
   return useMutation({
      mutationFn: async (login: AuthPost) => {
         return post<Auth, AuthPost>('auth', { body: login });
      },
      async onSuccess(data, variables, context) {
         await queryClient.invalidateQueries();
      },
   });
};

export const useOrgService = () => {
   return useInjection<OrgService>(TYPES.orgService);
};

export const useOrg = () => {
   const orgService = useInjection<OrgService>(TYPES.orgService);
   const orgs = useQuery<Org[]>([QueryKey.Org, 'list'], () => orgService.list(), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
   });
   return orgs.data?.[0];
};

export const useOrgStats = (): OrgStats | undefined => {
   const orgService = useInjection<OrgService>(TYPES.orgService);
   return useQuery<OrgStats>([QueryKey.Org, 'stats'], () => orgService.getStats(), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: 60 * 60 * 1000,
   }).data;
};
