import { useContext, useLayoutEffect, useMemo, useState, useCallback } from 'react';
import { FaChartBar, FaChartLine, FaChartPie, FaChartArea } from 'react-icons/fa';
import { TbChartScatter, TbChartTreemap } from 'react-icons/tb';
import { FaRegChartBar } from 'react-icons/fa6';
import { MdBubbleChart } from 'react-icons/md';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import Accessibility from 'highcharts/modules/accessibility';
import { useInjection } from 'inversify-react';
import { Spinner, Stack } from 'react-bootstrap';
import { useMutation } from 'react-query';
import classNames from 'classnames';

import { Button, ErrorBoundary } from './';
import { ThemeContext } from '../components/ThemeContext';
import { ChartConfig, QueryVersion } from '../entities';
import { QueryReturn } from '../interfaces';
import { QueryService } from '../services';
import { TYPES } from '../types';
import { isText, IconEdit, IconTrash } from '../utilities';
import CodeEditor from './UI/CodeEditor';
import { useCurrentQuery } from '../hooks';

// Initialize the accessibility module
Accessibility(Highcharts);
highchartsMore(Highcharts);

const chartTypeToIcon: { [key: string]: JSX.Element } = {
   line: <FaChartLine size={18} />,
   bar: <FaRegChartBar size={18} />,
   pie: <FaChartPie size={18} />,
   area: <FaChartArea size={18} />,
   scatter: <TbChartScatter size={18} />,
   column: <FaChartBar size={18} />,
   treemap: <TbChartTreemap size={18} />,
   bubble: <MdBubbleChart size={18} />,
};
const chartTypes = Object.keys(chartTypeToIcon);

const getRowStructure = (queryReturn?: QueryReturn) => {
   if (!queryReturn?.rows?.length) return undefined;
   else {
      const rowStructure: Record<string, string> = {};
      Object.entries(queryReturn.rows[0]).forEach(
         ([key, value]) => (rowStructure[key] = typeof value)
      );
      return rowStructure;
   }
};

const createKeyMap = (row: { [key: string]: any }) => {
   const map: { [key: string]: string } = {};
   Object.keys(row).forEach((key) => {
      map[key.toLowerCase()] = key;
   });
   return map;
};

export const QueryChart = ({
   allowChartGeneration,
   queryReturn,
   queryVersion,
   setShowUpgradeModal,
}: {
   allowChartGeneration?: boolean;
   queryReturn?: QueryReturn;
   queryVersion: QueryVersion;
   setShowUpgradeModal?: () => void;
}): JSX.Element => {
   const queryVersionId = queryVersion.id;
   const [chartConfig, setChartConfig] = useState<ChartConfig | undefined | null>(
      queryVersion.chartConfig
   );
   const [editChartConfig, setEditChartConfig] = useState<string | undefined>();
   const [editChartError, setEditChartError] = useState<string | undefined>();
   const themeContext = useContext(ThemeContext);
   const isDarkMode = themeContext?.mode === 'dark';
   const { onChange } = useCurrentQuery();
   const queryService = useInjection<QueryService>(TYPES.queryService);

   const createChartMutator = useMutation({
      mutationFn: async ({
         chartType,
         queryVersionId,
         queryReturn,
      }: {
         chartType?: string;
         queryReturn?: QueryReturn;
         queryVersionId: number;
      }) => {
         setChartConfig(undefined);
         return await queryService.createChartConfig(
            queryVersionId,
            chartType,
            getRowStructure(queryReturn)
         );
      },
      onSuccess: (data) => {
         setChartConfig(data);
      },
   });
   const createError =
      createChartMutator.isError && createChartMutator.error instanceof Error
         ? createChartMutator.error.message
         : undefined;

   const chartOptions: Highcharts.Options | undefined = useMemo(() => {
      if (!chartConfig?.dataMapping || !Array.isArray(chartConfig.dataMapping)) {
         return undefined;
      }

      return {
         ...chartConfig.chartConfig,
         credits: { enabled: false },
         plotOptions: {
            ...chartConfig.chartConfig.plotOptions,
            series: {
               ...chartConfig.chartConfig.plotOptions?.series,
               dataLabels: {
                  ...chartConfig.chartConfig.plotOptions?.series?.dataLabels,
                  style: {
                     ...chartConfig.chartConfig.plotOptions?.series?.dataLabels?.style,
                     textOutline: 'none',
                  },
               },
            },
         },
         series: chartConfig.dataMapping.map((mapping) => {
            const mappingX = mapping.x?.toLowerCase() ?? '';
            const mappingY = mapping.y?.toLowerCase() ?? '';
            return {
               name: mapping.seriesName,
               data:
                  Array.isArray(queryReturn?.rows) &&
                  queryReturn?.rows.map((row: { [key: string]: any }) => {
                     const rowKeyMap = createKeyMap(row);
                     const x = rowKeyMap[mappingX] ?? mapping.x;
                     const y = rowKeyMap[mappingY] ?? mapping.y;
                     let xVal = undefined;
                     if (x) {
                        xVal = Number(row[x]);
                        xVal = isNaN(xVal) ? row[x] : xVal;
                     }
                     let yVal = Number(row[y]);
                     yVal = isNaN(yVal) ? row[y] : yVal;
                     return xVal !== undefined ? [xVal, yVal] : [yVal];
                  }),
            };
         }),
      };
   }, [queryReturn?.rows, chartConfig]);

   // Apply changes from the queryVersion.chartConfig to the chartConfig state
   useLayoutEffect(() => {
      setChartConfig((chartConfig) =>
         chartConfig ?? queryVersion.chartConfig
            ? { ...((chartConfig ?? queryVersion.chartConfig) as ChartConfig) }
            : undefined
      );
   }, [queryVersion.chartConfig]);

   const updateChartConfig = useCallback(
      (chartConfig: ChartConfig | null) => {
         setChartConfig(chartConfig);
         onChange?.(
            {
               chartConfig,
            },
            {}
         );
      },
      [onChange]
   );

   const createChart = createChartMutator.mutateAsync;
   const handleCreateChart = useCallback(
      async (chartType: string): Promise<void> => {
         if (!queryVersionId) throw new Error('Query version id not found');
         try {
            const chartConfig = await createChart({
               chartType,
               queryReturn,
               queryVersionId,
            });
            if (chartConfig) {
               updateChartConfig(chartConfig);
            }
         } catch (e) {
            console.error(e);
         }
      },
      [queryVersionId, queryReturn, createChart, updateChartConfig]
   );

   const saveChartConfig = async () => {
      if (!editChartConfig) return;
      try {
         const newChartConfig = JSON.parse(editChartConfig);
         updateChartConfig(newChartConfig);
         setEditChartConfig(undefined);
      } catch (e) {
         if (e instanceof SyntaxError) {
            setEditChartError((e as SyntaxError).message);
         } else {
            setEditChartError('Could not update chart configuration');
         }
      }
   };

   // Handle cases with no chart and no generation possible
   let message: React.ReactNode;
   let showSpinner = false;
   if (!themeContext) {
      showSpinner = true;
   } else if (!allowChartGeneration && chartConfig === undefined) {
      message =
         'No chart saved with this version. Ask someone with edit access to generate a chart and save a new version.';
   } else if (queryReturn === undefined) {
      message = 'Run this query to see or generate a chart.';
   } else if (queryReturn.error) {
      message = isText(queryReturn.error) ? queryReturn.error : queryReturn.error.message;
   } else if (queryReturn.rows?.length === 0) {
      message = 'Your query was successful but returned 0 rows.';
   } else if (createChartMutator.isLoading) {
      showSpinner = true;
      message = 'Generating chart...';
   } else if (createError === 'Plan limit reached') {
      message = (
         <>
            <div style={{ fontSize: '12px', color: '#4c82f7', fontWeight: 'bold' }}>
               You've reached your plan limit for AI charts. Want to create more?
            </div>
            <Button
               onClick={() => {
                  if (setShowUpgradeModal) {
                     setShowUpgradeModal();
                  }
               }}
            >
               Upgrade Now
            </Button>
         </>
      );
   }
   if (message) {
      return (
         <Stack className="h-100 w-100 justify-content-center align-items-center" gap={2}>
            {showSpinner && <Spinner size="sm" />}
            {message}
         </Stack>
      );
   }

   // Chart editing mode
   if (editChartConfig) {
      return (
         <Stack className="h-100">
            <div className="flex-grow-1 overflow-y-auto">
               <CodeEditor onChange={setEditChartConfig} query={editChartConfig} />
            </div>
            <Stack
               className="flex-grow-0 justify-content-end border-top-line pt-2"
               direction="horizontal"
               gap={2}
            >
               {editChartError && <span className="text-danger">{editChartError}</span>}
               <Button
                  colorScheme="secondary"
                  onClick={() => setEditChartConfig(undefined)}
                  size="sm"
               >
                  Cancel
               </Button>
               <Button onClick={() => saveChartConfig()} size="sm">
                  Save
               </Button>
            </Stack>
         </Stack>
      );
   }

   // Display chart and/or chart generation options
   return (
      <Stack
         className={classNames('h-100 w-100 p-3 overflow-auto align-items-center', {
            'justify-content-center': !chartConfig || createError,
         })}
         gap={3}
      >
         {!chartConfig && allowChartGeneration && <h4>Generate a chart</h4>}
         {createError && <div className="text-danger">{createError}</div>}
         {chartConfig && !createError && (
            <div className={isDarkMode ? 'highcharts-dark' : 'highcharts-light'} id="container">
               <ErrorBoundary
                  message="Try changing the chart type or editing the chart configuration."
                  title="Could not render chart"
               >
                  <HighchartsReact highcharts={Highcharts} options={chartOptions} />
               </ErrorBoundary>
            </div>
         )}
         {allowChartGeneration && (
            <Stack className="justify-content-center" direction="horizontal" gap={2}>
               {chartTypes.map((chartType) => (
                  <Button
                     colorScheme={
                        chartConfig?.chartConfig.chart.type === chartType ? 'primary' : 'secondary'
                     }
                     disabled={createChartMutator.isLoading}
                     key={chartType}
                     onClick={() => handleCreateChart(chartType)}
                     title={`Generate ${chartType} chart`}
                  >
                     {chartTypeToIcon[chartType] ??
                        chartType.charAt(0).toUpperCase() + chartType.slice(1)}
                  </Button>
               ))}
            </Stack>
         )}
         {allowChartGeneration && chartConfig && (
            <Stack className="justify-content-center" direction="horizontal" gap={2}>
               <Button
                  colorScheme="secondary"
                  disabled={!chartConfig || createChartMutator.isLoading}
                  onClick={() => {
                     updateChartConfig(null);
                  }}
                  title="Remove chart"
               >
                  <IconTrash size={18} />
               </Button>
               <Button
                  colorScheme="secondary"
                  disabled={!chartConfig || createChartMutator.isLoading}
                  onClick={() => {
                     setEditChartError(undefined);
                     setEditChartConfig(JSON.stringify(chartConfig, null, 2));
                  }}
                  title="Edit chart configuration"
               >
                  <IconEdit size={18} />
               </Button>
            </Stack>
         )}
      </Stack>
   );
};
