import { Stack } from 'react-bootstrap';
import classNames from 'classnames';

import { PersonUtilities, QueryVersion } from '../entities';
import { getShortDateTimeString } from '../utilities/formatters';
import Button from './Button';

export const AnswerCard = ({
   onClick,
   queryVersion,
   selected,
}: {
   onClick?: (q: QueryVersion, force?: boolean) => void;
   queryVersion: QueryVersion;
   selected?: boolean;
}) => {
   return (
      <div
         className={classNames(
            'card py-2 px-3 bg-secondary',
            { clickable: !!onClick },
            { 'border-selected': selected }
         )}
         onClick={() => onClick?.(queryVersion, selected ?? false)}
      >
         <Stack className="justify-content-between" gap={2}>
            <Stack gap={2}>
               <Stack className="justify-content-between" direction="horizontal" gap={2}>
                  <div className="fw-bold fs-16">{queryVersion.title}</div>
                  <Button>{selected ? 'Refresh Data' : 'View Data'}</Button>
               </Stack>
               <div>{queryVersion.question}</div>
               <div>{queryVersion.description}</div>
            </Stack>
            <Stack
               className="query-card-footer fs-9p align-items-end w-100 text-muted"
               direction="horizontal"
               gap={1}
            >
               <div>{getShortDateTimeString(queryVersion.modified)}</div>
               <div className="fw-500 potential-badge">
                  –{' '}
                  {PersonUtilities.getFullName(
                     queryVersion.createdByPerson ?? {
                        firstName: 'Runa',
                     }
                  )}
               </div>
            </Stack>
         </Stack>
      </div>
   );
};

export default AnswerCard;
