import { forwardRef, memo, useState, useMemo } from 'react';
import classNames from 'classnames';
import { Badge, Button, OverlayTrigger, Popover, Stack } from 'react-bootstrap';
import { BiCheck, BiCopy, BiExpandVertical, BiCollapseVertical } from 'react-icons/bi';
import { useParams, useSearchParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';

import { extractTablesFromSQL } from '@runql/util';

import {
   PersonUtilities,
   QueryLog,
   QueryState,
   QueryVersion,
   QueryVersionLog,
   createQueryVersionFromLogs,
   useOpenQuery,
} from '../entities';
import { QUERY_SOURCE } from '../enums';
import { useExtension, useGetQueryQuery } from '../hooks';
import { StatsService } from '../services';
import { TYPES } from '../types';
import { copyToClipboard } from '../utilities/clipboard';
import { getShortDateTimeString } from '../utilities/formatters';
import CodeViewer from './UI/CodeViewer';
import DiffViewer from './UI/DiffViewer';
import { IconPrivate, IconShared, IconOpen, IconUse } from '../utilities/icons';
import { QueryVersionBadge } from '.';

export type QueryDisplayOptions = {
   basedOnVersion?: boolean;
   // collapses the code view and also hides the description when in the starting block.
   collapse?: boolean;
   defaultAction?: boolean;
   fullWidth?: boolean;
   showDescription?: boolean;
   showDetails?: boolean;
   showOriginalCreator?: boolean;
   showQuery?: boolean;
   showTags?: boolean;
   showTitle?: boolean;
};

export const queryTags = (queryVersion: QueryVersion) => {
   return (
      queryVersion.steps?.flatMap((step) =>
         step.queryText ? extractTablesFromSQL(step.queryText, step.schemaName ?? undefined) : []
      ) ?? []
   );
};

export const QueryHeader = ({
   queryVersion,
   diffAction,
   diffVersion,
   setShared,
   basedOnVersion,
   showDescription,
   showDetails,
   showOriginalCreator,
   showTags,
   showTitle,
   collapsed,
   footerExtra,
   onFooterClick,
}: QueryDisplayOptions & {
   collapsed?: boolean;
   diffAction?: 'added' | 'removed';
   diffVersion?: QueryVersion;
   footerExtra?: React.ReactNode;
   onFooterClick?: () => void;
   queryVersion: QueryVersion;
   setShared?: (queryVersion: QueryVersion, shared: boolean) => void;
}): JSX.Element => {
   showTitle ??= !!queryVersion.title;
   showDescription ??= false;
   const shared = queryVersion?.query?.state === QueryState.SHARED;
   const runtime = (queryVersion as QueryVersionLog).runtime;
   const tags = useMemo(() => queryTags(queryVersion), [queryVersion]);
   if (tags.length === 0) showTags = false;
   showTags ??= true;
   showOriginalCreator ??= true;

   const approved = queryVersion.query?.approvedVersionId === queryVersion.id;
   if (!showTitle && !queryVersion.version && !showDescription && !showTags && !showDetails) {
      return <></>;
   }
   return (
      <>
         <Stack className="query-card-header p-2 d-flex w-100" gap={1}>
            {(showTitle || queryVersion.version) && (
               <Stack
                  className="w-100 justify-content-between align-items-start"
                  direction="horizontal"
                  gap={1}
               >
                  <Stack direction="horizontal" gap={1}>
                     <div style={{ verticalAlign: 'middle' }}>
                        {basedOnVersion && <i>Based on&nbsp;</i>}
                        {showTitle && queryVersion?.title ? (
                           <span
                              className={classNames('query-title truncate-lines', {
                                 [`query-diff-${diffAction}`]:
                                    diffVersion && diffVersion.title !== queryVersion.title,
                              })}
                           >
                              {queryVersion.title}
                           </span>
                        ) : (
                           ''
                        )}{' '}
                     </div>
                     <QueryVersionBadge approved={approved} queryVersion={queryVersion} />
                     {setShared ? (
                        <Button
                           key="share"
                           onClick={
                              queryVersion?.query
                                 ? () => setShared(queryVersion, !shared)
                                 : undefined
                           }
                           size="sm"
                           title={
                              shared
                                 ? 'Make the query private to you'
                                 : 'Make query visible to everyone in the workspace'
                           }
                           variant="secondary"
                        >
                           <Stack direction="horizontal" gap={1}>
                              <div style={{ width: '12px' }}>
                                 {shared ? (
                                    <IconShared className="opacity-75" size="12" />
                                 ) : (
                                    <IconPrivate className="opacity-75" size="12" />
                                 )}
                              </div>
                              <div>{shared ? 'Shared' : 'Share'}</div>
                           </Stack>
                        </Button>
                     ) : queryVersion.version || queryVersion.parent?.version ? (
                        <div
                           className="flex-shrink-0"
                           style={{ verticalAlign: 'middle', lineHeight: 1 }}
                        >
                           {shared ? (
                              <IconShared className="opacity-75 flex-shrink-0" size="12" />
                           ) : (
                              <IconPrivate className="opacity-75 flex-shrink-0" size="12" />
                           )}
                        </div>
                     ) : (
                        <></>
                     )}
                  </Stack>
               </Stack>
            )}
            {showTags && (
               <Stack className="truncate-lines" direction="horizontal" gap={1}>
                  {tags.map((tag, i) => (
                     <Badge bg="tag" key={i} pill>
                        {tag}
                     </Badge>
                  ))}
               </Stack>
            )}
            {showDescription && queryVersion?.description && (
               <div
                  className={classNames('fs-11p fw-normal', {
                     'truncate-lines truncate-lines-2': collapsed,
                     [`query-diff-${diffAction}`]:
                        diffVersion && diffVersion.description !== queryVersion.description,
                  })}
               >
                  {queryVersion.description}
               </div>
            )}
            {showDescription && queryVersion?.question && (
               <div
                  className={classNames('fs-11p fw-normal', {
                     'truncate-lines': collapsed,
                     [`query-diff-${diffAction}`]:
                        diffVersion && diffVersion.question !== queryVersion.question,
                  })}
               >
                  <i>Q:</i> {queryVersion.question}
               </div>
            )}
         </Stack>
         {showDetails !== false && (queryVersion?.modified || queryVersion?.createdByPerson) && (
            <Stack
               className="query-card-footer p-2 fs-9p text-muted justify-content-between align-items-center"
               direction="horizontal"
               gap={2}
               onClick={
                  onFooterClick
                     ? (e) => {
                          e.stopPropagation();
                          onFooterClick();
                       }
                     : undefined
               }
            >
               <Stack className="truncate-lines" direction="horizontal" gap={1}>
                  {queryVersion?.modified && (
                     <>
                        {getShortDateTimeString(queryVersion?.modified)}
                        {runtime && runtime > 0 && <>&nbsp;({runtime}ms)</>}
                     </>
                  )}
                  {queryVersion?.createdByPerson && (
                     <>
                        {' – '}
                        {PersonUtilities.getFullName(
                           queryVersion?.aiSuggestion
                              ? {
                                   firstName: 'Runa',
                                }
                              : showOriginalCreator
                              ? queryVersion?.query?.createdByPerson ?? queryVersion.createdByPerson
                              : queryVersion.createdByPerson
                        )}
                     </>
                  )}
                  {showOriginalCreator &&
                     queryVersion?.createdByPerson &&
                     queryVersion?.query?.createdByPersonId &&
                     queryVersion.createdByPersonId !== queryVersion.query.createdByPersonId && (
                        <div className="fw-500 potential-badge">
                           (modified by {PersonUtilities.getFullName(queryVersion.createdByPerson)})
                        </div>
                     )}
               </Stack>
               <Stack className="justify-content-end align-items-center" direction="horizontal">
                  {footerExtra}
               </Stack>
            </Stack>
         )}
      </>
   );
};

export const QueryWidget = memo(
   forwardRef(
      (
         {
            children,
            diffSide,
            diffVersion,
            extraActions,
            highlight,
            action,
            queryVersion,
            source,
            workspaceId,
            popoverPlacement,
            noHover,
            dataChatThreadId, // open the query and associate the explore tab with this chat
            onClick,
            ...displayOptions
         }: QueryDisplayOptions & {
            action?: 'open' | 'use' | 'copy' | 'none';
            children?: React.ReactNode;
            dataChatThreadId?: number;
            // Whether queryVersion should be shown as the new or old query.
            // If not provided, it will be determined by the modified date.
            diffSide?: 'new' | 'old';
            diffVersion?: QueryVersion;
            extraActions?: React.ReactNode[];
            highlight?: boolean;
            noHover?: boolean;
            onClick?: () => Promise<boolean>;
            popoverPlacement?: 'right' | 'left' | 'auto-start';
            queryVersion: QueryVersion;
            source: QUERY_SOURCE;
            workspaceId?: number;
         },
         ref: React.ForwardedRef<HTMLDivElement>
      ): JSX.Element => {
         const [searchParams] = useSearchParams();
         const tabId = searchParams.has('t') ? Number(searchParams.get('t')) : undefined;
         const params = useParams();
         const extension = useExtension();
         const statsService = useInjection<StatsService>(TYPES.statsService);
         const maxLines = 3;
         const collapse =
            (displayOptions.collapse ?? false) &&
            queryVersion.steps?.[0].queryText &&
            (queryVersion.steps.length > 1 ||
               queryVersion.steps[0].queryText.trim().split('\n').length > maxLines);
         const [collapsed, setCollapsed] = useState(collapse);
         const defaultAction = displayOptions.defaultAction ?? action !== 'none';

         noHover = displayOptions.showDescription || noHover;
         action ??= extension ? 'copy' : 'open';
         popoverPlacement ??= extension ? 'left' : 'auto-start';
         if (action === 'use' && !tabId) action = 'open';
         if (diffVersion && !diffSide) {
            diffSide =
               diffVersion.modified &&
               queryVersion.modified &&
               diffVersion.modified < queryVersion.modified
                  ? 'new'
                  : 'old';
         }
         workspaceId ??= params.workspaceId ? Number(params.workspaceId) : undefined;

         const openQuery = useOpenQuery(extension ? { handler: extension.openTab } : {});
         const [showCopySuccess, setShowCopySuccess] = useState(false);
         const onOpen = () => {
            openQuery({
               newTab: true,
               fork: source === 'log',
               queryVersion,
               source,
               workspaceId,
               dataChatThreadId,
            });
         };

         if (extension) {
            extraActions = [
               <Button onClick={onOpen} size="sm" variant="secondary">
                  <IconOpen size={10} />
                  &nbsp;Open
               </Button>,
            ];
         }

         let label, icon;
         let onAction: () => void = () => {};
         switch (action) {
            case 'open':
               label = 'Open';
               onAction = onOpen;
               icon = <IconOpen size={10} />;
               break;
            case 'use':
               label = 'Use';
               onAction = () => {
                  openQuery({
                     queryVersion,
                     source,
                     workspaceId,
                  });
               };
               icon = <IconUse size={10} />;
               break;
         }

         const copyAction = () => {
            const query = queryVersion?.steps?.flatMap((s) => s.queryText ?? null).join(';\n');
            copyToClipboard(query);
            setShowCopySuccess(true);
            setTimeout(() => {
               setShowCopySuccess(false);
            }, 2000);

            statsService.addUseQueryClick({
               destination: extension ? 'extension' : 'app',
               query: queryVersion.steps?.map((s) => s.queryText)?.join(';\n') ?? '',
               workspaceId,
               queryVersionId: queryVersion.id,
               source,
            });
         };

         return (
            <div
               className={classNames('query-card-container', {
                  'query-card-2col':
                     !displayOptions.fullWidth &&
                     !diffVersion &&
                     displayOptions.showQuery !== false,
               })}
            >
               <OverlayTrigger
                  overlay={
                     queryVersion.description && !noHover ? (
                        <Popover>
                           <div className="fs-11p fw-normal p-2">{queryVersion.description}</div>
                        </Popover>
                     ) : (
                        <></>
                     )
                  }
                  placement={popoverPlacement}
               >
                  <div
                     className={classNames('border-0 query-card card', {
                        'query-card-highlight': highlight,
                        'query-card-hover': defaultAction,
                        'query-diff-card': !!diffVersion,
                     })}
                     onClick={async () => {
                        if ((await onClick?.()) === false) return;
                        if (!defaultAction) return;
                        onAction();
                     }}
                     ref={ref}
                  >
                     <Stack className="query-card-inner">
                        <div className="query-card-content">
                           <QueryHeader
                              collapsed={!!collapsed}
                              diffAction={diffSide === 'old' ? 'removed' : 'added'}
                              diffVersion={
                                 diffVersion && diffSide === 'old' ? diffVersion : queryVersion
                              }
                              footerExtra={
                                 collapse ? (
                                    <Stack
                                       className="align-items-center flex-shrink-0"
                                       direction="horizontal"
                                       gap={1}
                                    >
                                       {collapsed &&
                                          queryVersion.steps &&
                                          queryVersion.steps.length > 1 && (
                                             <Badge bg="tag" className="fs-9p" pill>
                                                +{queryVersion.steps.length - 1} step
                                                {queryVersion.steps.length > 2 ? 's' : ''}
                                             </Badge>
                                          )}
                                       {collapsed ? (
                                          <BiExpandVertical
                                             className="code-view-icon-overlay flex-shrink-0"
                                             size={14}
                                          />
                                       ) : (
                                          <BiCollapseVertical
                                             className="code-view-icon-overlay flex-shrink-0"
                                             size={14}
                                          />
                                       )}
                                    </Stack>
                                 ) : undefined
                              }
                              onFooterClick={() => {
                                 setCollapsed(!collapsed);
                              }}
                              queryVersion={
                                 diffVersion && diffSide === 'new' ? diffVersion : queryVersion
                              }
                              {...displayOptions}
                           />
                           {diffVersion && (
                              <QueryHeader
                                 collapsed={!!collapsed}
                                 diffAction={diffSide === 'new' ? 'removed' : 'added'}
                                 diffVersion={diffSide === 'new' ? diffVersion : queryVersion}
                                 queryVersion={diffSide === 'new' ? queryVersion : diffVersion}
                                 {...displayOptions}
                              />
                           )}
                           {displayOptions.showQuery !== false && (
                              <Stack className="query-card-query p-2">
                                 {queryVersion.steps
                                    ?.slice(0, collapsed ? 1 : undefined)
                                    .map((step, index) => (
                                       <div
                                          className="queryFontSmall cm-editor"
                                          key={step.id ?? index}
                                       >
                                          <div
                                             className="card border-0 queryCardCode"
                                             style={{ position: 'relative' }}
                                          >
                                             {diffVersion &&
                                                (diffSide === 'new' ? (
                                                   <DiffViewer
                                                      newCode={step.queryText ?? ''}
                                                      oldCode={
                                                         diffVersion.steps[index]?.queryText ?? ''
                                                      }
                                                   />
                                                ) : (
                                                   <DiffViewer
                                                      newCode={
                                                         diffVersion.steps[index]?.queryText ?? ''
                                                      }
                                                      oldCode={step.queryText ?? ''}
                                                   />
                                                ))}
                                             {!diffVersion && (
                                                <CodeViewer
                                                   comments={step.comments}
                                                   dialect={step.dataConnection?.dbms}
                                                   maxLines={collapsed ? maxLines : undefined}
                                                   query={step.queryText}
                                                />
                                             )}
                                          </div>
                                       </div>
                                    ))}
                              </Stack>
                           )}
                        </div>

                        {extraActions && (
                           <Stack
                              className="justify-content-end pe-2 pb-2"
                              direction="horizontal"
                              gap={2}
                              onClick={(e) => e.stopPropagation()}
                           >
                              {extraActions}
                           </Stack>
                        )}
                     </Stack>

                     {!diffVersion && displayOptions.showQuery !== false && (
                        <div className={'query-card-top-right d-flex gap-2 p-2'}>
                           {action !== 'none' && (
                              <Button
                                 className="hover-button"
                                 onClick={(e) => {
                                    e.preventDefault();
                                    onAction();
                                 }}
                                 size="sm"
                                 variant="secondary"
                              >
                                 {collapsed ? '' : <>{label}&nbsp;</>}
                                 {icon}
                              </Button>
                           )}
                           {!collapse && (
                              <Button
                                 className={`hover-button ${showCopySuccess ? 'btn-success' : ''}`}
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    copyAction();
                                 }}
                                 size="sm"
                                 title="Copy"
                                 variant="secondary"
                              >
                                 {showCopySuccess ? (
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                       <BiCheck />
                                    </div>
                                 ) : (
                                    <div style={{ display: 'flex', gap: '0.25rem' }}>
                                       <BiCopy />
                                    </div>
                                 )}
                              </Button>
                           )}
                        </div>
                     )}

                     {children}
                  </div>
               </OverlayTrigger>
            </div>
         );
      }
   )
);

export const QueryLogWidget = memo(
   ({
      queryLogs,
      extraActions,
      diffLogs,
      ...displayOptions
   }: QueryDisplayOptions & {
      currentVersion?: QueryVersion;
      diffLogs?: QueryLog[];
      extraActions?: React.ReactNode[];
      previousLogs?: QueryLog[];
      queryLogs?: QueryLog[];
   }): JSX.Element => {
      const queryVersion = queryLogs ? createQueryVersionFromLogs(queryLogs) : undefined;
      const diffVersion = diffLogs ? createQueryVersionFromLogs(diffLogs) : undefined;
      if (!queryVersion) return <></>;
      return (
         <QueryWidget
            diffVersion={diffVersion}
            extraActions={extraActions}
            fullWidth
            queryVersion={queryVersion}
            source="log"
            workspaceId={queryLogs?.[0]?.workspaceId}
            {...displayOptions}
         />
      );
   }
);

export const QueryGetter = ({
   queryVersion: partialQueryVersion,
   children,
}: {
   children: (queryVersion: QueryVersion) => React.ReactNode;
   queryVersion: Partial<QueryVersion>;
}) => {
   if ((partialQueryVersion as QueryVersionLog).logId) throw new Error('Cannot get log');
   const queryVersionQuery = useGetQueryQuery({ id: partialQueryVersion.id });
   const queryVersion = queryVersionQuery.data;

   if (!queryVersion) return null;
   return children(queryVersion);
};

export default QueryWidget;
