import { RunaMode, SchemaItemType } from '@runql/util';
import { useCallback, useMemo, useRef, useState } from 'react';
import { OverlayTrigger, Stack, Tab, Tooltip } from 'react-bootstrap';
import { BiDownload } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { MdOutlineEditOff } from 'react-icons/md';

import { DBMS } from '../enums';
import {
   useGetDataConnectionSchema,
   useExplorer,
   useOptionalExploreTab,
   useRunSystemQuery,
   fetchTableDataQueryKey,
   useExplorerWorkspaceRole,
   usePerson,
} from '../hooks';
import { useAskRunaContext } from '../hooks/askHooks';
import { useQueryPanelContext } from '../hooks/QueryPanelContext';
import { QueryReturn } from '../interfaces';
import {
   buildChangeQueries,
   ClientDataChange,
   convertQueryReturnToSigmaGraph,
   handleError,
   IconGraph,
   IconTable,
   isText,
   SigmaGraph,
} from '../utilities';
import { QueryResultsGraph } from './QueryResultsGraph';
import { ResultTable, ResultTableRef, RqlAction } from './UI';
import { DataConnection, StepType } from '../entities';
import { PersonRole } from '../enums';
import { Button } from '../components';
import { ConfirmChangesModal } from '../pages/Workspace/Explore/Table/ConfirmChangesModal';
import { getReadOnly } from '../pages/Workspace/Explore/Table';

const JOIN_REGEX = /\bJOIN\b/i;
const SUBQUERY_REGEX = /\bSELECT\b.*?\bFROM\b\s*\(/i;

export const QueryResults = ({
   dataConnection,
   onRefreshQueryData,
   queryReturn,
   schemaName,
   readOnly,
   simplified,
}: {
   dataConnection?: DataConnection;
   onRefreshQueryData?: () => Promise<void>;
   queryReturn: QueryReturn | undefined;
   readOnly?: boolean;
   schemaName?: string;
   simplified?: boolean;
}): JSX.Element => {
   const person = usePerson();
   const isBusinessUser = person?.role === PersonRole.ORG_BUSINESS_USER;
   const [activeTab, setActiveTab] = useState<string | null>('table');
   const { askRuna, isLoading } = useAskRunaContext();
   const { setQueryTab } = useQueryPanelContext();
   const { run, isRunning } = useRunSystemQuery();
   const queryClient = useQueryClient();
   const resultTableRef = useRef<ResultTableRef>(null);
   const exploreTab = useOptionalExploreTab();
   const explorer = useExplorer();
   const [showConfirmModal, setShowConfirmModal] = useState(false);
   const [changeCount, setChangeCount] = useState(0);
   const [changeQueries, setChangeQueries] = useState<string[]>([]);
   const [pendingUpdate, setPendingUpdate] = useState(false);
   const role = useExplorerWorkspaceRole(exploreTab?.workspaceId);
   readOnly ??= getReadOnly(dataConnection?.dbms, dataConnection?.connectionAccessType, role);

   const queryDataConnectionId = useMemo(() => dataConnection?.id || -1, [dataConnection?.id]);
   const querySchemaName = useMemo(() => schemaName ?? '', [schemaName]);
   const queryTableName = useMemo(() => {
      const regex = /FROM\s+((["'`[\]]?[\w]+["'`[\]]?)(\.\s*["'`[\]]?[\w]+["'`[\]]?)?)/i;
      if (queryReturn?.query) {
         const match = queryReturn.query.match(regex);
         if (match !== null) {
            const schema = match[3] ? match[2].replace(/["'`[\]]/g, '') : null;

            const tableName = match[3]
               ? match[3]
                    .replace(/["'`[]/g, '')
                    .replace(/\]/g, '')
                    .trim()
               : match[2]
                    .replace(/["'`[]/g, '')
                    .replace(/\]/g, '')
                    .trim();
            const finalTableName = schema ? tableName.replace('.', '') : tableName;
            return finalTableName;
         } else {
            return '';
         }
      } else {
         return '';
      }
   }, [queryReturn?.query]);
   const queryDBMS = useMemo(() => dataConnection?.dbms ?? DBMS.MySQL, [dataConnection?.dbms]);
   const queryEditingSupportDetails = useMemo<{
      notSupportedReason?: string;
      supported: boolean;
   }>(() => {
      if (queryReturn?.query) {
         if (readOnly) return { supported: false, notSupportedReason: 'Table is read-only.' };
         else if (queryTableName === '')
            return { supported: false, notSupportedReason: 'Could not extract table name.' };
         else if (
            dataConnection === undefined ||
            queryDataConnectionId === undefined ||
            queryDataConnectionId === -1
         )
            return { supported: false, notSupportedReason: 'Data connection is not valid.' };
         else if (JOIN_REGEX.test(queryReturn?.query || ''))
            return {
               supported: false,
               notSupportedReason: 'Editing not supported for queries with JOIN.',
            };
         else if (SUBQUERY_REGEX.test(queryReturn?.query || ''))
            return {
               supported: false,
               notSupportedReason: 'Editing not supported for queries with subqueries.',
            };
         else return { supported: true };
      } else {
         return { supported: false, notSupportedReason: 'No query ran.' };
      }
   }, [readOnly, queryTableName, dataConnection, queryDataConnectionId, queryReturn?.query]);
   const queryResultEditingSupported = useMemo(
      () => queryEditingSupportDetails.supported,
      [queryEditingSupportDetails.supported]
   );
   const cannotEditReason = useMemo<string | undefined>(
      () => queryEditingSupportDetails.notSupportedReason,
      [queryEditingSupportDetails.notSupportedReason]
   );
   const dataConnectionSchema = useGetDataConnectionSchema(
      readOnly ? undefined : queryDataConnectionId
   );
   const tableColumnCount = useMemo(() => queryReturn?.fields?.length ?? 0, [queryReturn?.fields]);

   const columnSchemaCache = useMemo(() => {
      return (
         dataConnectionSchema?.data?.filter(
            (entry) =>
               entry.tableName === queryTableName &&
               entry.schemaName === querySchemaName &&
               entry.type === SchemaItemType.COLUMN
         ) || []
      );
   }, [dataConnectionSchema?.data, queryTableName, querySchemaName]);

   // Only allow columns in the database schema to be editable (no special ones like COUNT(*), etc.)
   const editableColumns = useMemo(() => {
      return columnSchemaCache.map((entry) => entry.columnName);
   }, [columnSchemaCache]);

   const primaryKeyColumns = useMemo(() => {
      return columnSchemaCache
         .filter((entry) => entry.columnIsPrimaryKey)
         .map((entry) => entry.columnName);
   }, [columnSchemaCache]);

   const handleCellEdit = useCallback(
      (event: { valueChanged: boolean }) => {
         if (event.valueChanged) setPendingUpdate(true);
      },
      [setPendingUpdate]
   );

   async function handleConfirmSave(): Promise<void> {
      if (changeQueries.length === 0) return;
      if (
         dataConnection === undefined ||
         queryDataConnectionId === undefined ||
         queryDataConnectionId === -1 ||
         exploreTab === undefined
      ) {
         handleError('No data connection found');
         return;
      }
      try {
         await run({
            query: changeQueries.join('\n'),
            dataConnection,
            exploreTabId: exploreTab.id,
            workspaceId: exploreTab.workspaceId,
         });
         await queryClient.invalidateQueries(
            fetchTableDataQueryKey({
               dataConnectionId: queryDataConnectionId,
               schemaName: querySchemaName,
               tableName: queryTableName,
            })
         );
         setShowConfirmModal(false);
         setPendingUpdate(false);
         resultTableRef.current?.clearEdits(false);
         await onRefreshQueryData?.();
      } catch (error) {
         handleError(error);
         return;
      }
      toast.success('Changes saved successfully');
   }

   function handleSaveChanges(): void {
      const result = resultTableRef.current?.getUpdatedRows();
      if (result) {
         const { updates } = result;
         setChangeCount(updates.length);
         const clientChanges: ClientDataChange[] = [];
         updates.forEach((update) => {
            let { rqlAction, ...data } = update as { rqlAction: RqlAction };
            const keyValues: Record<string, any> = {};

            primaryKeyColumns.forEach((key) => {
               if (rqlAction.key?.[key] !== undefined) {
                  keyValues[key] = rqlAction.key[key];
               } else {
                  keyValues[key] = update[key];
               }
            });

            if (!rqlAction) {
               handleError('Error creating update');
               return;
            }

            if (rqlAction.type === 'update') {
               data =
                  rqlAction.columns?.reduce((acc: Record<string, any>, column: string) => {
                     if (column in update) {
                        acc[column] = update[column];
                     }
                     return acc;
                  }, {}) ?? {};
            }

            clientChanges.push({
               data,
               key: keyValues,
               type: rqlAction.type,
               table: queryTableName ?? '',
               schema: querySchemaName ?? '',
            });
         });
         const queries = buildChangeQueries(clientChanges, columnSchemaCache ?? [], queryDBMS);
         setChangeQueries(queries);
         setShowConfirmModal(true);
      } else {
         toast.warn('No changes found');
      }
   }

   const handleCancel = useCallback(() => {
      setPendingUpdate(false);
      resultTableRef.current?.clearEdits();
   }, []);

   const graphData: SigmaGraph | undefined = useMemo(() => {
      if (queryReturn && queryReturn.dbms === DBMS.Neo4j) {
         const data = convertQueryReturnToSigmaGraph(queryReturn);
         if (data.nodes.length === 0) {
            return undefined;
         }
         setActiveTab('graph');
         return data;
      }
      return undefined;
   }, [queryReturn]);

   if (queryReturn === undefined) {
      return <></>;
   } else if (queryReturn.error !== undefined) {
      let errorMessage: string = '';
      if (isText(queryReturn.error)) {
         errorMessage = queryReturn.error;
      } else {
         errorMessage = queryReturn.error.message;
      }

      return (
         <div className="m-3">
            {isBusinessUser && (
               <div className="text-danger">There was an error getting the data.</div>
            )}
            {errorMessage && (
               <>
                  <span className={isBusinessUser ? '' : 'text-danger'}>
                     {errorMessage}
                     <br />
                  </span>
                  {!isBusinessUser && (
                     <div>
                        <button
                           className="btn btn-xs btn-primary "
                           disabled={isLoading}
                           onClick={() => {
                              askRuna({
                                 clearConversation: true,
                                 prompt: errorMessage,
                                 mode: RunaMode.FixError,
                                 steps: [
                                    {
                                       query: queryReturn.query,
                                       dataConnectionId: dataConnection?.id,
                                       defaultSchema: schemaName,
                                       stepType: dataConnection
                                          ? StepType.DATA_CONNECTION
                                          : schemaName
                                          ? StepType.FEDERATED
                                          : StepType.PYTHON,
                                    },
                                 ],
                              });
                              setQueryTab('runa');
                           }}
                        >
                           Fix my query
                        </button>
                     </div>
                  )}
               </>
            )}
         </div>
      );
   } else if (!queryReturn.rows?.length) {
      if (queryReturn.affectedRows > 0) {
         return (
            <div className="card border-1 m-2 p-2">
               Your query affected {queryReturn.affectedRows} rows, and took {queryReturn.runtime}{' '}
               ms
            </div>
         );
      }
      return (
         <div className="card border-1 m-2 p-2">Your query was successful but returned 0 rows</div>
      );
   }
   /* eslint-disable jsx-a11y/anchor-is-valid */
   return (
      <div className="d-flex flex-column h-100 resultset-row">
         <ConfirmChangesModal
            changeCount={changeCount}
            onClose={() => {
               setShowConfirmModal(false);
            }}
            onConfirm={handleConfirmSave}
            query={changeQueries}
            running={isRunning}
            show={showConfirmModal}
         />
         {!simplified && (
            <div className="mb-2 mt-1 mx-1">
               <div className="row d-flex align-items-center">
                  <div className="col-2 d-flex justify-content-end w-100">
                     {graphData ? (
                        <>
                           <button
                              className="btn btn-link btn-sm p-0 fs-10p"
                              onClick={() => {
                                 setActiveTab('graph');
                              }}
                           >
                              <IconGraph size={10} style={{ maxWidth: 10 }} />
                              &nbsp;Graph
                           </button>
                           &nbsp;
                           <button
                              className="btn btn-link btn-sm p-0 fs-10p ms-1"
                              onClick={() => {
                                 setActiveTab('table');
                              }}
                           >
                              <IconTable className="" size={10} style={{ maxWidth: 10 }} />
                              &nbsp;Table
                           </button>
                           &nbsp;
                        </>
                     ) : (
                        <>
                           <button
                              className="btn btn-link btn-sm p-0 fs-10p ms-1"
                              onClick={() => {
                                 setActiveTab('table');
                              }}
                           >
                              <IconTable className="" size={10} style={{ maxWidth: 10 }} />
                              &nbsp;Table
                           </button>
                        </>
                     )}
                     {typeof queryReturn.rows === 'string' && <span className="mt-3">&nbsp;</span>}
                     {explorer && (explorer?.csvDownload || explorer?.csvDownload === undefined) ? (
                        <button
                           className="btn btn-link btn-sm p-0 fs-10p ms-1 plausible-event-name--csvDownload"
                           onClick={() => resultTableRef.current?.downloadCSV()}
                        >
                           <BiDownload />
                           &nbsp;CSV
                        </button>
                     ) : (
                        <></>
                     )}
                     <Stack
                        className="container justify-content-end"
                        direction="horizontal"
                        gap={1}
                     >
                        {queryResultEditingSupported ? (
                           <>
                              {editableColumns.length === tableColumnCount && (
                                 <Button
                                    className="btn btn-sm btn-secondary"
                                    colorScheme="secondary"
                                    message="Add"
                                    onClick={() => {
                                       setPendingUpdate(true);
                                       resultTableRef.current?.addNewRow();
                                    }}
                                    size="sm"
                                 />
                              )}
                              <Button
                                 colorScheme="secondary"
                                 message="Delete"
                                 onClick={() => {
                                    if (resultTableRef.current?.markRowsAsDeleted())
                                       setPendingUpdate(true);
                                 }}
                                 size="sm"
                              />
                              <Button
                                 colorScheme="secondary"
                                 disabled={!pendingUpdate}
                                 message="Cancel"
                                 onClick={handleCancel}
                                 size="sm"
                              />
                              <Button
                                 disabled={!pendingUpdate}
                                 message="Apply Changes"
                                 onClick={handleSaveChanges}
                                 size="sm"
                              />
                           </>
                        ) : (
                           cannotEditReason !== undefined && (
                              <OverlayTrigger
                                 delay={{ show: 500, hide: 0 }}
                                 key={'resultNoEdit'}
                                 overlay={<Tooltip>{cannotEditReason}</Tooltip>}
                                 placement="left"
                              >
                                 <div>
                                    <MdOutlineEditOff />
                                 </div>
                              </OverlayTrigger>
                           )
                        )}
                     </Stack>
                  </div>
               </div>
            </div>
         )}
         {typeof queryReturn.rows === 'string' ? (
            <div className="card border-1 mt-2 mb-4 p-4 flex-grow">
               <div className="fs-14p" style={{ whiteSpace: 'pre-wrap' }}>
                  {queryReturn.rows}
               </div>
            </div>
         ) : (
            <Tab.Container activeKey={activeTab ?? 'table'} onSelect={(k) => setActiveTab(k)}>
               <Tab.Content className="flex-grow-1 overflow-hidden">
                  {graphData && (
                     <Tab.Pane className="h-100" eventKey="graph">
                        <QueryResultsGraph graphData={graphData} />
                     </Tab.Pane>
                  )}
                  <Tab.Pane className="h-100" eventKey="table">
                     <ResultTable
                        allowEditing={!readOnly && queryResultEditingSupported}
                        editableColumns={editableColumns}
                        keyColumns={primaryKeyColumns}
                        onCellEdit={handleCellEdit}
                        queryReturn={queryReturn}
                        ref={resultTableRef}
                        simplified={simplified}
                     />
                  </Tab.Pane>
               </Tab.Content>
            </Tab.Container>
         )}
      </div>
   );
};
