import { SearchInput, ThemeContext } from '../../components';
import { FacetItem } from '../../entities';
import { useContext, useState } from 'react';
import { Dropdown, Form, Stack, Badge } from 'react-bootstrap';

export const FacetDropdown = ({
   title,
   options,
   filteredOptions,
   setFilteredOptions,
}: {
   filteredOptions: any[];
   options: FacetItem[];
   setFilteredOptions: (type: string, itemKey: any) => void;
   title: string;
}): JSX.Element => {
   const [showDropdown, setShowDropdown] = useState(false);
   const themeContext = useContext(ThemeContext);
   const isDarkMode = themeContext?.mode === 'dark';

   const [searchText, setSearchText] = useState('');

   const getLabelString = (item: FacetItem): string => {
      if (item.email) {
         return item.email;
      }
      if (item.connectionName) {
         return item.connectionName;
      }
      return item.key.replaceAll('.', ' > ').toString();
   };

   const getItemLabel = (item: FacetItem) => {
      let label = item.email || item.connectionName || item.key.toString().replaceAll('.', ' > ');
      const parts = label.split('>');
      const labelSize = parts.length - 1;

      const reorderedParts = [parts[labelSize], ...parts.slice(0, labelSize)];

      return reorderedParts.map((word: string, index: number) => (
         <span
            key={index}
            style={{
               paddingLeft: index === 1 ? '5px' : '',
               fontWeight: 'bold',
               color: index === 0 ? '' : 'grey',
               fontSize: index === 0 ? '12px' : '11px',
            }}
         >
            {index === 0 || index === 1 ? ' ' : ' > '}
            {word.trim()}
         </span>
      ));
   };

   const handleCheckChange = (type: string, itemKey: any) => {
      setFilteredOptions(type, itemKey);
   };

   const cleanAndSetSearchText = (newValue: any) => {
      newValue = newValue.toLowerCase().trim();
      setSearchText(newValue);
   };

   // Use `searchText` directly for instant filtering
   const searchedOptions = searchText
      ? options.filter((option) => getLabelString(option).toLowerCase().includes(searchText))
      : options;

   return (
      <Dropdown onToggle={() => setShowDropdown(!showDropdown)} show={showDropdown}>
         <Dropdown.Toggle
            className="facet-dropdown-label"
            data-label={title}
            onClick={() => setShowDropdown(!showDropdown)}
            style={{
               cursor: 'pointer',
               fontWeight: 'normal',
               padding: 5,
            }}
            variant="link"
         >
            <Stack className="align-items-start" direction="horizontal" gap={1}>
               <div
                  style={{
                     lineHeight: '17px',
                  }}
               >
                  {title}
               </div>
               {filteredOptions.length > 0 && (
                  <Badge
                     bg="secondary"
                     style={{
                        fontSize: '9px',
                        cursor: 'pointer',
                     }}
                  >
                     {`${filteredOptions.length}`}
                  </Badge>
               )}
               <div
                  style={{
                     lineHeight: '17px',
                  }}
               >
                  ▾
               </div>
            </Stack>
         </Dropdown.Toggle>

         <Dropdown.Menu
            style={{
               position: 'absolute',
               maxHeight: '40vh',
               overflowY: 'auto',
            }}
         >
            <Stack gap={1}>
               {!options.length && (
                  <div className="fs-12p text-center">
                     <i>No {title}s to filter</i>
                  </div>
               )}
               {options.length > 0 && (
                  <div className="px-2">
                     <SearchInput entityName={title} onTextChanged={cleanAndSetSearchText} />
                  </div>
               )}
               {searchedOptions.map((item, index) => (
                  <Dropdown.Item
                     as="button"
                     className="text-start"
                     key={index}
                     onClick={(e) => {
                        e.preventDefault();
                        handleCheckChange(title, item.key);
                     }}
                     onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = isDarkMode
                           ? 'rgba(36, 50, 72, 0.3)'
                           : 'rgba(36, 50, 72, 0.05)';
                     }}
                     onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = '';
                     }}
                     style={{
                        width: '100%',
                        overflowX: 'hidden',
                     }}
                  >
                     <Stack
                        direction="horizontal"
                        style={{
                           alignItems: 'center',
                           justifyContent: 'space-between',
                           width: '100%',
                        }}
                     >
                        <Form.Check
                           checked={filteredOptions?.includes(item.key)}
                           className="me-2 align-items-center"
                           onChange={() => handleCheckChange(title, item.key)}
                           onClick={(e) => {
                              e.preventDefault();
                              handleCheckChange(title, item.key);
                           }}
                           style={{
                              alignItems: 'center',
                              display: 'inline-flex',
                              cursor: 'pointer',
                           }}
                           type="checkbox"
                        />
                        <div
                           style={{
                              paddingLeft: '8px',
                              fontSize: '12px',
                              cursor: 'pointer',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              flex: 1,
                           }}
                        >
                           {getItemLabel(item)}
                        </div>
                        <Badge
                           bg="secondary"
                           style={{
                              fontSize: '9px',
                              cursor: 'pointer',
                              marginLeft: 'auto',
                           }}
                        >
                           {`${item.doc_count ?? '-'}`}
                        </Badge>
                     </Stack>
                  </Dropdown.Item>
               ))}
            </Stack>
         </Dropdown.Menu>
      </Dropdown>
   );
};

export default FacetDropdown;
