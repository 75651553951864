import { zodResolver } from '@hookform/resolvers/zod';
import { CredentialPersistence } from '@runql/util';
import { useEffect } from 'react';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import { ConnectionAccessType } from '../../../../entities';
import { DBMS } from '../../../../enums';
import { useGetDataConnectionQuery } from '../../../../hooks';
import { connectionName, description, host, port, withHostRefinement } from '../validators';
import { AccessTypeField, ConnectionParamSelectField, CredentialPersistenceField } from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   keyFile: z
      .custom<FileList>((v) => v instanceof FileList, {
         message: 'Key file is required',
      })
      .optional(),
});

const bigQueryDetailSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.Big_Query),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         projectId: z.string().min(1, 'Project ID is required'),
      })
      .merge(credentialsSchema)
);

type BigQueryDetailsFormData = z.infer<typeof bigQueryDetailSchema>;

function BigQueryDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // Register form
   const formMethods = useForm<BigQueryDetailsFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : bigQueryDetailSchema),
      mode: 'onTouched',
      defaultValues: {
         rememberCredential: true,
      },
   });
   const { register, handleSubmit, reset, setError, formState, watch, setValue } = formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: selectedConnectionId });

   // Effects
   useEffect(() => {
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const formData: BigQueryDetailsFormData = {
            dbms: DBMS.Big_Query,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            projectId: selectedConnectionQuery.data.catalogName ?? '',
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = async (data: BigQueryDetailsFormData) => {
      if (selectedConnectionId === undefined && data.keyFile?.length === 0) {
         // If creating a new connection and no key file is provided, set an error
         setError('keyFile', { message: 'Key file is required' });
         return;
      }

      if (onSubmit)
         onSubmit({
            ...data,
            database: data.projectId,
            password: !data.keyFile?.length ? undefined : await data.keyFile[0].text(),
         });
   };

   if (isSaving) return <LoadingSpinner />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input type="hidden" {...register('dbms')} value={DBMS.Big_Query} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="dbHost"
                        dbms={DBMS.Big_Query}
                        isRequired
                        label="Host"
                        onExistingSelect={(value) => {
                           setValue('port', parseInt(value.dbPort ?? '443', 10));
                           setValue('projectId', value.catalogName ?? '');
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Port <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('port', { valueAsNumber: true })}
                           defaultValue="443"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.port && !!errors.port}
                           isValid={touchedFields.port && !errors.port}
                           placeholder="Port"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.port?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Project ID <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('projectId')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.projectId && !!errors.projectId}
                           isValid={touchedFields.projectId && !errors.projectId}
                           placeholder="Project ID"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.projectId?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                        })}
                     />
                  </>
               )}
               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>
               <Form.Group>
                  <Form.Label>
                     Key File <span className="text-danger">*</span>{' '}
                     <a
                        className="fs-11p"
                        href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                        rel="noreferrer"
                        target={'_blank'}
                     >
                        (find your key file)
                     </a>
                  </Form.Label>
                  <Form.Control
                     {...register('keyFile')}
                     accept=".json"
                     disabled={editType !== 'connection'}
                     isInvalid={touchedFields.keyFile && !!errors.keyFile}
                     isValid={touchedFields.keyFile && !errors.keyFile}
                     multiple={false}
                     required={selectedConnectionId === undefined}
                     type="file"
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.keyFile?.message}
                  </Form.Control.Feedback>
               </Form.Group>
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default BigQueryDetailForm;
