import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useInjection } from 'inversify-react';

import { DataChatService } from '../../services';
import { TYPES } from '../../types';
import { DataChatThread, DataChatThreadState } from '../../entities';
import { QueryKey, QueryKeyType, PersonRole } from '../../enums';
import { usePerson } from './personHooks';

import type { DataChatThreadsMetadata } from '../../services/DataChatService';

export const getDataChatThreadKey = (threadId: number | null) => {
   if (!threadId) {
      return [QueryKey.DataChatThreads, QueryKeyType.LIST];
   }
   return [QueryKey.DataChatThreads, QueryKeyType.GET, threadId];
};

export const getDataChatThreadsMetadataKey = (role: 'creator' | 'analyst') => [
   QueryKey.DataChatThreads,
   'metadata',
   role,
];

export const useListDataChatThreads = ({
   workspaceId,
   enabled,
}: { enabled?: boolean; workspaceId?: number } = {}) => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   const person = usePerson();
   return useQuery<DataChatThread[]>(
      getDataChatThreadKey(null),
      async () =>
         await service.list({
            role: person.role === PersonRole.ORG_BUSINESS_USER ? 'creator' : 'analyst',
            workspaceId,
         }),
      {
         keepPreviousData: true,
         refetchOnWindowFocus: true,
         refetchOnMount: true,
         retry: false,
         enabled: enabled ?? true,
      }
   );
};

export const useFetchDataChatThreadsMetadata = () => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   const person = usePerson();
   const role = person.role === PersonRole.ORG_BUSINESS_USER ? 'creator' : 'analyst';
   return useQuery<DataChatThreadsMetadata>(
      getDataChatThreadsMetadataKey(role),
      async () => await service.fetchMetadata({ role }),
      {
         refetchOnWindowFocus: true,
         refetchOnMount: 'always',
         retry: false,
         refetchInterval: 45_000, // Refresh every 45s
      }
   );
};

export const useDataChatThreadsCount = () => {
   const metadata = useFetchDataChatThreadsMetadata();
   let count = 0;
   if (metadata.data?.type === 'creator') {
      count = metadata.data?.metadata.counts.unread;
   }
   if (metadata.data?.type === 'analyst') {
      count =
         metadata.data?.metadata.counts.assignedToSelf + metadata.data?.metadata.counts.unassigned;
   }

   // If the count changes, refetch the threads
   const { refetch: refetchThreads } = useListDataChatThreads({ enabled: false });
   useEffect(() => {
      refetchThreads();
   }, [count, refetchThreads]);

   return count;
};

export const useGetDataChatThread = (id: number | null) => {
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useQuery<DataChatThread>(getDataChatThreadKey(id), async () => await service.get(id!), {
      enabled: !!id,
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 30_000, // Refresh every 30s
      retry: false,
   });
};

export const useAddDataChatMessage = () => {
   const queryClient = useQueryClient();
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useMutation({
      mutationFn: (thread: {
         id?: number;
         message: { content?: string; queryVersionId?: number };
         state?: DataChatThreadState;
      }) => {
         return thread.id
            ? service.patch(thread.id, {
                 messages: [thread.message],
                 state: thread.state,
              })
            : service.post({ message: thread.message.content! });
      },
      onSuccess: (thread) => {
         queryClient.setQueryData(getDataChatThreadKey(thread.id!), (_: any) => thread);
         // We need to invalidate the full list, since the assignee and last
         // message date might change
         queryClient.invalidateQueries(getDataChatThreadKey(null));
         queryClient.invalidateQueries(getDataChatThreadsMetadataKey('creator'));
         queryClient.invalidateQueries(getDataChatThreadsMetadataKey('analyst'));
      },
   });
};

export const usePatchDataChatThread = () => {
   const queryClient = useQueryClient();
   const service = useInjection<DataChatService>(TYPES.dataChatService);
   return useMutation({
      mutationFn: ({
         id,
         data,
      }: {
         data: {
            assigneeId?: number | null;
            state?: DataChatThreadState;
            viewedByCreator?: true;
            workspaceId?: number;
         };
         id: number;
      }) => service.patch(id, data),
      onSuccess: (thread) => {
         queryClient.setQueryData(getDataChatThreadKey(thread.id!), (_: any) => thread);
         // We need to invalidate the full list, since the assignee and last
         // message date might change
         queryClient.invalidateQueries(getDataChatThreadKey(null));
         queryClient.invalidateQueries(getDataChatThreadsMetadataKey('creator'));
         queryClient.invalidateQueries(getDataChatThreadsMetadataKey('analyst'));
      },
   });
};
