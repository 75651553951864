import { zodResolver } from '@hookform/resolvers/zod';
import { CredentialPersistence } from '@runql/util';
import React, { useEffect, useState } from 'react';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import LoadingError from '../../../../components/UI/LoadingError';
import LoadingSpinner from '../../../../components/UI/LoadingSpinner';
import { ConnectionAccessType } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import { getErrorMessage } from '../../../../utilities';
import { connectionName, description, host, withHostRefinement } from '../validators';
import {
   AccessTypeField,
   ConnectionParamSelectField,
   CredentialPersistenceField,
   HideDetailsField,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   authToken: z.string().min(1, 'Required'),
});

const databricksDetailSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.Databricks),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         connectionName: connectionName,
         description: description,
         host,
         catalog: z.string().min(1, 'Required'),
         sharedConnection: z.boolean().default(false),
         hideDetails: z.boolean().default(false),
         databricksPath: z.string().min(1, 'Required'),
      })
      .merge(credentialsSchema)
);

type DatabricksDetailFormData = z.infer<typeof databricksDetailSchema>;

function DatabricksDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register form
   const formMethods = useForm<DatabricksDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : databricksDetailSchema),
      mode: 'onTouched',
      defaultValues: {
         rememberCredential: true,
      },
   });
   const { handleSubmit, register, formState, reset, watch, setValue, setError } = formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = React.useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({ id: selectedConnectionId });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];

         const formData: DatabricksDetailFormData = {
            dbms: DBMS.Databricks,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            catalog: selectedConnectionQuery.data.catalogName ?? '',
            databricksPath: selectedConnectionQuery.data.databricksPath ?? '',
            authToken:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: DatabricksDetailFormData) => {
      onSubmit?.({
         ...data,
         database: data.catalog,
         password: data.authToken === 'CURRENT' ? undefined : data.authToken,
         accountName: '',
      });
   };

   const [clearedFields, setClearedFields] = useState<string[]>([]);

   const handleFocus =
      (checkValue: string, setValueOnFocus: string = '') =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (e.target.value === checkValue) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof DatabricksDetailFormData, setValueOnFocus); // Update form state
            setClearedFields([...clearedFields, e.target.name]);
         }
      };

   const handleBlur =
      (checkValue: string = '', setValueOnFocus: string = 'CURRENT', touchedField: string) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
         if (
            e.target.value === checkValue &&
            !(touchedField in touchedFields) &&
            clearedFields.includes(touchedField)
         ) {
            e.target.value = setValueOnFocus; // Clear or set to desired value
            setValue(e.target.name as keyof DatabricksDetailFormData, setValueOnFocus); // Update form state
         } else if (!e.target.value) {
            setError(touchedField as keyof DatabricksDetailFormData, {
               type: 'manual',
               message: 'This field is required',
            });
            setValue(e.target.name as keyof DatabricksDetailFormData, undefined, {
               shouldDirty: true,
               shouldTouch: true,
            });
         }
      };

   // Render
   if (authPersonQuery.isLoading || selectedConnectionQuery.isLoading || isSaving)
      return <LoadingSpinner />;
   if (authPersonQuery.isError)
      return <LoadingError message={getErrorMessage(authPersonQuery.error)} />;
   if (selectedConnectionQuery.isError)
      return <LoadingError message={getErrorMessage(selectedConnectionQuery.error)} />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input type="hidden" {...register('dbms')} value={DBMS.Databricks} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="dbHost"
                        dbms={DBMS.Databricks}
                        isRequired
                        label="Host"
                        onExistingSelect={(value) => {
                           setValue('databricksPath', value.databricksPath ?? '');
                           setValue('catalog', value.catalogName ?? '');
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Path <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('databricksPath')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.databricksPath && !!errors.databricksPath}
                           isValid={touchedFields.databricksPath && !errors.databricksPath}
                           placeholder="Path"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.databricksPath?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Catalog <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('catalog')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.catalog && !!errors.catalog}
                           isValid={touchedFields.catalog && !errors.catalog}
                           placeholder="Catalog"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.catalog?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange(event) {
                              if (
                                 parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL
                              ) {
                                 setValue('hideDetails', false, { shouldDirty: true });
                              }
                              setValue('authToken', '', { shouldDirty: true });
                           },
                        })}
                     />
                     {explorerIsAdmin && (
                        <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                           <div>
                              <HideDetailsField
                                 {...register('hideDetails')}
                                 disabled={editType !== 'connection'}
                              />
                           </div>
                        </Collapse>
                     )}
                  </>
               )}
               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>
               <Form.Group>
                  <Form.Label>
                     Auth Token <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                     {...register('authToken')}
                     disabled={editType === 'read-only'}
                     isInvalid={touchedFields.authToken && !!errors.authToken}
                     isValid={touchedFields.authToken && !errors.authToken}
                     onBlur={handleBlur('', 'CURRENT', 'authToken')}
                     onFocus={handleFocus('CURRENT', '')}
                     placeholder="Auth Token"
                     required
                     type="password"
                  />
                  <Form.Control.Feedback type="invalid">
                     {errors.authToken?.message}
                  </Form.Control.Feedback>
               </Form.Group>
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default DatabricksDetailForm;
