import Cookies from 'js-cookie';

const uri = process.env.REACT_APP_API_URI ?? 'http://localhost:3000';
const code = window.localStorage.getItem('code');

const redirect = (provider: string): string => {
   return `${uri}/v1/auth/${provider}/callback`;
};

let host = `${window.location.protocol}//${window.location.host}`;

const state = () =>
   encodeURIComponent(JSON.stringify({ host, from: window.location.toString(), code }));

const generateNonce = () => {
   const nonceBuf = new Uint8Array(8);
   crypto.getRandomValues(nonceBuf);
   const nonce = nonceBuf.reduce((a, i) => a + i.toString(36), '');
   Cookies.set('nonce', nonce, { domain: window.location.hostname.replace('app.', '') });
   return nonce;
};

export const signInWithMS = (newTab = false) => {
   if (newTab) {
      window.open('/x/ms', '_blank');
      return;
   }
   const p = new URLSearchParams({
      client_id: 'c9d4cbf6-9b70-4a40-9aa0-bfafcb1129c3',
      response_type: 'token id_token',
      redirect_uri: redirect('microsoft'),
      scope: 'openid profile email',
      response_mode: 'form_post',
      nonce: generateNonce(),
      state: state(),
   });
   const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${p.toString()}`;
   window.location.href = url;
};

export const signInWithGoogle = (newTab = false) => {
   if (newTab) {
      window.open('/x/google', '_blank');
      return;
   }
   const p = new URLSearchParams({
      client_id: '1085718417014-gj176kc334vlksceok3u3j37sptqd2ub.apps.googleusercontent.com',
      response_type: 'code',
      redirect_uri: redirect('google'),
      scope: 'openid email profile',
      state: state(),
      nonce: generateNonce(),
   });
   const url = `https://accounts.google.com/o/oauth2/v2/auth?${p.toString()}`;
   window.location.href = url;
};
