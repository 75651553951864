import { Col, Form, Row } from 'react-bootstrap';
import { QueryParameter } from '../entities';

interface Props {
   mode: 'edit' | 'run';
   onDefaultValueChange(name: string, newValue: string): void;
   onDescriptionChange(name: string, description: string): void;
   queryParam: QueryParameter;
}

export const QueryParameterCard = (props: Props): JSX.Element => {
   return (
      <Form className="">
         <Row className="align-items-center">
            <Col xs={2}>
               <span className="fs-12p">
                  <Form.Control
                     className="fw-600"
                     plaintext
                     readOnly
                     value={props.queryParam.name}
                  />
               </span>
            </Col>
            <Col xs={3}>
               <span className="fs-12p">
                  {props.mode === 'edit' ? (
                     <Form.Control
                        className="fw-500 text-box-bottom-border-only"
                        onChange={(event) =>
                           props.onDescriptionChange(props.queryParam.name, event.target.value)
                        }
                        value={props.queryParam.description ?? ''}
                     />
                  ) : (
                     <Form.Control
                        className="fw-500"
                        plaintext
                        readOnly
                        value={props.queryParam.description ?? ''}
                     />
                  )}
               </span>
            </Col>
            <Col xs={2}>
               <Form.Control
                  className="fw-500 text-box-bottom-border-only"
                  disabled={props.queryParam.exclude ?? false}
                  onChange={(event) =>
                     props.onDefaultValueChange(props.queryParam.name, event.target.value)
                  }
                  value={props.queryParam.defaultValue}
               />
            </Col>
         </Row>
      </Form>
   );
};
