import { zodResolver } from '@hookform/resolvers/zod';
import { CredentialPersistence } from '@runql/util';
import { useEffect, useState } from 'react';
import { Collapse, Form, Stack } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { LoadingSpinner } from '../../../../components';
import { ConnectionAccessType, SSLMode } from '../../../../entities';
import { ADMIN_ROLES, DBMS } from '../../../../enums';
import { useGetAuthorizedExplorerQuery, useGetDataConnectionQuery } from '../../../../hooks';
import {
   connectionName,
   description,
   host,
   password,
   port,
   username,
   withHostRefinement,
} from '../validators';
import {
   AccessTypeField,
   ConnectionCredentialsFields,
   ConnectionParamSelectField,
   CredentialPersistenceField,
   HideDetailsField,
} from './common';
import { ConnectionFields, CredentialFields } from './ConnectionDetailsForm';

const credentialsSchema = z.object({
   rememberCredential: z.boolean().optional(),
   accountName: username,
   password: password,
});

const redshiftSchema = withHostRefinement(
   z
      .object({
         dbms: z.literal(DBMS.Redshift),
         connectionAccessType: z.nativeEnum(ConnectionAccessType),
         connectionName: connectionName,
         description: description,
         host: host,
         port: port,
         database: z.string().min(1, 'Required').trim(),
         sharedConnection: z.boolean().default(false),
         hideDetails: z.boolean().default(false),
         useSSL: z.boolean().default(false),
      })
      .merge(credentialsSchema)
);

type RedshiftDetailFormData = z.infer<typeof redshiftSchema>;

function RedshiftDetailForm({
   editType,
   formId,
   isSaving,
   onSaveStateChange,
   onSubmit,
   onlyCreds,
   selectedConnectionId,
}: {
   editType: 'connection' | 'credential' | 'read-only';
   formId: string;
   isSaving?: boolean;
   onSaveStateChange?: (state: 'clean' | 'dirty') => void;
   onSubmit?: (data: ConnectionFields & CredentialFields) => void;
   onlyCreds?: boolean;
   selectedConnectionId?: number;
}) {
   // register forms
   const formMethods = useForm<RedshiftDetailFormData>({
      resolver: zodResolver(onlyCreds ? credentialsSchema.passthrough() : redshiftSchema),
      mode: 'onTouched',
      defaultValues: {
         useSSL: false,
         rememberCredential: true,
      },
   });
   const { handleSubmit, register, reset, setValue, watch, formState } = formMethods;
   const errors = formState.errors;
   const touchedFields = formState.touchedFields;
   const connectionAccessType = watch('connectionAccessType', ConnectionAccessType.INDIVIDUAL);

   // State variables
   const [explorerIsAdmin, setExplorerIsAdmin] = useState(false);

   // Queries
   const selectedConnectionQuery = useGetDataConnectionQuery({
      id: selectedConnectionId,
      getOptions: { includeCredentials: true },
   });
   const authPersonQuery = useGetAuthorizedExplorerQuery();

   // Effects
   useEffect(() => {
      //User is adding credentials, load parent connection data
      if (selectedConnectionQuery.data) {
         const dataCredential = selectedConnectionQuery.data.dataCredentials?.[0];
         const formData: RedshiftDetailFormData = {
            dbms: DBMS.Redshift,
            connectionAccessType:
               selectedConnectionQuery.data.connectionAccessType ?? ConnectionAccessType.INDIVIDUAL,
            connectionName: selectedConnectionQuery.data.name ?? '',
            description: selectedConnectionQuery.data.description ?? '',
            host: selectedConnectionQuery.data.dbHost ?? '',
            port: parseInt(selectedConnectionQuery.data.dbPort ?? ''),
            database: selectedConnectionQuery.data.catalogName ?? '',
            sharedConnection:
               selectedConnectionQuery.data.connectionAccessType === ConnectionAccessType.SHARED,
            hideDetails:
               (selectedConnectionQuery.data.hideDetails as unknown as number) === 1 ? true : false,
            accountName: selectedConnectionQuery.data.dataCredentials?.[0].accountName ?? '',
            password:
               selectedConnectionQuery.data.dataCredentials?.[0]?.accountPassword === undefined
                  ? ''
                  : 'CURRENT',
            useSSL: selectedConnectionQuery.data.sslMode === SSLMode.VALIDATED,
            rememberCredential: dataCredential?.credentialPersistence
               ? dataCredential.credentialPersistence === CredentialPersistence.LOCAL_STORAGE
               : true,
         };
         reset(formData);
      }
   }, [selectedConnectionQuery.data, reset]);

   useEffect(() => {
      if (
         authPersonQuery.data?.person.role &&
         ADMIN_ROLES.includes(authPersonQuery.data.person.role)
      ) {
         setExplorerIsAdmin(true);
      } else {
         setExplorerIsAdmin(false);
      }
   }, [authPersonQuery.data?.person.role]);

   useEffect(() => {
      const isDirtyAlt = !!Object.keys(formState.dirtyFields).length;
      if (isDirtyAlt) {
         onSaveStateChange?.('dirty');
      } else {
         onSaveStateChange?.('clean');
      }
   }, [formState, onSaveStateChange]);

   // Page functions
   const handleOnSubmit = (data: RedshiftDetailFormData) => {
      if (onSubmit) {
         onSubmit(data);
      }
   };

   if (isSaving) return <LoadingSpinner />;

   return (
      <FormProvider {...formMethods}>
         <Form id={formId} onSubmit={handleSubmit(handleOnSubmit)}>
            <Stack gap={3}>
               {!onlyCreds && (
                  <>
                     <input defaultValue={DBMS.Redshift} type="hidden" {...register('dbms')} />
                     <Form.Group>
                        <Form.Label>
                           Connection Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('connectionName')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.connectionName && !!errors.connectionName}
                           isValid={touchedFields.connectionName && !errors.connectionName}
                           placeholder="Connection Name"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.connectionName?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                           {...register('description')}
                           as="textarea"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.description && !!errors.description}
                           isValid={touchedFields.description && !errors.description}
                           placeholder="Description"
                           rows={3}
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.description?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <ConnectionParamSelectField
                        connectionField="dbHost"
                        dbms={DBMS.Redshift}
                        isRequired
                        label="Host"
                        onExistingSelect={(value) => {
                           setValue('port', parseInt(value.dbPort ?? '5432', 10));
                           setValue('database', value.catalogName ?? '');
                           setValue('useSSL', value.sslMode === SSLMode.VALIDATED);
                        }}
                     />
                     <Form.Group>
                        <Form.Label>
                           Port <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('port', { valueAsNumber: true })}
                           defaultValue="5432"
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.port && !!errors.port}
                           isValid={touchedFields.port && !errors.port}
                           placeholder="Port"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.port?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>
                           Database <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                           {...register('database')}
                           disabled={editType !== 'connection'}
                           isInvalid={touchedFields.database && !!errors.database}
                           isValid={touchedFields.database && !errors.database}
                           placeholder="Database"
                           required
                        />
                        <Form.Control.Feedback type="invalid">
                           {errors.database?.message}
                        </Form.Control.Feedback>
                     </Form.Group>
                     <Form.Group>
                        <Form.Label>SSL Mode</Form.Label>
                        <Form.Select
                           disabled={editType !== 'connection'}
                           {...register('useSSL', {
                              setValueAs: (v) => v === true || v === 'true',
                           })}
                        >
                           <option value="false">Use SSL if available</option>
                           <option value="true">Require SSL and validate certificates</option>
                        </Form.Select>
                     </Form.Group>

                     <AccessTypeField
                        disabled={editType !== 'connection'}
                        {...register('connectionAccessType', {
                           setValueAs: (v: string) => parseInt(v) as ConnectionAccessType,
                           onChange(event) {
                              if (
                                 parseInt(event.target.value) === ConnectionAccessType.INDIVIDUAL
                              ) {
                                 setValue('hideDetails', false, { shouldDirty: true });
                              }

                              setValue('accountName', '', { shouldDirty: true });
                              setValue('password', '', { shouldDirty: true });
                           },
                        })}
                     />
                     {explorerIsAdmin && (
                        <Collapse in={connectionAccessType === ConnectionAccessType.SHARED}>
                           <div>
                              <HideDetailsField
                                 {...register('hideDetails')}
                                 disabled={editType !== 'connection'}
                              />
                           </div>
                        </Collapse>
                     )}
                  </>
               )}
               <Collapse in={connectionAccessType === ConnectionAccessType.INDIVIDUAL}>
                  <div>
                     <CredentialPersistenceField />
                  </div>
               </Collapse>

               <ConnectionCredentialsFields
                  autoFocusName={onlyCreds}
                  readonly={editType === 'read-only'}
               />
            </Stack>
         </Form>
      </FormProvider>
   );
}

export default RedshiftDetailForm;
