import { useCallback, useMemo, useState } from 'react';
import { Modal, Spinner, Stack } from 'react-bootstrap';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import { getErrorMessage } from '@runql/util';

import { Button, Page } from '../../../components';
import ErrorBoundary from '../../../components/ErrorBoundary';
import LoadingError from '../../../components/UI/LoadingError';
import LoadingSpinner from '../../../components/UI/LoadingSpinner';
import { DataConnection, DataCredential, getAllowableEditType } from '../../../entities';
import {
   useDeleteDataConnectionMutator,
   useGetAuthorizedExplorerQuery,
   useGetDataConnectionQuery,
   useNewDataCredentialMutator,
   useReTestDataConnectionMutator,
   useUpdateDataConnectionMutator,
} from '../../../hooks';
import ConnectionDetailsForm, {
   connectionDetailsFormId,
} from '../AddConnectionWizard/ConnectionDetailForm/ConnectionDetailsForm';
import { MetadataTabContent } from './DataConnectionDetailsMetadata';
import { VisibleSchemasTabContent } from './DataConnectionDetailsVisibleSchemas';

const DataConnectionDetailsPage = ({
   selectedDataConnection,
}: {
   selectedDataConnection: DataConnection;
}) => {
   const dataConnectionId = selectedDataConnection.id!;

   const navigate = useNavigate();

   // Page State
   const [connectionDetailsState, setConnectionDetailsState] = useState<'clean' | 'dirty'>('clean');
   const [formMode, setFormMode] = useState<'save' | 'test'>('save');
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [serverResult, setServerResult] = useState<
      { message?: string; success: Boolean } | undefined
   >(undefined);
   const [metaViewUpdated, setMetaViewUpdated] = useState(false);

   // Queries
   const explorerQuery = useGetAuthorizedExplorerQuery();

   // Mutators
   const deleteDataConnectionMutation = useDeleteDataConnectionMutator();
   const editFormMutator = useUpdateDataConnectionMutator({
      onSuccessCallback(data, variables, context) {},
   });
   const testConnectionMutation = useReTestDataConnectionMutator({
      callbacks: {
         onSuccess(data, variables, context) {
            setServerResult(data);
         },
      },
   });
   const newCredentialMutation = useNewDataCredentialMutator({
      onSuccess(data, variables, context) {},
   });

   const allowedEdit = useMemo(() => {
      return getAllowableEditType(selectedDataConnection, explorerQuery.data?.person);
   }, [selectedDataConnection, explorerQuery.data?.person]);
   const readOnly = allowedEdit === 'read-only';

   const updateMetaViewed = useCallback(async () => {
      if (metaViewUpdated) return;
      await editFormMutator.mutateAsync({
         dataConnection: {
            id: selectedDataConnection.id,
            metadataLastView: new Date(),
         },
      });
      setMetaViewUpdated((prev) => {
         if (!prev) {
            selectedDataConnection.hasNewMetadata = false;
            return true;
         }
         return prev;
      });
   }, [metaViewUpdated, editFormMutator, selectedDataConnection]);

   // Functions
   const updateSavedState = (state: 'clean' | 'dirty') => {
      if (state !== connectionDetailsState) {
         setConnectionDetailsState(state);
      }
   };

   const testConnection = async (newConnection: DataConnection) => {
      try {
         await testConnectionMutation.mutateAsync(newConnection);
      } catch (err) {
         setServerResult({ success: false, message: getErrorMessage(err) });
      }
   };

   // Handlers
   const handleSubmit = async (newConnection?: DataConnection, newCredential?: DataCredential) => {
      if (!newConnection) return;
      const editType = getAllowableEditType(newConnection, explorerQuery.data?.person);
      if (formMode === 'save') {
         if (editType === 'connection') {
            await editFormMutator.mutateAsync({
               dataConnection: newConnection,
            });
            if (editFormMutator.isSuccess) navigate('/sources');
         }
         if (editType === 'credential' && newCredential) {
            await newCredentialMutation.mutateAsync({
               newCredential: newCredential,
            });
            if (newCredentialMutation.isSuccess) navigate('/sources');
         }
      }
      if (formMode === 'test') await testConnection(newConnection);
   };

   const onDeleteClick = async () => {
      await deleteDataConnectionMutation.mutateAsync(dataConnectionId);
      navigate('/sources');
   };

   const showDelete = allowedEdit === 'connection';
   return (
      <ErrorBoundary title={'An error occurred when loading data collections.'}>
         <Page
            header={selectedDataConnection.name}
            onTabChange={(tabKey) => {
               // metadata tab
               if (tabKey === '2') {
                  updateMetaViewed();
               }
            }}
            tabs={[
               {
                  title: 'Connection Details',
                  content: (
                     <div
                        className="h-100 overflow-hidden d-flex flex-column"
                        style={{ maxWidth: '800px' }}
                     >
                        <div className="overflow-auto flex-grow-1">
                           <ConnectionDetailsForm
                              onSaveStateChange={updateSavedState}
                              onSubmit={handleSubmit}
                              selectedConnectionId={dataConnectionId}
                           />
                        </div>
                        <Stack className="mt-3 mb-2 justify-content-between" direction="horizontal">
                           <Stack direction="horizontal" gap={3}>
                              <Button
                                 colorScheme="secondary"
                                 form={connectionDetailsFormId}
                                 onClick={() => setFormMode('test')}
                                 size="sm"
                                 type="submit"
                              >
                                 {testConnectionMutation.isLoading ? (
                                    <Spinner size="sm" />
                                 ) : (
                                    <>Test Connection</>
                                 )}
                              </Button>
                              {(testConnectionMutation.isSuccess ||
                                 testConnectionMutation.isError) &&
                                 serverResult !== undefined &&
                                 (serverResult.success ? (
                                    <span className="text-success fs-10p d-flex justify-content-end">
                                       Connection Successful
                                    </span>
                                 ) : (
                                    <span>
                                       {serverResult?.message !== undefined ? (
                                          <div>
                                             <span className="text-danger fs-10p d-flex justify-content-end">
                                                {serverResult?.message}
                                             </span>
                                          </div>
                                       ) : (
                                          'Unknown error during test'
                                       )}
                                    </span>
                                 ))}
                           </Stack>
                           <Stack direction="horizontal" gap={2}>
                              {!readOnly && (
                                 <Button
                                    colorScheme="secondary"
                                    disabled={editFormMutator.isLoading}
                                    onClick={() => setShowDeleteModal(true)}
                                    size="sm"
                                 >
                                    Delete
                                 </Button>
                              )}
                              <Link className="btn btn-sm btn-secondary" to="/sources">
                                 {readOnly ? 'Close' : 'Cancel'}
                              </Link>
                              {!readOnly && (
                                 <Button
                                    disabled={
                                       connectionDetailsState === 'clean' ||
                                       editFormMutator.isLoading
                                    }
                                    form={connectionDetailsFormId}
                                    onClick={() => setFormMode('save')}
                                    size="sm"
                                    type="submit"
                                 >
                                    {editFormMutator.isLoading && <LoadingSpinner />} Save
                                 </Button>
                              )}
                           </Stack>
                        </Stack>
                     </div>
                  ),
               },
               {
                  title: 'Visible Schemas',
                  content: <VisibleSchemasTabContent dataConnection={selectedDataConnection} />,
               },
               {
                  title: 'Metadata',
                  pulse: selectedDataConnection.hasNewMetadata,
                  content: <MetadataTabContent dataConnection={selectedDataConnection} />,
               },
            ]}
         >
            {showDelete && (
               <Modal onHide={() => setShowDeleteModal(false)} show={showDeleteModal}>
                  <Modal.Header closeButton>
                     <Modal.Title className="fs-14p">Remove Data Source</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                     <span>
                        Are you sure you want to remove{' '}
                        {selectedDataConnection.name ?? 'this data source'}?
                     </span>
                     <Stack className="justify-content-end mt-2" direction="horizontal" gap={2}>
                        <Button
                           colorScheme="secondary"
                           onClick={() => setShowDeleteModal(false)}
                           size="sm"
                        >
                           Cancel
                        </Button>
                        <Button
                           colorScheme="danger"
                           onClick={() => onDeleteClick?.()}
                           size="sm"
                           type="button"
                        >
                           Delete
                        </Button>
                     </Stack>
                  </Modal.Body>
               </Modal>
            )}
         </Page>
      </ErrorBoundary>
   );
};

const DataConnectionDetailsPageContainer = ({ dataConnectionId }: { dataConnectionId: number }) => {
   const dataConnectionQueryResult = useGetDataConnectionQuery({
      id: dataConnectionId,
      getOptions: { flagNewMetadata: true },
   });

   if (dataConnectionQueryResult.isLoading) {
      return <LoadingSpinner />;
   }

   if (!dataConnectionQueryResult.data?.id) {
      return <LoadingError message="Data Connection not found" />;
   }

   return <DataConnectionDetailsPage selectedDataConnection={dataConnectionQueryResult.data} />;
};

const DataConnectionDetailsPageHandler = () => {
   const params = useParams();

   if (!params.dataConnectionId) {
      return <Navigate to="/sources" />;
   }

   const dataConnectionId = parseInt(params.dataConnectionId, 10);

   if (isNaN(dataConnectionId)) {
      return <Navigate to="/sources" />;
   }

   return <DataConnectionDetailsPageContainer dataConnectionId={dataConnectionId} />;
};

export default DataConnectionDetailsPageHandler;
