"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RQL_ENCRYPTION_VERSIONS = exports.CredentialPersistence = void 0;
var CredentialPersistence;
(function (CredentialPersistence) {
    CredentialPersistence["LOCAL_STORAGE"] = "localStorage";
    CredentialPersistence["SESSION_STORAGE"] = "sessionStorage";
})(CredentialPersistence || (exports.CredentialPersistence = CredentialPersistence = {}));
exports.RQL_ENCRYPTION_VERSIONS = {
    none: -1,
    current: 1,
    v1: 1,
};
